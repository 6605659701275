import * as React from 'react'
import styled from 'styled-components'

export default function Floor4({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_4"
      data-name="4"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="219.9698"
          y1="273.003"
          x2="219.9698"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(4, 16)}
          onMouseOver={() => setElementOnHover('A16')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A16"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_A16'
                ? 'active-apt'
                : elementOnHover === 'A16'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 9.837 42.915 23.784 67.917 23.784 67.917 9.837 59.107 9.837 59.107 7.088 50.516 7.088 50.516 9.837 42.915 9.837"
          />
        </g>
        <g
          onClick={() => changeView(4, 17)}
          onMouseOver={() => setElementOnHover('A17')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A17"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_A17'
                ? 'active-apt'
                : elementOnHover === 'A17'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 9.837 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 23.784 42.915 23.784 42.915 9.837 23.779 9.837"
          />
        </g>
        <g
          onClick={() => changeView(4, 18)}
          onMouseOver={() => setElementOnHover('A18')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A18"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_A18'
                ? 'active-apt'
                : elementOnHover === 'A18'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(4, 19)}
          onMouseOver={() => setElementOnHover('A19')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A19"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_A19'
                ? 'active-apt'
                : elementOnHover === 'A19'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(4, 20)}
          onMouseOver={() => setElementOnHover('A20')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A20"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_A20'
                ? 'active-apt'
                : elementOnHover === 'A20'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 9.837 67.917 23.784 95.873 23.784 95.873 9.837 86.583 9.837 86.583 7.088 78.086 7.088 78.086 9.837 67.917 9.837"
          />
        </g>
        <g
          onClick={() => changeView(4, 42)}
          onMouseOver={() => setElementOnHover('B42')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B42"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_B42'
                ? 'active-apt'
                : elementOnHover === 'B42'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 9.837 95.873 39.77 114.376 39.749 114.376 44.171 138.752 44.171 138.752 10.038 95.873 9.837"
          />
        </g>
        <g
          onClick={() => changeView(4, 43)}
          onMouseOver={() => setElementOnHover('B43')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B43"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_B43'
                ? 'active-apt'
                : elementOnHover === 'B43'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 77.319 138.665 77.319 138.665 44.618 114.29 44.618 114.029 77.319"
          />
        </g>
        <g
          onClick={() => changeView(4, 44)}
          onMouseOver={() => setElementOnHover('B44')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B44"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_B44'
                ? 'active-apt'
                : elementOnHover === 'B44'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 67.625 87.471 67.625 87.471 77.527 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(4, 71)}
          onMouseOver={() => setElementOnHover('C71')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C71"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C71'
                ? 'active-apt'
                : elementOnHover === 'C71'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 115.219 109.277 115.219 91.798 137.989 92.111 137.989 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(4, 72)}
          onMouseOver={() => setElementOnHover('C72')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C72"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C72'
                ? 'active-apt'
                : elementOnHover === 'C72'
                ? 'on-hover'
                : ''
            }`}
            points="138.567 91.798 138.567 117.543 114.975 117.543 115.219 91.798 138.567 91.798"
          />
        </g>
        <g
          onClick={() => changeView(4, 73)}
          onMouseOver={() => setElementOnHover('C73')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C73"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C73'
                ? 'active-apt'
                : elementOnHover === 'C73'
                ? 'on-hover'
                : ''
            }`}
            points="99.053 151.213 99.053 127.956 114.843 127.956 114.843 117.543 138.567 117.543 138.567 151.296 99.053 151.213"
          />
        </g>
        <g
          onClick={() => changeView(4, 74)}
          onMouseOver={() => setElementOnHover('C74')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C74"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C74'
                ? 'active-apt'
                : elementOnHover === 'C74'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
        <g
          onClick={() => changeView(4, 75)}
          onMouseOver={() => setElementOnHover('C75')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C75"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C75'
                ? 'active-apt'
                : elementOnHover === 'C75'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(4, 76)}
          onMouseOver={() => setElementOnHover('C76')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C76"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_04_krs_C76'
                ? 'active-apt'
                : elementOnHover === 'C76'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polyline
          className="cls-3"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-3"
          points="99.053 151.631 99.053 127.956 115.315 127.956"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-3"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-3"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-3"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-3"
          x1="95.8734"
          y1="9.837"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-3"
          points="95.873 23.715 67.917 23.715 67.917 9.837"
        />
        <polyline
          className="cls-3"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-3"
          points="45.296 23.715 42.915 23.715 42.915 9.837"
        />
        <line
          className="cls-3"
          x1="45.3101"
          y1="34.4952"
          x2="73.5293"
          y2="34.4952"
        />
        <polyline
          className="cls-3"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-3"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-3"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-3"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.837 50.516 7.088 59.107 7.088 59.107 9.768"
        />
        <polyline
          className="cls-5"
          points="78.086 9.837 78.086 7.088 86.583 7.088 86.583 9.837"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 67.625 95.028 67.625"
        />
        <line
          className="cls-3"
          x1="114.9748"
          y1="117.3803"
          x2="138.3093"
          y2="117.3803"
        />
        <path
          className="cls-4"
          d="M137.4064,10.4876l.0119,17.0265h-7.5529l.0023,1.42.0238,14.2487.0023,1.4111,1.411.0039,6.1006.017V49.26l-.0124,17.5936h-9.0382l.0024,1.42.0139,8.4067.0023,1.415h9.0329V91.4857h-9.0352v10.282l1.42-.0028,7.6143-.0148v35.157l-7.62.0189-1.4138.0035V150.214H63.3133V109.9506H96.36V51.5682H24.5864V10.4876h112.82M138.8227,9.07H23.1691V52.9855H94.9423v55.5478H61.896v43.098h67.8924v-13.288l9.0343-.0224V100.33l-9.0343.0176V92.903h9.0352V76.6775h-9.0352l-.0139-8.4068h9.0349l.0133-19.01v-6.06l-7.5139-.0209-.0237-14.2488h7.5515L138.8227,9.07Z"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.321"
        />
        <line
          className="cls-5"
          x1="138.659"
          y1="100.3794"
          x2="138.659"
          y2="92.1328"
        />
        <line
          className="cls-5"
          x1="138.6654"
          y1="77.3189"
          x2="138.6654"
          y2="68.2707"
        />
        <line
          className="cls-5"
          x1="138.6767"
          y1="43.2003"
          x2="138.6767"
          y2="28.9315"
        />
        <line
          className="cls-3"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
        <polyline
          className="cls-3"
          points="128.82 77.59 95.583 77.59 87.471 77.59"
        />
        <polyline
          className="cls-3"
          points="128.396 91.798 114.975 91.798 114.975 109.364"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_A16'
              ? 'white-text'
              : elementOnHover === 'A16'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 16)}
          onMouseOver={() => setElementOnHover('A16')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M58.9239,18.5937h-.4986V15.4189a2.59,2.59,0,0,1-.4716.3427,3.3957,3.3957,0,0,1-.525.2578V15.538a3.0231,3.0231,0,0,0,.731-.4766,1.8734,1.8734,0,0,0,.4429-.5429h.3213Z"
          />
          <path
            className="cls-6"
            d="M63.0689,15.5292l-.4956.0391a.9431.9431,0,0,0-.188-.4268.6612.6612,0,0,0-.4985-.2129.6836.6836,0,0,0-.418.1328,1.1385,1.1385,0,0,0-.3711.501,2.6258,2.6258,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.1913,1.1913,0,0,1,.5454-.1329,1.1276,1.1276,0,0,1,.8482.3672,1.3164,1.3164,0,0,1,.35.9483,1.5606,1.5606,0,0,1-.1646.71,1.1987,1.1987,0,0,1-.4526.502,1.2366,1.2366,0,0,1-.6533.1748,1.2749,1.2749,0,0,1-1.0157-.458,2.3133,2.3133,0,0,1-.393-1.5108,2.7017,2.7017,0,0,1,.4345-1.71,1.248,1.248,0,0,1,1.0215-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1555,1.1555,0,0,0,.1094.4922.8114.8114,0,0,0,.3057.3584.7665.7665,0,0,0,.4126.123.7043.7043,0,0,0,.5424-.2539,1.0025,1.0025,0,0,0,.2271-.6924.9383.9383,0,0,0-.2241-.663.7377.7377,0,0,0-.5645-.2422.7691.7691,0,0,0-.5732.2422A.8727.8727,0,0,0,61.0342,17.2792Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_A17'
              ? 'white-text'
              : elementOnHover === 'A17'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 17)}
          onMouseOver={() => setElementOnHover('A17')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M37.0635,35.4179H36.565V32.2431a2.591,2.591,0,0,1-.4717.3427,3.4013,3.4013,0,0,1-.5249.2579v-.4815a3.0278,3.0278,0,0,0,.731-.4765,1.8731,1.8731,0,0,0,.4428-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M38.6563,31.8915v-.48h2.6269V31.8a5.5335,5.5335,0,0,0-.768,1.0967,6.6521,6.6521,0,0,0-.5884,1.4062,5.2262,5.2262,0,0,0-.1909,1.1153h-.5122a4.9534,4.9534,0,0,1,.1884-1.1572,6.0635,6.0635,0,0,1,.5162-1.3077,5.5925,5.5925,0,0,1,.7153-1.0615Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_A18'
              ? 'white-text'
              : elementOnHover === 'A18'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 18)}
          onMouseOver={() => setElementOnHover('A18')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M62.7837,44.9242h-.4985V41.7494a2.5875,2.5875,0,0,1-.4717.3428,3.3907,3.3907,0,0,1-.5249.2578v-.4814a3.031,3.031,0,0,0,.731-.4766,1.8718,1.8718,0,0,0,.4428-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M65.11,42.7231A.9383.9383,0,0,1,64.65,42.4a.8491.8491,0,0,1-.1494-.5039.9863.9863,0,0,1,.3184-.7451,1.1808,1.1808,0,0,1,.8471-.3018,1.1928,1.1928,0,0,1,.8555.3086,1.0013,1.0013,0,0,1,.3237.752.8262.8262,0,0,1-.1479.4912.9317.9317,0,0,1-.45.3223,1.0941,1.0941,0,0,1,.5688.3935,1.0766,1.0766,0,0,1,.1954.6475,1.1651,1.1651,0,0,1-.3682.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1837,1.1837,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0111,1.0111,0,0,1,65.11,42.7231Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.71.71,0,0,0,.3.3086.875.875,0,0,0,.4292.1094.8041.8041,0,0,0,.59-.2295.7871.7871,0,0,0,.2324-.584.7984.7984,0,0,0-.2393-.5947.82.82,0,0,0-.6-.2354.793.793,0,0,0-.814.8135Zm.1607-1.874a.634.634,0,0,0,.1855.4707.6555.6555,0,0,0,.4815.1826.6432.6432,0,0,0,.4722-.1816.6.6,0,0,0,.184-.4444.6223.6223,0,0,0-.1894-.4609.6975.6975,0,0,0-.9453-.0039A.5889.5889,0,0,0,65.01,41.8793Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_A19'
              ? 'white-text'
              : elementOnHover === 'A19'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 19)}
          onMouseOver={() => setElementOnHover('A19')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M87.9644,40.1132h-.4985V36.9384a2.5993,2.5993,0,0,1-.4717.3428,3.4,3.4,0,0,1-.5249.2578v-.4815A3.0271,3.0271,0,0,0,87.2,36.581a1.8737,1.8737,0,0,0,.4429-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M89.5987,39.1747l.479-.0439a.8286.8286,0,0,0,.2324.4892.6379.6379,0,0,0,.44.1524.7669.7669,0,0,0,.4029-.1045.8636.8636,0,0,0,.2837-.2813,1.6544,1.6544,0,0,0,.1855-.4746,2.5029,2.5029,0,0,0,.0747-.6094c0-.0224-.001-.0556-.0024-.1a1.1457,1.1457,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5606.1474,1.1347,1.1347,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3486-.9629,1.37,1.37,0,0,1,.3637-.9942A1.2171,1.2171,0,0,1,90.8,36.038a1.2984,1.2984,0,0,1,.7242.2139,1.3281,1.3281,0,0,1,.498.6074,2.9841,2.9841,0,0,1,.17,1.1416,3.6944,3.6944,0,0,1-.1689,1.2392,1.4462,1.4462,0,0,1-.5025.7012,1.2959,1.2959,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1406-1.0078Zm2.04-1.791a.9768.9768,0,0,0-.228-.6807.7165.7165,0,0,0-.55-.2519.7568.7568,0,0,0-.5786.2715,1.0051,1.0051,0,0,0-.2461.7031.8653.8653,0,0,0,.2339.6289.7675.7675,0,0,0,.5771.2422.7369.7369,0,0,0,.5689-.2422A.953.953,0,0,0,91.6387,37.3837Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_A20'
              ? 'white-text'
              : elementOnHover === 'A20'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 20)}
          onMouseOver={() => setElementOnHover('A20')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M77.2769,18.4667l1.5586-4.0586h.5786l1.6606,4.0586h-.6118L79.99,17.2372H78.293l-.4458,1.2295Zm1.1709-1.666h1.376L79.4,15.6767q-.1934-.5127-.2876-.8418a5.2,5.2,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M84.2041,17.9882v.4785H81.5215a.883.883,0,0,1,.0586-.3457,1.8175,1.8175,0,0,1,.3277-.54,5.0767,5.0767,0,0,1,.6518-.6143,5.5224,5.5224,0,0,0,.8945-.86,1.0276,1.0276,0,0,0,.2325-.5987.6674.6674,0,0,0-.212-.5.7629.7629,0,0,0-.5522-.2031.7763.7763,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0528a1.26,1.26,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8028,1.2153,1.2153,0,0,1-.1.4785,1.858,1.858,0,0,1-.3311.4961A8.0046,8.0046,0,0,1,83,17.2069c-.2988.251-.4912.4219-.5756.5108a1.6249,1.6249,0,0,0-.2105.27Z"
          />
          <path
            className="cls-6"
            d="M85.022,16.4657a3.7194,3.7194,0,0,1,.148-1.1592,1.4447,1.4447,0,0,1,.44-.6767,1.1268,1.1268,0,0,1,.7349-.2383,1.196,1.196,0,0,1,.5727.1318,1.098,1.098,0,0,1,.4072.379,2.0563,2.0563,0,0,1,.252.6035,4.0383,4.0383,0,0,1,.0913.96,3.7074,3.7074,0,0,1-.147,1.1524,1.4442,1.4442,0,0,1-.4385.6787,1.1282,1.1282,0,0,1-.7377.2392,1.1137,1.1137,0,0,1-.9219-.4209A2.6739,2.6739,0,0,1,85.022,16.4657Zm.5122,0a2.5428,2.5428,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.0019,2.5458,2.5458,0,0,0,.2339-1.3282,2.5456,2.5456,0,0,0-.2339-1.332.694.694,0,0,0-.583-.3291.6393.6393,0,0,0-.5479.29A2.5759,2.5759,0,0,0,85.5342,16.4657Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_B42'
              ? 'white-text'
              : elementOnHover === 'B42'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 42)}
          onMouseOver={() => setElementOnHover('B42')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M116.78,27.7191v-.9716H115.02V26.29l1.8525-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V24.4613L115.51,26.29Z"
          />
          <path
            className="cls-6"
            d="M121.2383,27.2406v.4785h-2.6826a.9016.9016,0,0,1,.0586-.3457,1.8309,1.8309,0,0,1,.3281-.54,5.09,5.09,0,0,1,.6514-.6143,5.5267,5.5267,0,0,0,.8945-.86,1.0238,1.0238,0,0,0,.2324-.5986.6656.6656,0,0,0-.2119-.5.7615.7615,0,0,0-.5517-.2032.7768.7768,0,0,0-.5762.2159.8172.8172,0,0,0-.2188.5976l-.5117-.0527a1.2618,1.2618,0,0,1,.3955-.8731,1.352,1.352,0,0,1,.9219-.3007,1.2874,1.2874,0,0,1,.9248.3242,1.0646,1.0646,0,0,1,.3408.8027,1.2149,1.2149,0,0,1-.1.4785,1.8558,1.8558,0,0,1-.331.4961,8.0353,8.0353,0,0,1-.7686.7139c-.2988.251-.4912.4219-.5752.5107a1.66,1.66,0,0,0-.2109.2705Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_B43'
              ? 'white-text'
              : elementOnHover === 'B43'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 43)}
          onMouseOver={() => setElementOnHover('B43')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M126.5723,60.3119V59.34h-1.7607v-.457l1.8515-2.63h.4073v2.63h.5478v.457H127.07v.9717Zm0-1.4287V57.0541l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M128.4141,59.2406l.498-.0664a1.108,1.108,0,0,0,.292.61.7212.7212,0,0,0,.503.1865.8068.8068,0,0,0,.5937-.2432.822.822,0,0,0,.2422-.6035.7662.7662,0,0,0-.2246-.5664.7744.7744,0,0,0-.57-.2226,1.4282,1.4282,0,0,0-.3516.0556l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.0216,1.0216,0,0,0,.5732-.167.5677.5677,0,0,0,.2549-.5117.6086.6086,0,0,0-.1856-.4541.6591.6591,0,0,0-.4794-.18.679.679,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5478l-.4981-.0878a1.2848,1.2848,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2754,1.3331,1.3331,0,0,1,.6123.1426,1.0418,1.0418,0,0,1,.4277.39.9953.9953,0,0,1,.1475.5224.8616.8616,0,0,1-.1406.4795.9694.9694,0,0,1-.418.3428.946.946,0,0,1,.5586.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1959,1.1959,0,0,1-.3877.9,1.3594,1.3594,0,0,1-.98.37,1.2774,1.2774,0,0,1-.8877-.3184A1.2365,1.2365,0,0,1,128.4141,59.2406Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_B44'
              ? 'white-text'
              : elementOnHover === 'B44'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 44)}
          onMouseOver={() => setElementOnHover('B44')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M105.7344,68.2943v-.9716h-1.7607v-.4571l1.852-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V65.0365l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M109.1709,68.2943v-.9716H107.41v-.4571l1.852-2.63h.4073v2.63h.5478v.4571H109.67v.9716Zm0-1.4287V65.0365l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C71'
              ? 'white-text'
              : elementOnHover === 'C71'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 71)}
          onMouseOver={() => setElementOnHover('C71')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-6"
            d="M104.8462,98.6547v-.48h2.627v.3877a5.5335,5.5335,0,0,0-.7681,1.0967,6.6521,6.6521,0,0,0-.5884,1.4062,5.2324,5.2324,0,0,0-.1909,1.1153h-.5122a4.961,4.961,0,0,1,.1885-1.1573,6.0623,6.0623,0,0,1,.5161-1.3076,5.5925,5.5925,0,0,1,.7153-1.0615Z"
          />
          <path
            className="cls-6"
            d="M110.1265,102.1811h-.4985V99.0063a2.591,2.591,0,0,1-.4717.3427,3.3907,3.3907,0,0,1-.5249.2578v-.4814a3.0244,3.0244,0,0,0,.7309-.4766,1.8734,1.8734,0,0,0,.4429-.5429h.3213Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C72'
              ? 'white-text'
              : elementOnHover === 'C72'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 72)}
          onMouseOver={() => setElementOnHover('C72')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M123.4483,107.7777l.5371.1358a1.8079,1.8079,0,0,1-.6084,1.0088,1.6729,1.6729,0,0,1-1.0723.3476,1.9233,1.9233,0,0,1-1.0674-.2676,1.6646,1.6646,0,0,1-.625-.7734,2.7525,2.7525,0,0,1-.2148-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.66,1.66,0,0,1,.6894-.7178,2.0145,2.0145,0,0,1,.9834-.2442,1.6608,1.6608,0,0,1,1.0245.31,1.6111,1.6111,0,0,1,.5791.8721l-.5293.124a1.2536,1.2536,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3151,1.3151,0,0,0-.7822.2246,1.1845,1.1845,0,0,0-.4414.6016,2.4209,2.4209,0,0,0-.127.7793,2.478,2.478,0,0,0,.15.9043,1.1262,1.1262,0,0,0,.47.5771,1.3164,1.3164,0,0,0,.69.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,123.4483,107.7777Z"
          />
          <path
            className="cls-6"
            d="M124.7608,105.6742v-.48h2.6269v.3877a5.5364,5.5364,0,0,0-.7675,1.0967,6.6475,6.6475,0,0,0-.5889,1.4063,5.1937,5.1937,0,0,0-.19,1.1152h-.5127a4.9807,4.9807,0,0,1,.1884-1.1572,6.07,6.07,0,0,1,.5166-1.3077,5.5919,5.5919,0,0,1,.7149-1.0615Z"
          />
          <path
            className="cls-6"
            d="M130.7832,108.7221v.4785h-2.6826a.8649.8649,0,0,1,.0586-.3457,1.8066,1.8066,0,0,1,.3272-.54,5.1,5.1,0,0,1,.6523-.6142,5.5176,5.5176,0,0,0,.8945-.86,1.0305,1.0305,0,0,0,.2325-.5986.6689.6689,0,0,0-.212-.5.7642.7642,0,0,0-.5527-.2031.7751.7751,0,0,0-.5752.2158.8135.8135,0,0,0-.2187.5977l-.5127-.0528a1.2579,1.2579,0,0,1,.3965-.873,1.35,1.35,0,0,1,.9218-.3008,1.2858,1.2858,0,0,1,.9239.3242,1.0614,1.0614,0,0,1,.3408.8027,1.2154,1.2154,0,0,1-.1.4786,1.858,1.858,0,0,1-.3311.4961,7.9728,7.9728,0,0,1-.7676.7138c-.2988.251-.4912.4219-.5761.5108a1.5946,1.5946,0,0,0-.21.27Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C73'
              ? 'white-text'
              : elementOnHover === 'C73'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 73)}
          onMouseOver={() => setElementOnHover('C73')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
          />
          <path
            className="cls-6"
            d="M117.2588,133.807v-.48h2.627v.3877a5.5364,5.5364,0,0,0-.7676,1.0967,6.6527,6.6527,0,0,0-.5889,1.4063,5.1937,5.1937,0,0,0-.19,1.1152h-.5127a4.9869,4.9869,0,0,1,.1885-1.1572,6.062,6.062,0,0,1,.5166-1.3076,5.59,5.59,0,0,1,.7148-1.0616Z"
          />
          <path
            className="cls-6"
            d="M120.6651,136.2621l.498-.0664a1.1077,1.1077,0,0,0,.292.61.7211.7211,0,0,0,.5029.1865.8069.8069,0,0,0,.5938-.2432.8226.8226,0,0,0,.2422-.6035.766.766,0,0,0-.2246-.5664.7744.7744,0,0,0-.57-.2227,1.429,1.429,0,0,0-.3516.0557l.0557-.4375a.7157.7157,0,0,0,.08.0059,1.0219,1.0219,0,0,0,.5733-.167.5677.5677,0,0,0,.2549-.5117.6085.6085,0,0,0-.1856-.4541.6587.6587,0,0,0-.48-.18.6787.6787,0,0,0-.4843.1826.9207.9207,0,0,0-.2491.5478l-.498-.0879a1.2843,1.2843,0,0,1,.415-.7773,1.2,1.2,0,0,1,.8057-.2754,1.3331,1.3331,0,0,1,.6123.1426,1.0429,1.0429,0,0,1,.4277.39.9959.9959,0,0,1,.1475.5225.8616.8616,0,0,1-.1406.4795.9707.9707,0,0,1-.418.3428.9463.9463,0,0,1,.5586.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1959,1.1959,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2774,1.2774,0,0,1-.8877-.3184A1.236,1.236,0,0,1,120.6651,136.2621Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C74'
              ? 'white-text'
              : elementOnHover === 'C74'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 74)}
          onMouseOver={() => setElementOnHover('C74')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-6"
            d="M76.3658,142.3539v-.48h2.6269v.3877a5.5377,5.5377,0,0,0-.7681,1.0967,6.6465,6.6465,0,0,0-.5883,1.4062,5.2262,5.2262,0,0,0-.1909,1.1153h-.5123a4.96,4.96,0,0,1,.1885-1.1572,6.0631,6.0631,0,0,1,.5161-1.3077,5.5926,5.5926,0,0,1,.7154-1.0615Z"
          />
          <path
            className="cls-6"
            d="M81.3662,145.88v-.9717H79.6055v-.457l1.8521-2.63h.4072v2.63h.5478v.457h-.5478v.9717Zm0-1.4287v-1.8291l-1.2705,1.8291Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C75'
              ? 'white-text'
              : elementOnHover === 'C75'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 75)}
          onMouseOver={() => setElementOnHover('C75')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-6"
            d="M76.3658,128.6947v-.48h2.6269v.3877a5.5407,5.5407,0,0,0-.7681,1.0967,6.6451,6.6451,0,0,0-.5883,1.4063,5.2241,5.2241,0,0,0-.1909,1.1152h-.5123a4.96,4.96,0,0,1,.1885-1.1572,6.06,6.06,0,0,1,.5161-1.3076,5.59,5.59,0,0,1,.7154-1.0616Z"
          />
          <path
            className="cls-6"
            d="M79.7691,131.1576l.5229-.0439a.9378.9378,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.7955.7955,0,0,0,.6089-.27,1.0224,1.0224,0,0,0,.2495-.72.9242.9242,0,0,0-.24-.6728.8359.8359,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.851.851,0,0,0-.3047.2842l-.4677-.0606.393-2.0849h2.0181v.4765H80.65l-.2188,1.0909a1.319,1.319,0,0,1,.7666-.2549,1.2123,1.2123,0,0,1,.897.3681,1.2852,1.2852,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2853,1.2853,0,0,1-1.0659.4931,1.3114,1.3114,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,79.7691,131.1576Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_04_krs_C76'
              ? 'white-text'
              : elementOnHover === 'C76'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(4, 76)}
          onMouseOver={() => setElementOnHover('C76')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-6"
            d="M76.3658,115.29v-.48h2.6269v.3877a5.5407,5.5407,0,0,0-.7681,1.0967,6.6451,6.6451,0,0,0-.5883,1.4063,5.2241,5.2241,0,0,0-.1909,1.1152h-.5123a4.96,4.96,0,0,1,.1885-1.1572,6.06,6.06,0,0,1,.5161-1.3076,5.59,5.59,0,0,1,.7154-1.0616Z"
          />
          <path
            className="cls-6"
            d="M82.3545,115.7523l-.4956.0391a.94.94,0,0,0-.188-.4267.6609.6609,0,0,0-.4985-.2129.6831.6831,0,0,0-.418.1328,1.1381,1.1381,0,0,0-.3711.501,2.6256,2.6256,0,0,0-.1411.9384,1.1576,1.1576,0,0,1,.4405-.4072,1.1906,1.1906,0,0,1,.5454-.1328,1.1275,1.1275,0,0,1,.8481.3672,1.316,1.316,0,0,1,.35.9482,1.5625,1.5625,0,0,1-.1645.71,1.1992,1.1992,0,0,1-.4527.5019,1.2364,1.2364,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3129,2.3129,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2481,1.2481,0,0,1,1.0215-.4658,1.087,1.087,0,0,1,1.1513,1.0107Zm-2.0346,1.75a1.1565,1.1565,0,0,0,.1093.4922.8115.8115,0,0,0,.3057.3584.766.766,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.738.738,0,0,0-.5644-.2422.77.77,0,0,0-.5733.2422A.8732.8732,0,0,0,80.32,117.5023Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 16 ? (
          <g id="A16-2" data-name="A16">
            <path
              id="A16cone"
              className="cls-8"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A16-3"
              data-name="A16"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 17 ? (
          <g id="A17-2" data-name="A17">
            <path
              id="A17cone"
              className="cls-10"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A17-3"
              data-name="A17"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 18 ? (
          <g id="A18-2" data-name="A18">
            <path
              id="A18cone"
              className="cls-11"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A18-3"
              data-name="A18"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 19 ? (
          <g id="A19-2" data-name="A19">
            <path
              id="A19cone"
              className="cls-12"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A19-3"
              data-name="A19"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 20 ? (
          <g id="A20-2" data-name="A20">
            <path
              id="A20cone"
              className="cls-13"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A20-3"
              data-name="A20"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 42 ? (
          <g id="B42-2" data-name="B42">
            <path
              id="B42cone"
              className="cls-14"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B42-3"
              data-name="B42"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 43 ? (
          <g id="B43-2" data-name="B43">
            <path
              id="B43cone"
              className="cls-15"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B43-3"
              data-name="B43"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 44 ? (
          <g id="B44-2" data-name="B44">
            <path
              id="B44cone"
              className="cls-16"
              d="M86.1952,75.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B44-3"
              data-name="B44"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 71 ? (
          <g id="C71-2" data-name="C71">
            <path
              id="C71cone"
              className="cls-17"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C71-3"
              data-name="C71"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 72 ? (
          <g id="C72-2" data-name="C72">
            <path
              id="C72cone"
              className="cls-18"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C72-3"
              data-name="C72"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 73 ? (
          <g id="C73-2" data-name="C73">
            <path
              id="C73cone"
              className="cls-19"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C73-3"
              data-name="C73"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 74 ? (
          <g id="C74-2" data-name="C74">
            <path
              id="C74cone"
              className="cls-20"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C74-3"
              data-name="C74"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 75 ? (
          <g id="C75-2" data-name="C75">
            <path
              id="C75cone"
              className="cls-21"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C75-3"
              data-name="C75"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 76 ? (
          <g id="C76-2" data-name="C76">
            <path
              id="C76cone"
              className="cls-22"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C76-3"
              data-name="C76"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_4-2" data-name="4">
          <path
            className="cls-7"
            d="M172.7579,86.2191V84.7616h-2.6407v-.685l2.7774-3.9449h.61v3.9449h.8223v.685h-.8223v1.4575Zm0-2.1425V81.3319l-1.9063,2.7447Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  16: [54.848, 7.0884],
  17: [36.4846, 57.3091],
  18: [63.4377, 57.3091],
  19: [77.9525, 57.3091],
  20: [82.3715, 7.0884],
  42: [135.7828, 36.2417],
  43: [135.7828, 72.5363],
  44: [90.7952, 72.5363],
  71: [90.7952, 86.0066],
  72: [135.7828, 96.0066],
  73: [135.7828, 144.7428],
  74: [56.9873, 143.5353],
  75: [56.9873, 130.4153],
  76: [56.9873, 117.0263],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }
  .cls-17 {
    fill: url(#linear-gradient-9);
  }
  .cls-18 {
    fill: url(#linear-gradient-10);
  }
  .cls-19 {
    fill: url(#linear-gradient-11);
  }
  .cls-20 {
    fill: url(#linear-gradient-12);
  }
  .cls-21 {
    fill: url(#linear-gradient-13);
  }
  .cls-22 {
    fill: url(#linear-gradient-14);
  }

  #A16,
  #A17,
  #A18,
  #A19,
  #A20,
  #B42,
  #B43,
  #B44,
  #C71,
  #C72,
  #C73,
  #C74,
  #C75,
  #C76 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
