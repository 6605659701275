export const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 6,
    sceneName: 'scene_Otavallanhovi_02_krs_A06',
    sceneName_future: 'scene_Otavallanhovi_02_krs_A06_future',
    name: 'A06',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      1: 1,
      3: 11,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 7,
    sceneName: 'scene_Otavallanhovi_02_krs_A07',
    sceneName_future: 'scene_Otavallanhovi_02_krs_A07_future',
    name: 'A07',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      1: 2,
      3: 12,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 8,
    sceneName: 'scene_Otavallanhovi_02_krs_A08',
    sceneName_future: 'scene_Otavallanhovi_02_krs_A08_future',
    name: 'A08',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      1: 3,
      3: 13,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 9,
    sceneName: 'scene_Otavallanhovi_02_krs_A09',
    sceneName_future: 'scene_Otavallanhovi_02_krs_A09_future',
    name: 'A09',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      1: 4,
      3: 14,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 10,
    sceneName: 'scene_Otavallanhovi_02_krs_A10',
    sceneName_future: 'scene_Otavallanhovi_02_krs_A10_future',
    name: 'A10',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      1: 5,
      3: 15,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 36,
    sceneName: 'scene_Otavallanhovi_02_krs_B36',
    sceneName_future: 'scene_Otavallanhovi_02_krs_B36_future',
    name: 'B36',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      1: 4,
      3: 39,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 37,
    sceneName: 'scene_Otavallanhovi_02_krs_B37',
    sceneName_future: 'scene_Otavallanhovi_02_krs_B37_future',
    name: 'B37',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      1: 4,
      3: 40,
    },
  },
  {
    floor: 2,
    building: 'B',
    apartmentNumber: 38,
    sceneName: 'scene_Otavallanhovi_02_krs_B38',
    sceneName_future: 'scene_Otavallanhovi_02_krs_B38_future',
    name: 'B38',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      1: 4,
      3: 41,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 59,
    sceneName: 'scene_Otavallanhovi_02_krs_C59',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C59_future',
    name: 'C59',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      1: 56,
      3: 65,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 60,
    sceneName: 'scene_Otavallanhovi_02_krs_C60',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C60_future',
    name: 'C60',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      1: 56,
      3: 66,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 61,
    sceneName: 'scene_Otavallanhovi_02_krs_C61',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C61_future',
    name: 'C61',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      1: 56,
      3: 67,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 62,
    sceneName: 'scene_Otavallanhovi_02_krs_C62',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C62_future',
    name: 'C62',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      1: 58,
      3: 68,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 63,
    sceneName: 'scene_Otavallanhovi_02_krs_C63',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C63_future',
    name: 'C63',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      1: 57,
      3: 69,
    },
  },
  {
    floor: 2,
    building: 'C',
    apartmentNumber: 64,
    sceneName: 'scene_Otavallanhovi_02_krs_C64',
    sceneName_future: 'scene_Otavallanhovi_02_krs_C64_future',
    name: 'C64',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      1: 56,
      3: 70,
    },
  },
]
