import * as React from 'react'
import styled from 'styled-components'

export default function Floor6({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_6"
      data-name="6"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="219.9698"
          y1="273.003"
          x2="219.9698"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(6, 26)}
          onMouseOver={() => setElementOnHover('A26')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A26"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_A26'
                ? 'active-apt'
                : elementOnHover === 'A26'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 11.403 42.915 23.784 67.917 23.784 67.917 11.403 59.107 11.403 59.107 7.088 50.516 7.088 50.516 11.403 42.915 11.403"
          />
        </g>
        <g
          onClick={() => changeView(6, 27)}
          onMouseOver={() => setElementOnHover('A27')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A27"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B27'
                ? 'active-apt'
                : elementOnHover === 'A27'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 11.617 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 23.784 42.915 23.784 42.915 11.617 23.779 11.617"
          />
        </g>
        <g
          onClick={() => changeView(6, 28)}
          onMouseOver={() => setElementOnHover('A28')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A28"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B28'
                ? 'active-apt'
                : elementOnHover === 'A28'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(6, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A29"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B29'
                ? 'active-apt'
                : elementOnHover === 'A29'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(6, 30)}
          onMouseOver={() => setElementOnHover('A30')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A30"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_A30'
                ? 'active-apt'
                : elementOnHover === 'A30'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 11.403 67.917 23.784 95.873 23.784 95.873 11.403 86.583 11.403 86.583 7.088 78.086 7.088 78.086 11.403 67.917 11.403"
          />
        </g>
        <g
          onClick={() => changeView(6, 48)}
          onMouseOver={() => setElementOnHover('B48')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B48"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B48'
                ? 'active-apt'
                : elementOnHover === 'B48'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 11.202 95.873 39.77 114.376 39.749 114.376 44.171 136.75 44.171 136.75 43.376 138.665 43.376 138.665 28.712 136.75 28.712 136.75 11.403 95.873 11.202"
          />
        </g>
        <g
          onClick={() => changeView(6, 49)}
          onMouseOver={() => setElementOnHover('B49')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B49"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B49'
                ? 'active-apt'
                : elementOnHover === 'B49'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 77.319 138.665 77.319 138.665 68.127 137.406 68.276 137.406 44.394 114.29 44.394 114.029 77.319"
          />
        </g>
        <g
          onClick={() => changeView(6, 50)}
          onMouseOver={() => setElementOnHover('B50')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B50"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_B50'
                ? 'active-apt'
                : elementOnHover === 'B50'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 67.625 87.471 67.625 87.471 77.527 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(6, 83)}
          onMouseOver={() => setElementOnHover('C83')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C83"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C83'
                ? 'active-apt'
                : elementOnHover === 'C83'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 115.219 109.277 115.219 91.798 137.989 92.111 137.989 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(6, 84)}
          onMouseOver={() => setElementOnHover('C84')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C84"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C84' ||
              sceneName === 'scene_Otavallanhovi_06_krs_C84_small'
                ? 'active-apt'
                : elementOnHover === 'C84'
                ? 'on-hover'
                : ''
            }`}
            points="99.053 151.213 99.053 127.956 114.843 127.956 114.843 92.133 138.567 92.133 138.567 100.581 137.214 100.642 137.182 138.059 138.567 138.152 138.567 151.296 99.053 151.213"
          />
        </g>
        <g
          onClick={() => changeView(6, `${84}small`)}
          onMouseOver={() => setElementOnHover('C84small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C84_small"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C84_small'
                ? 'active-apt'
                : elementOnHover === 'C84small'
                ? 'on-hover'
                : ''
            }`}
            x="129.2786"
            y="92.1328"
            width="9.2886"
            height="8.4481"
          />
        </g>
        <g
          onClick={() => changeView(6, 85)}
          onMouseOver={() => setElementOnHover('C85')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C85"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C85'
                ? 'active-apt'
                : elementOnHover === 'C85'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
        <g
          onClick={() => changeView(6, 86)}
          onMouseOver={() => setElementOnHover('C86')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C86"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C86'
                ? 'active-apt'
                : elementOnHover === 'C86'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(6, 87)}
          onMouseOver={() => setElementOnHover('C87')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C87"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_06_krs_C87'
                ? 'active-apt'
                : elementOnHover === 'C87'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polyline
          className="cls-3"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-3"
          points="99.053 151.631 99.053 127.956 115.315 127.956"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-3"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-3"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-3"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-3"
          x1="95.8734"
          y1="11.4025"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-3"
          points="95.873 23.715 67.917 23.715 67.917 11.403"
        />
        <polyline
          className="cls-3"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-3"
          points="45.296 23.715 42.915 23.715 42.915 11.403"
        />
        <line
          className="cls-3"
          x1="45.3101"
          y1="34.4952"
          x2="73.5293"
          y2="34.4952"
        />
        <polyline
          className="cls-3"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-3"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-3"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-3"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.071 50.516 7.088 59.107 7.088 59.107 9.002"
        />
        <polyline
          className="cls-5"
          points="78.086 9.071 78.086 7.088 86.583 7.088 86.583 9.071"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 67.625 95.028 67.625"
        />
        <path
          className="cls-4"
          d="M51.778,10.35l6.2824.0493.0076,1.26H79.3571L79.3434,10.41h6.2232l.0071,1.2487H136.273l.0117,15.8552h-6.4193l.0023,1.42.0237,14.2487.0024,1.41,1.41.0046,4.9485.0163-.0119,22.24h-7.8859l.0024,1.42.0139,8.4067.0023,1.415h9.0329V91.4857h-9.0352v10.2825l1.42-.0033,6.2518-.0144v35.1572l-6.2592.0183-1.4131.0041V150.214H63.3133V109.9506H96.36V51.5682H24.5865V11.6589H51.7771l.0009-1.309M50.3617,8.9214l-.0009,1.32H23.1691V52.9855H94.9423v55.5478H61.8959v43.098h67.8925v-13.288l7.6723-.0224V100.33l-7.6723.0176V92.903h9.0353V76.6775h-9.0353l-.0139-8.4068h7.8828l.0133-25.07-6.3618-.0209-.0237-14.2487h6.418l-.0139-18.69H86.9829l-.0071-1.2487H77.9106l.0136,1.2487H59.4768l-.0076-1.2487-9.1075-.0715Z"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.152 137.461 138.152"
        />
        <polyline
          className="cls-5"
          points="138.659 92.133 138.659 100.48 137.461 100.48"
        />
        <polyline
          className="cls-5"
          points="138.665 77.319 138.665 68.138 137.657 68.138"
        />
        <polyline
          className="cls-5"
          points="137.422 28.712 138.677 28.712 138.677 43.376 137.406 43.376"
        />
        <line
          className="cls-3"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
        <polyline
          className="cls-3"
          points="128.82 77.59 95.583 77.59 87.471 77.59"
        />
        <polyline
          className="cls-3"
          points="128.396 91.798 114.975 91.798 114.975 109.364"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_06_krs_A26'
              ? 'white-text'
              : elementOnHover === 'A26'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 26)}
          onMouseOver={() => setElementOnHover('A26')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M59.6656,18.1151v.4786H56.9829a.8842.8842,0,0,1,.0586-.3457,1.8179,1.8179,0,0,1,.3277-.54,5.0976,5.0976,0,0,1,.6518-.6142,5.5279,5.5279,0,0,0,.8946-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7628.7628,0,0,0-.5523-.2032.776.776,0,0,0-.5757.2159.8151.8151,0,0,0-.2187.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.8731,1.351,1.351,0,0,1,.9219-.3007,1.2869,1.2869,0,0,1,.9243.3242,1.0632,1.0632,0,0,1,.3408.8027,1.2149,1.2149,0,0,1-.1.4785,1.8549,1.8549,0,0,1-.3311.4961,7.9858,7.9858,0,0,1-.768.7139c-.2989.251-.4912.4219-.5757.5107a1.629,1.629,0,0,0-.2105.2705Z"
          />
          <path
            className="cls-6"
            d="M63.0689,15.5292l-.4956.0391a.9431.9431,0,0,0-.188-.4268.6612.6612,0,0,0-.4985-.2129.6836.6836,0,0,0-.418.1328,1.1385,1.1385,0,0,0-.3711.501,2.6258,2.6258,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.1913,1.1913,0,0,1,.5454-.1329,1.1276,1.1276,0,0,1,.8482.3672,1.3164,1.3164,0,0,1,.35.9483,1.5606,1.5606,0,0,1-.1646.71,1.1987,1.1987,0,0,1-.4526.502,1.2366,1.2366,0,0,1-.6533.1748,1.2749,1.2749,0,0,1-1.0157-.458,2.3133,2.3133,0,0,1-.393-1.5108,2.7017,2.7017,0,0,1,.4345-1.71,1.248,1.248,0,0,1,1.0215-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1555,1.1555,0,0,0,.1094.4922.8114.8114,0,0,0,.3057.3584.7665.7665,0,0,0,.4126.123.7043.7043,0,0,0,.5424-.2539,1.0025,1.0025,0,0,0,.2271-.6924.9383.9383,0,0,0-.2241-.663.7377.7377,0,0,0-.5645-.2422.7691.7691,0,0,0-.5732.2422A.8727.8727,0,0,0,61.0342,17.2792Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B27'
              ? 'white-text'
              : elementOnHover === 'A27'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 27)}
          onMouseOver={() => setElementOnHover('A27')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M37.8052,34.9394v.4785H35.1226a.8827.8827,0,0,1,.0586-.3457,1.8187,1.8187,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5262,5.5262,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7631.7631,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8153.8153,0,0,0-.2188.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3512,1.3512,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242A1.0628,1.0628,0,0,1,37.8,32.47a1.2158,1.2158,0,0,1-.1.4786,1.8555,1.8555,0,0,1-.331.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.6253,1.6253,0,0,0-.21.27Z"
          />
          <path
            className="cls-6"
            d="M38.6563,31.8915v-.48h2.6269V31.8a5.5335,5.5335,0,0,0-.768,1.0967,6.6521,6.6521,0,0,0-.5884,1.4062,5.2262,5.2262,0,0,0-.1909,1.1153h-.5122a4.9534,4.9534,0,0,1,.1884-1.1572,6.0635,6.0635,0,0,1,.5162-1.3077,5.5925,5.5925,0,0,1,.7153-1.0615Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B28'
              ? 'white-text'
              : elementOnHover === 'A28'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 28)}
          onMouseOver={() => setElementOnHover('A28')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M63.5254,44.4457v.4785H60.8428a.883.883,0,0,1,.0586-.3457,1.8191,1.8191,0,0,1,.3276-.54,5.0853,5.0853,0,0,1,.6519-.6143,5.5224,5.5224,0,0,0,.8945-.86,1.0276,1.0276,0,0,0,.2325-.5987.6674.6674,0,0,0-.212-.5.7631.7631,0,0,0-.5522-.2031.7761.7761,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0527a1.26,1.26,0,0,1,.3959-.8731,1.3514,1.3514,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0631,1.0631,0,0,1,.3409.8028,1.2136,1.2136,0,0,1-.1.4785,1.8558,1.8558,0,0,1-.331.4961,8.0157,8.0157,0,0,1-.7681.7139c-.2988.2509-.4912.4218-.5757.5107a1.6294,1.6294,0,0,0-.21.2705Z"
          />
          <path
            className="cls-6"
            d="M65.11,42.7231A.9383.9383,0,0,1,64.65,42.4a.8491.8491,0,0,1-.1494-.5039.9863.9863,0,0,1,.3184-.7451,1.1808,1.1808,0,0,1,.8471-.3018,1.1928,1.1928,0,0,1,.8555.3086,1.0013,1.0013,0,0,1,.3237.752.8262.8262,0,0,1-.1479.4912.9317.9317,0,0,1-.45.3223,1.0941,1.0941,0,0,1,.5688.3935,1.0766,1.0766,0,0,1,.1954.6475,1.1651,1.1651,0,0,1-.3682.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1837,1.1837,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0111,1.0111,0,0,1,65.11,42.7231Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.71.71,0,0,0,.3.3086.875.875,0,0,0,.4292.1094.8041.8041,0,0,0,.59-.2295.7871.7871,0,0,0,.2324-.584.7984.7984,0,0,0-.2393-.5947.82.82,0,0,0-.6-.2354.793.793,0,0,0-.814.8135Zm.1607-1.874a.634.634,0,0,0,.1855.4707.6555.6555,0,0,0,.4815.1826.6432.6432,0,0,0,.4722-.1816.6.6,0,0,0,.184-.4444.6223.6223,0,0,0-.1894-.4609.6975.6975,0,0,0-.9453-.0039A.5889.5889,0,0,0,65.01,41.8793Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B29'
              ? 'white-text'
              : elementOnHover === 'A29'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M88.7061,39.6347v.4785H86.0235a.8827.8827,0,0,1,.0586-.3457,1.8166,1.8166,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5219,5.5219,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7631.7631,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8156.8156,0,0,0-.2188.5977l-.5122-.0528a1.26,1.26,0,0,1,.396-.873,1.351,1.351,0,0,1,.9219-.3008,1.2868,1.2868,0,0,1,.9243.3242,1.0628,1.0628,0,0,1,.3408.8027,1.2154,1.2154,0,0,1-.1.4786,1.8574,1.8574,0,0,1-.331.4961,8.0169,8.0169,0,0,1-.7681.7138c-.2988.251-.4912.4219-.5757.5108a1.6188,1.6188,0,0,0-.21.2705Z"
          />
          <path
            className="cls-6"
            d="M89.5987,39.1747l.479-.0439a.8286.8286,0,0,0,.2324.4892.6379.6379,0,0,0,.44.1524.7669.7669,0,0,0,.4029-.1045.8636.8636,0,0,0,.2837-.2813,1.6544,1.6544,0,0,0,.1855-.4746,2.5029,2.5029,0,0,0,.0747-.6094c0-.0224-.001-.0556-.0024-.1a1.1457,1.1457,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5606.1474,1.1347,1.1347,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3486-.9629,1.37,1.37,0,0,1,.3637-.9942A1.2171,1.2171,0,0,1,90.8,36.038a1.2984,1.2984,0,0,1,.7242.2139,1.3281,1.3281,0,0,1,.498.6074,2.9841,2.9841,0,0,1,.17,1.1416,3.6944,3.6944,0,0,1-.1689,1.2392,1.4462,1.4462,0,0,1-.5025.7012,1.2959,1.2959,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1406-1.0078Zm2.04-1.791a.9768.9768,0,0,0-.228-.6807.7165.7165,0,0,0-.55-.2519.7568.7568,0,0,0-.5786.2715,1.0051,1.0051,0,0,0-.2461.7031.8653.8653,0,0,0,.2339.6289.7675.7675,0,0,0,.5771.2422.7369.7369,0,0,0,.5689-.2422A.953.953,0,0,0,91.6387,37.3837Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_A30'
              ? 'white-text'
              : elementOnHover === 'A30'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 30)}
          onMouseOver={() => setElementOnHover('A30')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M77.2769,18.4667l1.5586-4.0586h.5786l1.6606,4.0586h-.6118L79.99,17.2372H78.293l-.4458,1.2295Zm1.1709-1.666h1.376L79.4,15.6767q-.1934-.5127-.2876-.8418a5.2,5.2,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M81.5884,17.3954l.4981-.0664a1.1,1.1,0,0,0,.292.61.72.72,0,0,0,.5024.1865.8058.8058,0,0,0,.5937-.2432.82.82,0,0,0,.2422-.6035.7656.7656,0,0,0-.2241-.5664.7751.7751,0,0,0-.57-.2227,1.4235,1.4235,0,0,0-.3516.0557l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5674.5674,0,0,0,.2549-.5117.6069.6069,0,0,0-.1855-.4541.6577.6577,0,0,0-.479-.18.6782.6782,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5478l-.4981-.0879a1.2844,1.2844,0,0,1,.4151-.7773,1.2,1.2,0,0,1,.8056-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0363,1.0363,0,0,1,.4278.39.9972.9972,0,0,1,.1479.5225.8572.8572,0,0,1-.1411.48.9672.9672,0,0,1-.418.3428.9457.9457,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2767,1.2767,0,0,1-.8872-.3184A1.2339,1.2339,0,0,1,81.5884,17.3954Z"
          />
          <path
            className="cls-6"
            d="M85.022,16.4657a3.7194,3.7194,0,0,1,.148-1.1592,1.4447,1.4447,0,0,1,.44-.6767,1.1268,1.1268,0,0,1,.7349-.2383,1.196,1.196,0,0,1,.5727.1318,1.098,1.098,0,0,1,.4072.379,2.0563,2.0563,0,0,1,.252.6035,4.0383,4.0383,0,0,1,.0913.96,3.7074,3.7074,0,0,1-.147,1.1524,1.4442,1.4442,0,0,1-.4385.6787,1.1282,1.1282,0,0,1-.7377.2392,1.1137,1.1137,0,0,1-.9219-.4209A2.6739,2.6739,0,0,1,85.022,16.4657Zm.5122,0a2.5428,2.5428,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.0019,2.5458,2.5458,0,0,0,.2339-1.3282,2.5456,2.5456,0,0,0-.2339-1.332.694.694,0,0,0-.583-.3291.6393.6393,0,0,0-.5479.29A2.5759,2.5759,0,0,0,85.5342,16.4657Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B48'
              ? 'white-text'
              : elementOnHover === 'B48'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 48)}
          onMouseOver={() => setElementOnHover('B48')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M116.78,27.7191v-.9716H115.02V26.29l1.8525-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V24.4613L115.51,26.29Z"
          />
          <path
            className="cls-6"
            d="M119.3868,25.518a.9371.9371,0,0,1-.46-.3233.8491.8491,0,0,1-.1494-.5039.988.988,0,0,1,.3183-.7451,1.3411,1.3411,0,0,1,1.7032.0068,1.0009,1.0009,0,0,1,.3232.752.8253.8253,0,0,1-.1475.4912.9336.9336,0,0,1-.45.3223,1.0957,1.0957,0,0,1,.5683.3935,1.0732,1.0732,0,0,1,.1953.6475,1.1631,1.1631,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1851,1.1851,0,0,1-.3682-.8877,1.0683,1.0683,0,0,1,.2012-.6631A1.0111,1.0111,0,0,1,119.3868,25.518Zm-.2608,1.03a.9022.9022,0,0,0,.1016.4122.71.71,0,0,0,.3.3086.879.879,0,0,0,.43.1093.8035.8035,0,0,0,.59-.2295.7887.7887,0,0,0,.2324-.584.7983.7983,0,0,0-.2392-.5947.822.822,0,0,0-.6-.2353.7929.7929,0,0,0-.8145.8134Zm.1611-1.874a.632.632,0,0,0,.1856.4707.6545.6545,0,0,0,.4814.1826.6412.6412,0,0,0,.4717-.1816.5984.5984,0,0,0,.1846-.4443.6241.6241,0,0,0-.1895-.461.6975.6975,0,0,0-.9453-.0039A.5871.5871,0,0,0,119.2871,24.6742Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B49'
              ? 'white-text'
              : elementOnHover === 'B49'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 49)}
          onMouseOver={() => setElementOnHover('B49')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M126.5723,60.3119V59.34h-1.7607v-.457l1.8515-2.63h.4073v2.63h.5478v.457H127.07v.9717Zm0-1.4287V57.0541l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M128.4864,59.3734l.4785-.0439a.8318.8318,0,0,0,.2324.4893.64.64,0,0,0,.44.1523.7651.7651,0,0,0,.4024-.1045.87.87,0,0,0,.2842-.2812,1.6686,1.6686,0,0,0,.1855-.4747,2.5023,2.5023,0,0,0,.0742-.6093c0-.0225-.001-.0557-.0019-.1a1.147,1.147,0,0,1-.4092.3867,1.1086,1.1086,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3361,1.3361,0,0,1-.3486-.9629,1.3675,1.3675,0,0,1,.3642-.9941,1.2161,1.2161,0,0,1,.9121-.377,1.298,1.298,0,0,1,.7237.2139,1.3281,1.3281,0,0,1,.498.6074A2.9733,2.9733,0,0,1,131.08,58.2a3.7075,3.7075,0,0,1-.1689,1.2393,1.4471,1.4471,0,0,1-.503.7011,1.2928,1.2928,0,0,1-.7812.2413,1.072,1.072,0,0,1-1.1406-1.0079Zm2.04-1.791a.9743.9743,0,0,0-.2285-.6806.7156.7156,0,0,0-.55-.252.7562.7562,0,0,0-.5781.2715,1.0053,1.0053,0,0,0-.2461.7031.865.865,0,0,0,.2334.6289.7691.7691,0,0,0,.5771.2422.7376.7376,0,0,0,.5693-.2422A.9524.9524,0,0,0,130.5264,57.5824Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_06_krs_B50'
              ? 'white-text'
              : elementOnHover === 'B50'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 50)}
          onMouseOver={() => setElementOnHover('B50')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M104.1372,67.2309l.523-.044a.9383.9383,0,0,0,.27.5742.73.73,0,0,0,.5108.1924.795.795,0,0,0,.6088-.27,1.0222,1.0222,0,0,0,.25-.72.9243.9243,0,0,0-.24-.6729.8364.8364,0,0,0-.6269-.247.8762.8762,0,0,0-.4346.1093.851.851,0,0,0-.3047.2842l-.4678-.06.3931-2.085h2.0181v.4766h-1.6192l-.2187,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.212,1.212,0,0,1,.8969.3682,1.2846,1.2846,0,0,1,.3653.9463,1.4788,1.4788,0,0,1-.3208.9521,1.2855,1.2855,0,0,1-1.0659.4932,1.3111,1.3111,0,0,1-.9039-.3106A1.2078,1.2078,0,0,1,104.1372,67.2309Z"
          />
          <path
            className="cls-6"
            d="M107.5738,66.2934a3.7223,3.7223,0,0,1,.1479-1.1592,1.4453,1.4453,0,0,1,.44-.6768,1.1272,1.1272,0,0,1,.7349-.2383,1.195,1.195,0,0,1,.5728.1319,1.0985,1.0985,0,0,1,.4072.3789,2.06,2.06,0,0,1,.2519.6035,4.0327,4.0327,0,0,1,.0914.96,3.71,3.71,0,0,1-.147,1.1523,1.4447,1.4447,0,0,1-.4385.6787,1.1283,1.1283,0,0,1-.7378.2393,1.1139,1.1139,0,0,1-.9219-.4209A2.6748,2.6748,0,0,1,107.5738,66.2934Zm.5122,0a2.5413,2.5413,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.0019,3.9,3.9,0,0,0,0-2.66.6942.6942,0,0,0-.583-.3291.6389.6389,0,0,0-.5479.29A2.5746,2.5746,0,0,0,108.086,66.2934Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_06_krs_C83'
              ? 'white-text'
              : elementOnHover === 'C83'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 83)}
          onMouseOver={() => setElementOnHover('C83')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-6"
            d="M105.58,99.98a.938.938,0,0,1-.4594-.3233.8483.8483,0,0,1-.15-.5039.9863.9863,0,0,1,.3184-.7451,1.34,1.34,0,0,1,1.7026.0069,1.0007,1.0007,0,0,1,.3238.7519.8254.8254,0,0,1-.148.4912.931.931,0,0,1-.45.3223,1.0954,1.0954,0,0,1,.5689.3935,1.0772,1.0772,0,0,1,.1953.6475,1.1648,1.1648,0,0,1-.3682.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1836,1.1836,0,0,1-.3681-.8877,1.0722,1.0722,0,0,1,.2006-.6631A1.0115,1.0115,0,0,1,105.58,99.98Zm-.26,1.03a.902.902,0,0,0,.101.4121.7106.7106,0,0,0,.3.3086.876.876,0,0,0,.4292.1093.8037.8037,0,0,0,.59-.23.7867.7867,0,0,0,.2324-.5839.7985.7985,0,0,0-.2393-.5948.82.82,0,0,0-.6-.2353.7927.7927,0,0,0-.8139.8135Zm.1606-1.8741a.6335.6335,0,0,0,.1856.4707.6552.6552,0,0,0,.4814.1827.6432.6432,0,0,0,.4722-.1817.6.6,0,0,0,.1841-.4443.6222.6222,0,0,0-.1895-.461.6975.6975,0,0,0-.9453-.0039A.5889.5889,0,0,0,105.48,99.1361Z"
          />
          <path
            className="cls-6"
            d="M108.2525,101.11l.498-.0664a1.1013,1.1013,0,0,0,.292.61.72.72,0,0,0,.5025.1865.8059.8059,0,0,0,.5937-.2431.8206.8206,0,0,0,.2422-.6035.7655.7655,0,0,0-.2241-.5664.7748.7748,0,0,0-.57-.2227,1.4221,1.4221,0,0,0-.3516.0557l.0557-.4375a.7117.7117,0,0,0,.08.0058,1.02,1.02,0,0,0,.5728-.167.5674.5674,0,0,0,.2549-.5117.6068.6068,0,0,0-.1856-.4541.658.658,0,0,0-.479-.18.6783.6783,0,0,0-.4843.1826.9208.9208,0,0,0-.2491.5479l-.498-.0879a1.2838,1.2838,0,0,1,.415-.7773,1.2,1.2,0,0,1,.8057-.2754,1.3325,1.3325,0,0,1,.6118.1425,1.0369,1.0369,0,0,1,.4277.39.9964.9964,0,0,1,.148.5225.8579.8579,0,0,1-.1411.4794.9664.9664,0,0,1-.418.3428.9462.9462,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.277,1.277,0,0,1-.8872-.3183A1.2343,1.2343,0,0,1,108.2525,101.11Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_06_krs_C84' ||
            sceneName === 'scene_Otavallanhovi_06_krs_C84_small'
              ? 'white-text'
              : elementOnHover === 'C84'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 84)}
          onMouseOver={() => setElementOnHover('C84')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
          />
          <path
            className="cls-6"
            d="M117.9922,135.1322a.94.94,0,0,1-.459-.3232.8491.8491,0,0,1-.1494-.5039.9844.9844,0,0,1,.3184-.7451,1.1789,1.1789,0,0,1,.8467-.3018,1.1909,1.1909,0,0,1,.8554.3086,1.0014,1.0014,0,0,1,.3243.752.8268.8268,0,0,1-.1485.4912.9292.9292,0,0,1-.45.3222,1.0949,1.0949,0,0,1,.5694.3936,1.0805,1.0805,0,0,1,.1953.6474,1.1666,1.1666,0,0,1-.3682.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1818,1.1818,0,0,1-.3682-.8877,1.0741,1.0741,0,0,1,.2-.6631A1.0121,1.0121,0,0,1,117.9922,135.1322Zm-.26,1.03a.9014.9014,0,0,0,.1.4121.7118.7118,0,0,0,.3008.3086.8718.8718,0,0,0,.4287.1094.8041.8041,0,0,0,.59-.23.785.785,0,0,0,.2324-.584.7983.7983,0,0,0-.2393-.5947.8185.8185,0,0,0-.6-.2354.7929.7929,0,0,0-.8134.8135Zm.16-1.874a.6356.6356,0,0,0,.1856.4707.6559.6559,0,0,0,.4814.1826.6451.6451,0,0,0,.4727-.1816.6017.6017,0,0,0,.1836-.4444.62.62,0,0,0-.19-.4609.6975.6975,0,0,0-.9453-.0039A.5908.5908,0,0,0,117.8926,134.2885Z"
          />
          <path
            className="cls-6"
            d="M122.26,137.3334v-.9717h-1.7607v-.457l1.8515-2.63h.4073v2.63h.5478v.457h-.5478v.9717Zm0-1.4287v-1.8291l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_06_krs_C85'
              ? 'white-text'
              : elementOnHover === 'C85'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 85)}
          onMouseOver={() => setElementOnHover('C85')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-6"
            d="M77.0992,143.6791a.9383.9383,0,0,1-.4595-.3232.8493.8493,0,0,1-.1494-.5039.9863.9863,0,0,1,.3183-.7452,1.34,1.34,0,0,1,1.7027.0069,1.001,1.001,0,0,1,.3237.7519.826.826,0,0,1-.1479.4912.932.932,0,0,1-.45.3223,1.0947,1.0947,0,0,1,.5688.3936,1.0761,1.0761,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1832,1.1832,0,0,1-.3682-.8876,1.0712,1.0712,0,0,1,.2007-.6631A1.0107,1.0107,0,0,1,77.0992,143.6791Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7871.7871,0,0,0,.2324-.584.798.798,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,142.8354a.634.634,0,0,0,.1855.4707.6557.6557,0,0,0,.4814.1826.6431.6431,0,0,0,.4722-.1817.6.6,0,0,0,.1841-.4443.622.622,0,0,0-.1895-.4609.6973.6973,0,0,0-.9453-.0039A.5885.5885,0,0,0,77,142.8354Z"
          />
          <path
            className="cls-6"
            d="M79.7691,144.8168l.5229-.0439a.9384.9384,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.7955.7955,0,0,0,.6089-.27,1.0224,1.0224,0,0,0,.2495-.72.9239.9239,0,0,0-.24-.6728.8359.8359,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.85.85,0,0,0-.3047.2842l-.4677-.0606.393-2.085h2.0181v.4766H80.65l-.2188,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.2124,1.2124,0,0,1,.897.3682,1.2851,1.2851,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2853,1.2853,0,0,1-1.0659.4931,1.3114,1.3114,0,0,1-.9038-.31A1.2082,1.2082,0,0,1,79.7691,144.8168Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_06_krs_C86'
              ? 'white-text'
              : elementOnHover === 'C86'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 86)}
          onMouseOver={() => setElementOnHover('C86')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-6"
            d="M77.0992,130.02a.9383.9383,0,0,1-.4595-.3232.8491.8491,0,0,1-.1494-.5039.9861.9861,0,0,1,.3183-.7451,1.34,1.34,0,0,1,1.7027.0068,1.0012,1.0012,0,0,1,.3237.752.8264.8264,0,0,1-.1479.4912.9325.9325,0,0,1-.45.3222,1.0952,1.0952,0,0,1,.5688.3936,1.0763,1.0763,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1833,1.1833,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0118,1.0118,0,0,1,77.0992,130.02Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7869.7869,0,0,0,.2324-.584.7982.7982,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,129.1762a.634.634,0,0,0,.1855.4707.6553.6553,0,0,0,.4814.1826.643.643,0,0,0,.4722-.1816.6.6,0,0,0,.1841-.4444.622.622,0,0,0-.1895-.4609.6975.6975,0,0,0-.9453-.0039A.5887.5887,0,0,0,77,129.1762Z"
          />
          <path
            className="cls-6"
            d="M82.3545,129.1566l-.4956.0391a.94.94,0,0,0-.188-.4267.6606.6606,0,0,0-.4985-.2129.6831.6831,0,0,0-.418.1328,1.1383,1.1383,0,0,0-.3711.5009,2.6266,2.6266,0,0,0-.1411.9385,1.1576,1.1576,0,0,1,.4405-.4072,1.1906,1.1906,0,0,1,.5454-.1328,1.1275,1.1275,0,0,1,.8481.3672,1.316,1.316,0,0,1,.35.9482,1.5625,1.5625,0,0,1-.1645.71,1.1992,1.1992,0,0,1-.4527.5019,1.2364,1.2364,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3129,2.3129,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2481,1.2481,0,0,1,1.0215-.4658,1.087,1.087,0,0,1,1.1513,1.0107Zm-2.0346,1.75a1.1565,1.1565,0,0,0,.1093.4922.8115.8115,0,0,0,.3057.3584.766.766,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.738.738,0,0,0-.5644-.2422.77.77,0,0,0-.5733.2422A.8732.8732,0,0,0,80.32,130.9066Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_06_krs_C87'
              ? 'white-text'
              : elementOnHover === 'C87'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(6, 87)}
          onMouseOver={() => setElementOnHover('C87')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-6"
            d="M77.0992,116.6156a.9383.9383,0,0,1-.4595-.3232.8491.8491,0,0,1-.1494-.5039.9861.9861,0,0,1,.3183-.7451,1.34,1.34,0,0,1,1.7027.0068,1.0012,1.0012,0,0,1,.3237.752.8264.8264,0,0,1-.1479.4912.9325.9325,0,0,1-.45.3222,1.0952,1.0952,0,0,1,.5688.3936,1.0763,1.0763,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1833,1.1833,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0118,1.0118,0,0,1,77.0992,116.6156Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7869.7869,0,0,0,.2324-.584.7982.7982,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,115.7719a.634.634,0,0,0,.1855.4707.6553.6553,0,0,0,.4814.1826.643.643,0,0,0,.4722-.1816.6.6,0,0,0,.1841-.4444.622.622,0,0,0-.1895-.4609.6975.6975,0,0,0-.9453-.0039A.5888.5888,0,0,0,77,115.7719Z"
          />
          <path
            className="cls-6"
            d="M79.8023,115.29v-.48h2.6269v.3877a5.5365,5.5365,0,0,0-.768,1.0967,6.6507,6.6507,0,0,0-.5884,1.4063,5.2241,5.2241,0,0,0-.1909,1.1152H80.37a4.9542,4.9542,0,0,1,.1884-1.1572,6.0606,6.0606,0,0,1,.5162-1.3076A5.5856,5.5856,0,0,1,81.79,115.29Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 26 ? (
          <g id="A26-2" data-name="A26">
            <path
              id="A26cone"
              className="cls-8"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A26-3"
              data-name="A26"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 27 ? (
          <g id="A27-2" data-name="A27">
            <path
              id="A27cone"
              className="cls-10"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A27-3"
              data-name="A27"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 28 ? (
          <g id="A28-2" data-name="A28">
            <path
              id="A28cone"
              className="cls-11"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A28-3"
              data-name="A28"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 29 ? (
          <g id="A29-2" data-name="A29">
            <path
              id="A29cone"
              className="cls-12"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A29-3"
              data-name="A29"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 30 ? (
          <g id="A30-2" data-name="A30">
            <path
              id="A30cone"
              className="cls-13"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A30-3"
              data-name="A30"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 48 ? (
          <g id="B48-2" data-name="B48">
            <path
              id="B48cone"
              className="cls-14"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B48-3"
              data-name="B48"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 49 ? (
          <g id="B49-2" data-name="B49">
            <path
              id="B49cone"
              className="cls-15"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B49-3"
              data-name="B49"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 50 ? (
          <g id="B50-2" data-name="B50">
            <path
              id="B50cone"
              className="cls-16"
              d="M86.1952,75.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B50-3"
              data-name="B50"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 83 ? (
          <g id="C83-2" data-name="C83">
            <path
              id="C83cone"
              className="cls-17"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C83-3"
              data-name="C83"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 84 ? (
          <g id="C84-2" data-name="C84">
            <path
              id="C84cone"
              className="cls-18"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C84-3"
              data-name="C84"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === `${84}small` ? (
          <g id="C84-4" data-name="C84">
            <path
              id="C84cone-2"
              data-name="C84cone"
              className="cls-19"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C84-5"
              data-name="C84"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 85 ? (
          <g id="C85-2" data-name="C85">
            <path
              id="C85cone"
              className="cls-20"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C85-3"
              data-name="C85"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 86 ? (
          <g id="C86-2" data-name="C86">
            <path
              id="C86cone"
              className="cls-21"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C86-3"
              data-name="C86"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 87 ? (
          <g id="C87-2" data-name="C87">
            <path
              id="C87cone"
              className="cls-22"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C87-3"
              data-name="C87"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_6-2" data-name="6">
          <path
            className="cls-7"
            d="M174.24,81.6225l-.7432.0581a1.4127,1.4127,0,0,0-.2822-.6392.99.99,0,0,0-.7481-.32,1.0264,1.0264,0,0,0-.6269.1992,1.7073,1.7073,0,0,0-.5567.7515,3.9163,3.9163,0,0,0-.2109,1.4077,1.7267,1.7267,0,0,1,.66-.61,1.7721,1.7721,0,0,1,.8173-.1992,1.6922,1.6922,0,0,1,1.2725.55,1.9727,1.9727,0,0,1,.5254,1.4219,2.3338,2.3338,0,0,1-.2471,1.0649,1.7929,1.7929,0,0,1-.6787.7539,1.854,1.854,0,0,1-.98.2618,1.9159,1.9159,0,0,1-1.5244-.6875,3.4718,3.4718,0,0,1-.59-2.2652,4.06,4.06,0,0,1,.6523-2.5659,1.8729,1.8729,0,0,1,1.5322-.6978,1.6318,1.6318,0,0,1,1.7276,1.5157Zm-3.0518,2.6245a1.7154,1.7154,0,0,0,.1641.7387,1.2185,1.2185,0,0,0,.458.5381,1.1514,1.1514,0,0,0,.6191.1846,1.0539,1.0539,0,0,0,.8135-.3818,1.5015,1.5015,0,0,0,.3408-1.0381,1.41,1.41,0,0,0-.3359-.9946,1.1066,1.1066,0,0,0-.8477-.3633,1.1523,1.1523,0,0,0-.8593.3633A1.3091,1.3091,0,0,0,171.1885,84.247Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  26: [54.848, 7.0884],
  27: [36.4846, 57.3091],
  28: [63.4377, 57.3091],
  29: [77.9525, 57.3091],
  30: [82.3715, 7.0884],
  48: [135.7828, 36.2417],
  49: [135.7828, 72.5363],
  50: [90.7952, 72.5363],
  83: [90.7952, 86.0066],
  '84small': [135.7828, 96.0066],
  84: [135.7828, 144.7428],
  85: [56.9873, 143.5353],
  86: [56.9873, 130.4153],
  87: [56.9873, 117.0263],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }
  .cls-17 {
    fill: url(#linear-gradient-9);
  }
  .cls-18 {
    fill: url(#linear-gradient-10);
  }
  .cls-19 {
    fill: url(#linear-gradient-11);
  }
  .cls-20 {
    fill: url(#linear-gradient-12);
  }
  .cls-21 {
    fill: url(#linear-gradient-13);
  }
  .cls-22 {
    fill: url(#linear-gradient-14);
  }

  #A26,
  #A27,
  #A28,
  #A29,
  #A30,
  #B48,
  #B49,
  #B50,
  #C83,
  #C84,
  #C85,
  #C86,
  #C87 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
