import * as React from 'react'
import styled from 'styled-components'

export default function Floor7({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_7"
      data-name="7"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="219.9698"
          y1="273.003"
          x2="219.9698"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="270.9504"
          y1="239.1951"
          x2="270.9504"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(7, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A31"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_A31'
                ? 'active-apt'
                : elementOnHover === 'A31'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 14.179 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 34.495 47.456 34.495 47.456 23.784 67.917 23.784 67.917 14.179 23.779 14.179"
          />
        </g>
        <g
          onClick={() => changeView(7, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A32"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_A32'
                ? 'active-apt'
                : elementOnHover === 'A32'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(7, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A33"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_A33'
                ? 'active-apt'
                : elementOnHover === 'A33'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 13.449 67.917 13.449 67.917 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(7, 51)}
          onMouseOver={() => setElementOnHover('B51')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B51"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_B51'
                ? 'active-apt'
                : elementOnHover === 'B51'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 13.349 95.873 39.77 114.376 39.749 114.376 44.171 134.844 44.171 134.844 41.199 138.665 41.199 138.665 28.712 134.527 28.712 134.527 13.549 95.873 13.349"
          />
        </g>
        <g
          onClick={() => changeView(7, 52)}
          onMouseOver={() => setElementOnHover('B52')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B52"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_B52'
                ? 'active-apt'
                : elementOnHover === 'B52'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 77.319 134.48 77.319 134.488 76.829 138.665 76.829 138.665 68.127 134.642 68.276 134.642 44.394 114.29 44.394 114.029 77.319"
          />
        </g>
        <g
          onClick={() => changeView(7, 53)}
          onMouseOver={() => setElementOnHover('B53')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B53"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_B53'
                ? 'active-apt'
                : elementOnHover === 'B53'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 67.625 87.471 67.625 87.471 77.527 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(7, 88)}
          onMouseOver={() => setElementOnHover('C88')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C88"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C88'
                ? 'active-apt'
                : elementOnHover === 'C88'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 115.219 109.277 115.219 91.798 134.369 92.111 134.369 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(7, 89)}
          onMouseOver={() => setElementOnHover('C89')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C89"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C89'
                ? 'active-apt'
                : elementOnHover === 'C89'
                ? 'on-hover'
                : ''
            }`}
            points="134.116 100.642 134.084 138.059 138.567 138.152 138.567 151.296 92.944 151.213 92.944 127.956 114.843 127.956 114.843 92.133 134.931 92.133 134.931 92.758 138.567 92.758"
          />
        </g>
        <g
          onClick={() => changeView(7, `${89}small`)}
          onMouseOver={() => setElementOnHover('C89small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C89small"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C89_small'
                ? 'active-apt'
                : elementOnHover === 'C89small'
                ? 'on-hover'
                : ''
            }`}
            x="129.3365"
            y="92.7581"
            width="9.3225"
            height="7.722"
          />
        </g>
        <g
          onClick={() => changeView(7, 89)}
          onMouseOver={() => setElementOnHover('C89')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C89-2"
            data-name="C89"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C89' ||
              sceneName === 'scene_Otavallanhovi_07_krs_C89_small'
                ? 'active-apt'
                : elementOnHover === 'C89'
                ? 'on-hover'
                : ''
            }`}
            points="92.944 151.213 92.944 127.956 114.843 127.956 114.843 92.133 134.931 92.133 134.931 92.758 129.209 92.758 129.209 100.581 134.116 100.642 134.084 138.059 138.567 138.152 138.567 151.296 92.944 151.213"
          />
        </g>
        <g
          onClick={() => changeView(7, `${90}small`)}
          onMouseOver={() => setElementOnHover('C90small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C90small"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C90_small'
                ? 'active-apt'
                : elementOnHover === 'C90small'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.1984"
            width="8.5454"
            height="14.0142"
          />
        </g>
        <g
          onClick={() => changeView(7, 90)}
          onMouseOver={() => setElementOnHover('C90')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C90"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C90' ||
              sceneName === 'scene_Otavallanhovi_07_krs_C90_small'
                ? 'active-apt'
                : elementOnHover === 'C90'
                ? 'on-hover'
                : ''
            }`}
            points="92.944 108.533 54.177 108.533 54.177 137.311 62.7 137.311 62.505 151.213 92.944 151.213 92.944 108.533"
          />
        </g>
      </g>
      <g id="levelfloor">
        <line
          className="cls-3"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-3"
          x1="54.1437"
          y1="137.3107"
          x2="62.1851"
          y2="137.3107"
        />
        <polyline
          className="cls-3"
          points="92.88 151.631 92.88 127.956 115.315 127.956"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-3"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-3"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-3"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-3"
          x1="95.8734"
          y1="13.9871"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-3"
          points="73.529 34.495 47.481 34.495 45.31 34.495 45.31 51.892"
        />
        <polyline
          className="cls-3"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715 47.456 23.715 47.456 34.495"
        />
        <line
          className="cls-3"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-3"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-3"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 67.625 95.028 67.625"
        />
        <path
          className="cls-4"
          d="M133.6608,14.2315l.0115,13.2826h-3.8069l.0023,1.42.0238,14.2487.0023,1.4075,1.4075.0075,2.549.0134-.0118,22.2425h-5.4837l.0024,1.42.0139,8.4067.0023,1.415h5.289V91.4857h-5.2913V101.77l1.4221-.0049,3.8031-.0128V136.91l-3.814.0163-1.4112.006v13.282H63.3133V109.9506H96.36V51.5682H24.5865V14.2315H133.6608m1.4161-1.4173H23.1691V52.9855H94.9423v55.5478H61.896v43.098h67.8924v-13.288l5.2252-.0224V100.33l-5.2252.0176V92.903H135.08V76.6775h-5.2913l-.0139-8.4068h5.4805l.0134-25.07-3.96-.0209-.0237-14.2487h3.8057l-.0139-16.1173Z"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.152 134.845 138.152"
        />
        <polyline
          className="cls-5"
          points="134.931 100.48 138.659 100.48 138.659 92.758 134.931 92.758"
        />
        <polyline
          className="cls-5"
          points="135.255 68.138 138.665 68.138 138.665 76.829 134.882 76.829"
        />
        <polyline
          className="cls-5"
          points="135.107 28.712 138.677 28.712 138.677 41.237 135.091 41.237"
        />
        <line
          className="cls-3"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
        <polyline
          className="cls-3"
          points="128.82 77.59 95.583 77.59 87.471 77.59"
        />
        <polyline
          className="cls-3"
          points="128.396 91.798 114.975 91.798 114.975 109.364"
        />
        <line
          className="cls-3"
          x1="134.8442"
          y1="40.6548"
          x2="134.8442"
          y2="43.4935"
        />
        <line
          className="cls-3"
          x1="67.8878"
          y1="23.8747"
          x2="67.8878"
          y2="14.1018"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_A31'
              ? 'white-text'
              : elementOnHover === 'A31'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M35.19,34.3466l.498-.0664a1.1013,1.1013,0,0,0,.292.61.72.72,0,0,0,.5025.1866.806.806,0,0,0,.5937-.2432.8206.8206,0,0,0,.2422-.6035.7656.7656,0,0,0-.2241-.5664.7748.7748,0,0,0-.57-.2227,1.4228,1.4228,0,0,0-.3516.0557l.0557-.4375a.7117.7117,0,0,0,.08.0058,1.0191,1.0191,0,0,0,.5727-.167.5674.5674,0,0,0,.2549-.5117.6068.6068,0,0,0-.1856-.4541.658.658,0,0,0-.479-.18.6782.6782,0,0,0-.4843.1827.9213.9213,0,0,0-.2491.5478l-.498-.0879a1.2838,1.2838,0,0,1,.415-.7773,1.2,1.2,0,0,1,.8057-.2754,1.3313,1.3313,0,0,1,.6118.1426,1.0369,1.0369,0,0,1,.4278.39.9972.9972,0,0,1,.1479.5225.8583.8583,0,0,1-.1411.48.9669.9669,0,0,1-.418.3427.9459.9459,0,0,1,.5591.3448,1.05,1.05,0,0,1,.1992.6552,1.1975,1.1975,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.277,1.277,0,0,1-.8872-.3183A1.2343,1.2343,0,0,1,35.19,34.3466Z"
          />
          <path
            className="cls-6"
            d="M40.5,35.4179h-.4985V32.2431a2.5875,2.5875,0,0,1-.4717.3427,3.4013,3.4013,0,0,1-.5249.2579v-.4815a3.0278,3.0278,0,0,0,.731-.4765,1.8731,1.8731,0,0,0,.4428-.543H40.5Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_A32'
              ? 'white-text'
              : elementOnHover === 'A32'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M60.91,43.8529l.4981-.0664a1.1006,1.1006,0,0,0,.2919.61.72.72,0,0,0,.5025.1865.8058.8058,0,0,0,.5937-.2432.82.82,0,0,0,.2422-.6035.7658.7658,0,0,0-.2241-.5664.7752.7752,0,0,0-.57-.2226,1.4227,1.4227,0,0,0-.3516.0556l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5677.5677,0,0,0,.2549-.5117.6071.6071,0,0,0-.1855-.4541.6581.6581,0,0,0-.479-.18.6782.6782,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.4981-.0879a1.2844,1.2844,0,0,1,.4151-.7774,1.2,1.2,0,0,1,.8056-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0365,1.0365,0,0,1,.4278.39.9966.9966,0,0,1,.1479.5224.858.858,0,0,1-.1411.48.9665.9665,0,0,1-.418.3428.9457.9457,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2767,1.2767,0,0,1-.8872-.3184A1.2339,1.2339,0,0,1,60.91,43.8529Z"
          />
          <path
            className="cls-6"
            d="M66.962,44.4457v.4785H64.2793a.883.883,0,0,1,.0586-.3457,1.8175,1.8175,0,0,1,.3277-.54,5.0835,5.0835,0,0,1,.6518-.6143,5.5284,5.5284,0,0,0,.8946-.86,1.0281,1.0281,0,0,0,.2324-.5987.6677.6677,0,0,0-.2119-.5.7634.7634,0,0,0-.5523-.2031.7763.7763,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0527a1.26,1.26,0,0,1,.396-.8731,1.3509,1.3509,0,0,1,.9218-.3008,1.287,1.287,0,0,1,.9244.3242,1.0634,1.0634,0,0,1,.3408.8028,1.2153,1.2153,0,0,1-.1.4785,1.8564,1.8564,0,0,1-.3311.4961,8.0118,8.0118,0,0,1-.768.7139c-.2989.2509-.4913.4218-.5757.5107a1.6249,1.6249,0,0,0-.21.2705Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_A33'
              ? 'white-text'
              : elementOnHover === 'A33'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M86.09,39.0419l.498-.0664a1.1007,1.1007,0,0,0,.292.61.72.72,0,0,0,.5025.1866.806.806,0,0,0,.5937-.2432.8206.8206,0,0,0,.2422-.6035.7656.7656,0,0,0-.2241-.5664.7748.7748,0,0,0-.57-.2227,1.4221,1.4221,0,0,0-.3516.0557l.0557-.4375a.7157.7157,0,0,0,.08.0059,1.02,1.02,0,0,0,.5728-.167.5677.5677,0,0,0,.2549-.5118.6069.6069,0,0,0-.1856-.4541.6579.6579,0,0,0-.479-.18.6783.6783,0,0,0-.4844.1826.921.921,0,0,0-.249.5478l-.498-.0879a1.2841,1.2841,0,0,1,.415-.7773,1.2,1.2,0,0,1,.8057-.2754,1.3313,1.3313,0,0,1,.6118.1426,1.0367,1.0367,0,0,1,.4277.39.9964.9964,0,0,1,.148.5225.8583.8583,0,0,1-.1411.48.9669.9669,0,0,1-.418.3427.9462.9462,0,0,1,.5591.3448,1.0506,1.0506,0,0,1,.1992.6552,1.1975,1.1975,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.277,1.277,0,0,1-.8872-.3184A1.2343,1.2343,0,0,1,86.09,39.0419Z"
          />
          <path
            className="cls-6"
            d="M89.5269,39.0419l.498-.0664a1.1013,1.1013,0,0,0,.292.61.72.72,0,0,0,.5025.1866.806.806,0,0,0,.5937-.2432.8206.8206,0,0,0,.2422-.6035.7656.7656,0,0,0-.2241-.5664.7748.7748,0,0,0-.57-.2227,1.4228,1.4228,0,0,0-.3516.0557l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5677.5677,0,0,0,.2549-.5118.6069.6069,0,0,0-.1856-.4541.6579.6579,0,0,0-.479-.18.6781.6781,0,0,0-.4843.1826.9216.9216,0,0,0-.2491.5478l-.498-.0879a1.2841,1.2841,0,0,1,.415-.7773,1.2,1.2,0,0,1,.8057-.2754,1.3313,1.3313,0,0,1,.6118.1426,1.0369,1.0369,0,0,1,.4278.39.9972.9972,0,0,1,.1479.5225.8583.8583,0,0,1-.1411.48.9663.9663,0,0,1-.418.3427.9459.9459,0,0,1,.5591.3448,1.0506,1.0506,0,0,1,.1992.6552,1.1975,1.1975,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.277,1.277,0,0,1-.8872-.3184A1.2343,1.2343,0,0,1,89.5269,39.0419Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_B51'
              ? 'white-text'
              : elementOnHover === 'B51'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 51)}
          onMouseOver={() => setElementOnHover('B51')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M115.1836,26.6557l.5225-.044a.9359.9359,0,0,0,.27.5742.7291.7291,0,0,0,.5107.1924.7947.7947,0,0,0,.6084-.27,1.02,1.02,0,0,0,.25-.72.9224.9224,0,0,0-.24-.6729.835.835,0,0,0-.6269-.247.879.879,0,0,0-.4346.1093.8552.8552,0,0,0-.3047.2842l-.4678-.06.3936-2.085h2.0176v.4766h-1.6192l-.2187,1.0908a1.3206,1.3206,0,0,1,.7666-.2549,1.2139,1.2139,0,0,1,.8974.3682,1.2866,1.2866,0,0,1,.3653.9463,1.4792,1.4792,0,0,1-.3213.9521,1.2841,1.2841,0,0,1-1.0655.4932,1.3123,1.3123,0,0,1-.9043-.3106A1.2073,1.2073,0,0,1,115.1836,26.6557Z"
          />
          <path
            className="cls-6"
            d="M120.4971,27.7191h-.499V24.5443a2.5863,2.5863,0,0,1-.4717.3428,3.366,3.366,0,0,1-.5244.2578v-.4814a3.0278,3.0278,0,0,0,.7305-.4766,1.8689,1.8689,0,0,0,.4433-.5429h.3213Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_B52'
              ? 'white-text'
              : elementOnHover === 'B52'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 52)}
          onMouseOver={() => setElementOnHover('B52')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M124.9746,59.2484l.5235-.0439a.94.94,0,0,0,.27.5742.7309.7309,0,0,0,.5108.1924.7952.7952,0,0,0,.6093-.27,1.0242,1.0242,0,0,0,.2491-.72.9261.9261,0,0,0-.2393-.6729.838.838,0,0,0-.627-.2471.8735.8735,0,0,0-.4345.1094.847.847,0,0,0-.3047.2842l-.4678-.0605.3926-2.085h2.0185v.4766h-1.6191l-.2187,1.0908a1.3184,1.3184,0,0,1,.7666-.2549,1.21,1.21,0,0,1,.8964.3682,1.2826,1.2826,0,0,1,.3653.9462,1.4787,1.4787,0,0,1-.32.9522,1.2871,1.2871,0,0,1-1.0664.4932,1.31,1.31,0,0,1-.9034-.3106A1.2089,1.2089,0,0,1,124.9746,59.2484Z"
          />
          <path
            className="cls-6"
            d="M131.03,59.8334v.4785h-2.6826a.8652.8652,0,0,1,.0586-.3457,1.8046,1.8046,0,0,1,.3271-.54,5.0945,5.0945,0,0,1,.6524-.6143,5.5267,5.5267,0,0,0,.8945-.86,1.0308,1.0308,0,0,0,.2324-.5987.67.67,0,0,0-.2119-.5.7643.7643,0,0,0-.5527-.2031.7748.7748,0,0,0-.5752.2158.8139.8139,0,0,0-.2188.5977l-.5127-.0527a1.2584,1.2584,0,0,1,.3965-.8731,1.35,1.35,0,0,1,.9219-.3008,1.2853,1.2853,0,0,1,.9238.3242,1.0613,1.0613,0,0,1,.3409.8028,1.2154,1.2154,0,0,1-.1.4785,1.8558,1.8558,0,0,1-.331.4961,7.9961,7.9961,0,0,1-.7676.7139c-.2988.2509-.4912.4218-.5762.5107a1.5989,1.5989,0,0,0-.21.27Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_07_krs_B53'
              ? 'white-text'
              : elementOnHover === 'B53'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 53)}
          onMouseOver={() => setElementOnHover('B53')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M104.1372,67.2309l.523-.044a.9383.9383,0,0,0,.27.5742.73.73,0,0,0,.5108.1924.795.795,0,0,0,.6088-.27,1.0222,1.0222,0,0,0,.25-.72.9243.9243,0,0,0-.24-.6729.8364.8364,0,0,0-.6269-.247.8762.8762,0,0,0-.4346.1093.851.851,0,0,0-.3047.2842l-.4678-.06.3931-2.085h2.0181v.4766h-1.6192l-.2187,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.212,1.212,0,0,1,.8969.3682,1.2846,1.2846,0,0,1,.3653.9463,1.4788,1.4788,0,0,1-.3208.9521,1.2855,1.2855,0,0,1-1.0659.4932,1.3111,1.3111,0,0,1-.9039-.3106A1.2078,1.2078,0,0,1,104.1372,67.2309Z"
          />
          <path
            className="cls-6"
            d="M107.5767,67.2231l.498-.0665a1.1013,1.1013,0,0,0,.292.61.72.72,0,0,0,.5025.1865.8058.8058,0,0,0,.5937-.2431.82.82,0,0,0,.2422-.6036.7658.7658,0,0,0-.2241-.5664.7752.7752,0,0,0-.57-.2226,1.422,1.422,0,0,0-.3516.0556l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5674.5674,0,0,0,.2549-.5117.6071.6071,0,0,0-.1855-.4541.6584.6584,0,0,0-.4791-.18.6781.6781,0,0,0-.4843.1826.9213.9213,0,0,0-.249.5479l-.4981-.0879a1.2848,1.2848,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0365,1.0365,0,0,1,.4278.39.9969.9969,0,0,1,.1479.5224.858.858,0,0,1-.1411.48.9665.9665,0,0,1-.418.3428.9462.9462,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2766,1.2766,0,0,1-.8872-.3184A1.234,1.234,0,0,1,107.5767,67.2231Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_07_krs_C88'
              ? 'white-text'
              : elementOnHover === 'C88'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(7, 88)}
          onMouseOver={() => setElementOnHover('C88')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-6"
            d="M105.58,99.98a.938.938,0,0,1-.4594-.3233.8483.8483,0,0,1-.15-.5039.9863.9863,0,0,1,.3184-.7451,1.34,1.34,0,0,1,1.7026.0069,1.0007,1.0007,0,0,1,.3238.7519.8254.8254,0,0,1-.148.4912.931.931,0,0,1-.45.3223,1.0954,1.0954,0,0,1,.5689.3935,1.0772,1.0772,0,0,1,.1953.6475,1.1648,1.1648,0,0,1-.3682.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1836,1.1836,0,0,1-.3681-.8877,1.0722,1.0722,0,0,1,.2006-.6631A1.0115,1.0115,0,0,1,105.58,99.98Zm-.26,1.03a.902.902,0,0,0,.101.4121.7106.7106,0,0,0,.3.3086.876.876,0,0,0,.4292.1093.8037.8037,0,0,0,.59-.23.7867.7867,0,0,0,.2324-.5839.7985.7985,0,0,0-.2393-.5948.82.82,0,0,0-.6-.2353.7927.7927,0,0,0-.8139.8135Zm.1606-1.8741a.6335.6335,0,0,0,.1856.4707.6552.6552,0,0,0,.4814.1827.6432.6432,0,0,0,.4722-.1817.6.6,0,0,0,.1841-.4443.6222.6222,0,0,0-.1895-.461.6975.6975,0,0,0-.9453-.0039A.5889.5889,0,0,0,105.48,99.1361Z"
          />
          <path
            className="cls-6"
            d="M109.0162,99.98a.9378.9378,0,0,1-.4595-.3233.8489.8489,0,0,1-.1494-.5039.9862.9862,0,0,1,.3183-.7451,1.34,1.34,0,0,1,1.7027.0069,1.001,1.001,0,0,1,.3237.7519.8254.8254,0,0,1-.148.4912.931.931,0,0,1-.45.3223,1.095,1.095,0,0,1,.5689.3935,1.0766,1.0766,0,0,1,.1953.6475,1.1648,1.1648,0,0,1-.3682.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1836,1.1836,0,0,1-.3681-.8877,1.0716,1.0716,0,0,1,.2007-.6631A1.0111,1.0111,0,0,1,109.0162,99.98Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8757.8757,0,0,0,.4292.1093.8036.8036,0,0,0,.59-.23.7867.7867,0,0,0,.2324-.5839.7984.7984,0,0,0-.2392-.5948.82.82,0,0,0-.6-.2353.7927.7927,0,0,0-.814.8135Zm.1606-1.8741a.6339.6339,0,0,0,.1856.4707.6554.6554,0,0,0,.4814.1827.6432.6432,0,0,0,.4722-.1817.6.6,0,0,0,.1841-.4443.6222.6222,0,0,0-.19-.461.6975.6975,0,0,0-.9453-.0039A.5889.5889,0,0,0,108.9165,99.1361Z"
          />
        </g>
        {apartmentNumber === `${89}small` ? (
          <g
            id="C89small-2"
            data-name="C89small"
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C89_small'
                ? 'white-text'
                : elementOnHover === 'C89small'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(7, `${89}small`)}
            onMouseOver={() => setElementOnHover('C89small')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
            />
            <path
              className="cls-6"
              d="M117.9922,135.1322a.94.94,0,0,1-.459-.3232.8491.8491,0,0,1-.1494-.5039.9844.9844,0,0,1,.3184-.7451,1.1789,1.1789,0,0,1,.8467-.3018,1.1909,1.1909,0,0,1,.8554.3086,1.0014,1.0014,0,0,1,.3243.752.8268.8268,0,0,1-.1485.4912.9292.9292,0,0,1-.45.3222,1.0949,1.0949,0,0,1,.5694.3936,1.0805,1.0805,0,0,1,.1953.6474,1.1666,1.1666,0,0,1-.3682.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1818,1.1818,0,0,1-.3682-.8877,1.0741,1.0741,0,0,1,.2-.6631A1.0121,1.0121,0,0,1,117.9922,135.1322Zm-.26,1.03a.9014.9014,0,0,0,.1.4121.7118.7118,0,0,0,.3008.3086.8718.8718,0,0,0,.4287.1094.8041.8041,0,0,0,.59-.23.785.785,0,0,0,.2324-.584.7983.7983,0,0,0-.2393-.5947.8185.8185,0,0,0-.6-.2354.7929.7929,0,0,0-.8134.8135Zm.16-1.874a.6356.6356,0,0,0,.1856.4707.6559.6559,0,0,0,.4814.1826.6451.6451,0,0,0,.4727-.1816.6017.6017,0,0,0,.1836-.4444.62.62,0,0,0-.19-.4609.6975.6975,0,0,0-.9453-.0039A.5908.5908,0,0,0,117.8926,134.2885Z"
            />
            <path
              className="cls-6"
              d="M120.7373,136.3949l.4786-.0439a.8312.8312,0,0,0,.2324.4892.639.639,0,0,0,.44.1524.7654.7654,0,0,0,.4024-.1045.8686.8686,0,0,0,.2841-.2813,1.6635,1.6635,0,0,0,.1856-.4746,2.5023,2.5023,0,0,0,.0742-.6093c0-.0225-.001-.0557-.002-.1a1.1461,1.1461,0,0,1-.4091.3868,1.1086,1.1086,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3487-.9629,1.3669,1.3669,0,0,1,.3643-.9941,1.2161,1.2161,0,0,1,.9121-.377,1.2974,1.2974,0,0,1,.7236.2139,1.3276,1.3276,0,0,1,.4981.6074,2.9733,2.9733,0,0,1,.1709,1.1416,3.705,3.705,0,0,1-.169,1.2393,1.447,1.447,0,0,1-.5029.7011,1.2929,1.2929,0,0,1-.7812.2412,1.0719,1.0719,0,0,1-1.1407-1.0078Zm2.04-1.791a.9747.9747,0,0,0-.2285-.6807.7158.7158,0,0,0-.55-.2519.7562.7562,0,0,0-.5782.2715,1.0056,1.0056,0,0,0-.2461.7031.8654.8654,0,0,0,.2334.6289.7694.7694,0,0,0,.5772.2422.7376.7376,0,0,0,.5693-.2422A.9526.9526,0,0,0,122.7774,134.6039Z"
            />
          </g>
        ) : (
          <g
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C89'
                ? 'white-text'
                : elementOnHover === 'C89'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(7, 89)}
            onMouseOver={() => setElementOnHover('C89')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
            />
            <path
              className="cls-6"
              d="M117.9922,135.1322a.94.94,0,0,1-.459-.3232.8491.8491,0,0,1-.1494-.5039.9844.9844,0,0,1,.3184-.7451,1.1789,1.1789,0,0,1,.8467-.3018,1.1909,1.1909,0,0,1,.8554.3086,1.0014,1.0014,0,0,1,.3243.752.8268.8268,0,0,1-.1485.4912.9292.9292,0,0,1-.45.3222,1.0949,1.0949,0,0,1,.5694.3936,1.0805,1.0805,0,0,1,.1953.6474,1.1666,1.1666,0,0,1-.3682.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1818,1.1818,0,0,1-.3682-.8877,1.0741,1.0741,0,0,1,.2-.6631A1.0121,1.0121,0,0,1,117.9922,135.1322Zm-.26,1.03a.9014.9014,0,0,0,.1.4121.7118.7118,0,0,0,.3008.3086.8718.8718,0,0,0,.4287.1094.8041.8041,0,0,0,.59-.23.785.785,0,0,0,.2324-.584.7983.7983,0,0,0-.2393-.5947.8185.8185,0,0,0-.6-.2354.7929.7929,0,0,0-.8134.8135Zm.16-1.874a.6356.6356,0,0,0,.1856.4707.6559.6559,0,0,0,.4814.1826.6451.6451,0,0,0,.4727-.1816.6017.6017,0,0,0,.1836-.4444.62.62,0,0,0-.19-.4609.6975.6975,0,0,0-.9453-.0039A.5908.5908,0,0,0,117.8926,134.2885Z"
            />
            <path
              className="cls-6"
              d="M120.7373,136.3949l.4786-.0439a.8312.8312,0,0,0,.2324.4892.639.639,0,0,0,.44.1524.7654.7654,0,0,0,.4024-.1045.8686.8686,0,0,0,.2841-.2813,1.6635,1.6635,0,0,0,.1856-.4746,2.5023,2.5023,0,0,0,.0742-.6093c0-.0225-.001-.0557-.002-.1a1.1461,1.1461,0,0,1-.4091.3868,1.1086,1.1086,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3487-.9629,1.3669,1.3669,0,0,1,.3643-.9941,1.2161,1.2161,0,0,1,.9121-.377,1.2974,1.2974,0,0,1,.7236.2139,1.3276,1.3276,0,0,1,.4981.6074,2.9733,2.9733,0,0,1,.1709,1.1416,3.705,3.705,0,0,1-.169,1.2393,1.447,1.447,0,0,1-.5029.7011,1.2929,1.2929,0,0,1-.7812.2412,1.0719,1.0719,0,0,1-1.1407-1.0078Zm2.04-1.791a.9747.9747,0,0,0-.2285-.6807.7158.7158,0,0,0-.55-.2519.7562.7562,0,0,0-.5782.2715,1.0056,1.0056,0,0,0-.2461.7031.8654.8654,0,0,0,.2334.6289.7694.7694,0,0,0,.5772.2422.7376.7376,0,0,0,.5693-.2422A.9526.9526,0,0,0,122.7774,134.6039Z"
            />
          </g>
        )}
        {apartmentNumber === `${90}small` ? (
          <g
            id="C90small-2"
            data-name="C90small"
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C90_small'
                ? 'white-text'
                : elementOnHover === 'C90small'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(7, `${90}small`)}
            onMouseOver={() => setElementOnHover('C90small')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
            />
            <path
              className="cls-6"
              d="M76.4073,131.2826l.479-.0439a.8286.8286,0,0,0,.2324.4892.6377.6377,0,0,0,.44.1524.7669.7669,0,0,0,.4029-.1045.8636.8636,0,0,0,.2837-.2813,1.6544,1.6544,0,0,0,.1855-.4746,2.5027,2.5027,0,0,0,.0747-.6093c0-.0225-.001-.0557-.0024-.1a1.1449,1.1449,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3486-.9629,1.3691,1.3691,0,0,1,.3637-.9941,1.2168,1.2168,0,0,1,.9121-.377,1.2984,1.2984,0,0,1,.7242.2139,1.3281,1.3281,0,0,1,.498.6074,2.9841,2.9841,0,0,1,.17,1.1416,3.6954,3.6954,0,0,1-.1689,1.2393,1.4464,1.4464,0,0,1-.5025.7011,1.2953,1.2953,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1406-1.0078Zm2.04-1.791a.9768.9768,0,0,0-.228-.6807.7165.7165,0,0,0-.55-.2519.7568.7568,0,0,0-.5786.2715,1.0051,1.0051,0,0,0-.2461.7031.8653.8653,0,0,0,.2339.6289.7675.7675,0,0,0,.5771.2422.7365.7365,0,0,0,.5688-.2422A.9526.9526,0,0,0,78.4473,129.4916Z"
            />
            <path
              className="cls-6"
              d="M79.7691,130.22a3.7227,3.7227,0,0,1,.1479-1.1592,1.4449,1.4449,0,0,1,.4405-.6767,1.1267,1.1267,0,0,1,.7348-.2383,1.195,1.195,0,0,1,.5728.1318,1.0993,1.0993,0,0,1,.4072.3789,2.0635,2.0635,0,0,1,.252.6036,4.0383,4.0383,0,0,1,.0913.96,3.71,3.71,0,0,1-.147,1.1524,1.4449,1.4449,0,0,1-.4385.6787,1.1283,1.1283,0,0,1-.7378.2392,1.1139,1.1139,0,0,1-.9219-.4209A2.6748,2.6748,0,0,1,79.7691,130.22Zm.5122,0a2.5418,2.5418,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.002,3.8985,3.8985,0,0,0,0-2.66.6942.6942,0,0,0-.583-.3291.6391.6391,0,0,0-.5479.29A2.5749,2.5749,0,0,0,80.2813,130.22Z"
            />
          </g>
        ) : (
          <g
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_07_krs_C90'
                ? 'white-text'
                : elementOnHover === 'C90'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(7, 90)}
            onMouseOver={() => setElementOnHover('C90')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
            />
            <path
              className="cls-6"
              d="M76.4073,131.2826l.479-.0439a.8286.8286,0,0,0,.2324.4892.6377.6377,0,0,0,.44.1524.7669.7669,0,0,0,.4029-.1045.8636.8636,0,0,0,.2837-.2813,1.6544,1.6544,0,0,0,.1855-.4746,2.5027,2.5027,0,0,0,.0747-.6093c0-.0225-.001-.0557-.0024-.1a1.1449,1.1449,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3486-.9629,1.3691,1.3691,0,0,1,.3637-.9941,1.2168,1.2168,0,0,1,.9121-.377,1.2984,1.2984,0,0,1,.7242.2139,1.3281,1.3281,0,0,1,.498.6074,2.9841,2.9841,0,0,1,.17,1.1416,3.6954,3.6954,0,0,1-.1689,1.2393,1.4464,1.4464,0,0,1-.5025.7011,1.2953,1.2953,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1406-1.0078Zm2.04-1.791a.9768.9768,0,0,0-.228-.6807.7165.7165,0,0,0-.55-.2519.7568.7568,0,0,0-.5786.2715,1.0051,1.0051,0,0,0-.2461.7031.8653.8653,0,0,0,.2339.6289.7675.7675,0,0,0,.5771.2422.7365.7365,0,0,0,.5688-.2422A.9526.9526,0,0,0,78.4473,129.4916Z"
            />
            <path
              className="cls-6"
              d="M79.7691,130.22a3.7227,3.7227,0,0,1,.1479-1.1592,1.4449,1.4449,0,0,1,.4405-.6767,1.1267,1.1267,0,0,1,.7348-.2383,1.195,1.195,0,0,1,.5728.1318,1.0993,1.0993,0,0,1,.4072.3789,2.0635,2.0635,0,0,1,.252.6036,4.0383,4.0383,0,0,1,.0913.96,3.71,3.71,0,0,1-.147,1.1524,1.4449,1.4449,0,0,1-.4385.6787,1.1283,1.1283,0,0,1-.7378.2392,1.1139,1.1139,0,0,1-.9219-.4209A2.6748,2.6748,0,0,1,79.7691,130.22Zm.5122,0a2.5418,2.5418,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.002,3.8985,3.8985,0,0,0,0-2.66.6942.6942,0,0,0-.583-.3291.6391.6391,0,0,0-.5479.29A2.5749,2.5749,0,0,0,80.2813,130.22Z"
            />
          </g>
        )}
      </g>
      <g id="view">
        {apartmentNumber === 31 ? (
          <g id="A31-2" data-name="A31">
            <path
              id="A31cone"
              className="cls-8"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A31-3"
              data-name="A31"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 32 ? (
          <g id="A32-2" data-name="A32">
            <path
              id="A32cone"
              className="cls-10"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A32-3"
              data-name="A32"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 33 ? (
          <g id="A33-2" data-name="A33">
            <path
              id="A33cone"
              className="cls-11"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A33-3"
              data-name="A33"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 51 ? (
          <g id="B51-2" data-name="B51">
            <path
              id="B51_cone"
              data-name="B51 cone"
              className="cls-12"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B51-3"
              data-name="B51"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 52 ? (
          <g id="B52-2" data-name="B52">
            <path
              id="B52cone"
              className="cls-13"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B52-3"
              data-name="B52"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 53 ? (
          <g id="B53-2" data-name="B53">
            <path
              id="B53cone"
              className="cls-14"
              d="M86.1952,75.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B53-3"
              data-name="B53"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 88 ? (
          <g id="C88-2" data-name="C88">
            <path
              id="C88cone"
              className="cls-15"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C88-3"
              data-name="C88"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === `${89}small` ? (
          <g id="C89small-3" data-name="C89small">
            <path
              id="C89smallcone"
              className="cls-16"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C89small-4"
              data-name="C89small"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 89 ? (
          <g id="C89-3" data-name="C89">
            <path
              id="C89cone"
              className="cls-17"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="C89-4"
              data-name="C89"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === `${90}small` ? (
          <g id="C90small-3" data-name="C90small">
            <path
              id="C90smallcone"
              className="cls-18"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C90small-4"
              data-name="C90small"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 90 ? (
          <g id="C90-2" data-name="C90">
            <path
              id="C90cone"
              className="cls-19"
              d="M52.3872,126.2869a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C90-3"
              data-name="C90"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_7-2" data-name="7">
          <path
            className="cls-7"
            d="M170.4112,80.9291v-.7183h3.9414v.5811A8.2778,8.2778,0,0,0,173.2,82.4364a10.0224,10.0224,0,0,0-.8828,2.1094,7.81,7.81,0,0,0-.2861,1.6733h-.7686a7.4982,7.4982,0,0,1,.2823-1.7358,9.1163,9.1163,0,0,1,.7744-1.9619,8.4562,8.4562,0,0,1,1.0732-1.5923Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  31: [36.4846, 57.3091],
  32: [63.4377, 57.3091],
  33: [77.9525, 57.3091],
  51: [135.7828, 36.2417],
  52: [135.7828, 72.5363],
  53: [90.7952, 72.5363],
  88: [90.7952, 86.0066],
  '89small': [135.7828, 96.0066],
  89: [135.7828, 144.7428],
  '90small': [56.9873, 143.5353],
  90: [56.9873, 123.5169],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }
  .cls-17 {
    fill: url(#linear-gradient-9);
  }
  .cls-18 {
    fill: url(#linear-gradient-10);
  }
  .cls-19 {
    fill: url(#linear-gradient-11);
  }

  #A31,
  #A32,
  #A33,
  #B51,
  #B52,
  #B53,
  #C88,
  #C89,
  #C90 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
