import * as React from 'react'
import styled from 'styled-components'

export default function Floor1({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_1"
      data-name="1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(1, 1)}
          onMouseOver={() => setElementOnHover('A01')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A1"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_A01'
                ? 'active-apt'
                : elementOnHover === 'A01'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 9.837 42.915 23.784 67.917 23.784 67.917 9.837 59.107 9.837 59.107 7.088 50.516 7.088 50.516 9.837 42.915 9.837"
          />
        </g>
        <g
          onClick={() => changeView(1, 2)}
          onMouseOver={() => setElementOnHover('A02')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A2"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_A02'
                ? 'active-apt'
                : elementOnHover === 'A02'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 9.837 23.779 60.537 45.126 60.537 45.126 23.784 42.915 23.784 42.915 9.837 23.779 9.837"
          />
        </g>
        <g
          onClick={() => changeView(1, 3)}
          onMouseOver={() => setElementOnHover('A03')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A3"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_A03'
                ? 'active-apt'
                : elementOnHover === 'A03'
                ? 'on-hover'
                : ''
            }`}
            points="53.314 60.537 53.314 52.305 50.929 52.305 50.929 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 53.314 60.537"
          />
        </g>
        <g
          onClick={() => changeView(1, 4)}
          onMouseOver={() => setElementOnHover('A04')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A4"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_A04'
                ? 'active-apt'
                : elementOnHover === 'A04'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(1, 5)}
          onMouseOver={() => setElementOnHover('A05')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A5"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_A05'
                ? 'active-apt'
                : elementOnHover === 'A05'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 9.837 67.917 23.784 95.873 23.784 95.873 9.837 86.583 9.837 86.583 7.088 78.086 7.088 78.086 9.837 67.917 9.837"
          />
        </g>
        <g
          onClick={() => changeView(1, 56)}
          onMouseOver={() => setElementOnHover('C56')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C56"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_C56'
                ? 'active-apt'
                : elementOnHover === 'C56'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
        <g
          onClick={() => changeView(1, 57)}
          onMouseOver={() => setElementOnHover('C57')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C57"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_C57'
                ? 'active-apt'
                : elementOnHover === 'C57'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(1, 58)}
          onMouseOver={() => setElementOnHover('C58')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C58"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_01_krs_C58'
                ? 'active-apt'
                : elementOnHover === 'C58'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
      </g>
      <g id="levelfloor">
        <path
          className="cls-3"
          d="M137.4053,92.2294v10.9237h-5.153v14.7657h5.153V150.214H63.3133V109.9506H96.36V92.2294h41.0457m1.4174-1.4173h-43.88v17.7212H61.8959v43.098h76.9268v-35.13H133.67v-11.931h5.1531V90.8121Z"
        />
        <path
          className="cls-3"
          d="M137.4054,10.4876V47.8434h-5.1531V61.6655h5.1531V77.3181H96.36v-25.75H24.5865V10.4876H137.4054M138.8227,9.07H23.1691V52.9855H94.9423v25.75h43.88V60.2482H133.67V49.2608h5.1531V9.07Z"
        />
        <polyline
          className="cls-4"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-4"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-4"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-4"
          points="99.053 151.631 99.053 127.956 138.892 127.956"
        />
        <polyline
          className="cls-4"
          points="95.62 109.364 108.82 109.364 108.82 117.38 108.82 127.956"
        />
        <line
          className="cls-4"
          x1="132.7904"
          y1="117.4824"
          x2="108.8204"
          y2="117.4824"
        />
        <line
          className="cls-4"
          x1="132.8974"
          y1="103.6875"
          x2="95.6204"
          y2="103.6875"
        />
        <polyline
          className="cls-4"
          points="132.811 61.255 114.688 61.255 95.748 61.255"
        />
        <polyline
          className="cls-4"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 61.255"
        />
        <line
          className="cls-4"
          x1="138.0642"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-4"
          x1="95.8734"
          y1="9.837"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-4"
          points="95.873 23.715 67.917 23.715 67.917 9.837"
        />
        <polyline
          className="cls-4"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-4"
          points="45.296 23.715 42.915 23.715 42.915 9.837"
        />
        <polyline
          className="cls-4"
          points="50.929 52.305 50.929 34.495 73.529 34.495"
        />
        <polyline
          className="cls-4"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-4"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-4"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <rect
          className="cls-4"
          x="97.5108"
          y="114.6558"
          width="6.9049"
          height="7.4835"
        />
        <polyline
          className="cls-5"
          points="61.896 151.495 54.144 151.495 54.144 108.652 61.896 108.652"
        />
        <polyline
          className="cls-5"
          points="23.254 52.985 23.254 60.469 45.344 60.469 45.344 52.985"
        />
        <polyline
          className="cls-5"
          points="53.314 52.985 53.314 60.537 85.136 60.537 85.136 52.968"
        />
        <line
          className="cls-4"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.837 50.516 7.088 59.107 7.088 59.107 9.768"
        />
        <polyline
          className="cls-5"
          points="78.086 9.837 78.086 7.088 86.583 7.088 86.583 9.837"
        />
        <polyline
          className="cls-4"
          points="114.502 50.671 116.848 50.671 116.848 48.279 132.647 48.279"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_01_krs_A01'
              ? 'white-text'
              : elementOnHover === 'A01'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 1)}
          onMouseOver={() => setElementOnHover('A01')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M58.9239,18.5937h-.4986V15.4189a2.59,2.59,0,0,1-.4716.3427,3.3957,3.3957,0,0,1-.525.2578V15.538a3.0231,3.0231,0,0,0,.731-.4766,1.8734,1.8734,0,0,0,.4429-.5429h.3213Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_01_krs_A02'
              ? 'white-text'
              : elementOnHover === 'A02'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 2)}
          onMouseOver={() => setElementOnHover('A02')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M37.8052,34.9394v.4785H35.1226a.8827.8827,0,0,1,.0586-.3457,1.8187,1.8187,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5262,5.5262,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7631.7631,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8153.8153,0,0,0-.2188.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3512,1.3512,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242A1.0628,1.0628,0,0,1,37.8,32.47a1.2158,1.2158,0,0,1-.1.4786,1.8555,1.8555,0,0,1-.331.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.6253,1.6253,0,0,0-.21.27Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_01_krs_A03'
              ? 'white-text'
              : elementOnHover === 'A03'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 3)}
          onMouseOver={() => setElementOnHover('A03')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M58.5249,44.9242l1.5586-4.0586h.5786l1.6607,4.0586H61.711l-.4732-1.23H59.5411l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1975,5.1975,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M62.8365,43.8529l.498-.0664a1.1006,1.1006,0,0,0,.292.61.72.72,0,0,0,.5024.1865.8061.8061,0,0,0,.5938-.2432.82.82,0,0,0,.2422-.6035.7658.7658,0,0,0-.2241-.5664.7754.7754,0,0,0-.57-.2226,1.4213,1.4213,0,0,0-.3515.0556l.0557-.4375a.7157.7157,0,0,0,.08.0059,1.02,1.02,0,0,0,.5728-.167.5677.5677,0,0,0,.2549-.5117.6068.6068,0,0,0-.1856-.4541.658.658,0,0,0-.479-.18.6783.6783,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.498-.0879a1.2843,1.2843,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0362,1.0362,0,0,1,.4277.39.9958.9958,0,0,1,.148.5224.858.858,0,0,1-.1411.48.967.967,0,0,1-.418.3428.946.946,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.3607,1.3607,0,0,1-.98.37,1.277,1.277,0,0,1-.8872-.3184A1.2338,1.2338,0,0,1,62.8365,43.8529Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_01_krs_A04'
              ? 'white-text'
              : elementOnHover === 'A04'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 4)}
          onMouseOver={() => setElementOnHover('A04')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M87.6846,40.1132v-.9717H85.9239v-.457l1.852-2.63h.4072v2.63h.5479v.457h-.5479v.9717Zm0-1.4287V36.8554l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_01_krs_A05'
              ? 'white-text'
              : elementOnHover === 'A05'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 5)}
          onMouseOver={() => setElementOnHover('A05')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M78.9932,18.4667l1.5586-4.0586H81.13l1.6607,4.0586h-.6119l-.4731-1.2295H80.0093l-.4458,1.2295Zm1.1709-1.666H81.54l-.4239-1.124q-.1933-.5127-.2875-.8418a5.2129,5.2129,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M83.3018,17.4032l.5229-.0439a.9384.9384,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.7954.7954,0,0,0,.6089-.2705,1.0223,1.0223,0,0,0,.2495-.72.9247.9247,0,0,0-.24-.6729.8363.8363,0,0,0-.627-.2471.8755.8755,0,0,0-.4346.1094.851.851,0,0,0-.3047.2842l-.4677-.0606.393-2.0849h2.0181V14.94H84.1822l-.2188,1.0909a1.319,1.319,0,0,1,.7666-.2549,1.2123,1.2123,0,0,1,.897.3681,1.2852,1.2852,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2853,1.2853,0,0,1-1.0659.4931,1.3114,1.3114,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,83.3018,17.4032Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_01_krs_C56'
              ? 'white-text'
              : elementOnHover === 'C56'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 56)}
          onMouseOver={() => setElementOnHover('C56')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-6"
            d="M76.3326,117.7533l.5229-.0439a.9383.9383,0,0,0,.27.5742.73.73,0,0,0,.5108.1924.7957.7957,0,0,0,.6089-.27,1.0224,1.0224,0,0,0,.2495-.72.9242.9242,0,0,0-.24-.6728.8359.8359,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.85.85,0,0,0-.3047.2842l-.4678-.0606.3931-2.0849h2.0181v.4765H77.2129l-.2187,1.0909a1.3188,1.3188,0,0,1,.7666-.2549,1.2123,1.2123,0,0,1,.897.3681,1.2852,1.2852,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2854,1.2854,0,0,1-1.0659.4931,1.3112,1.3112,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,76.3326,117.7533Z"
          />
          <path
            className="cls-6"
            d="M82.3545,115.7523l-.4956.0391a.94.94,0,0,0-.188-.4267.6609.6609,0,0,0-.4985-.2129.6831.6831,0,0,0-.418.1328,1.1381,1.1381,0,0,0-.3711.501,2.6256,2.6256,0,0,0-.1411.9384,1.1576,1.1576,0,0,1,.4405-.4072,1.1906,1.1906,0,0,1,.5454-.1328,1.1275,1.1275,0,0,1,.8481.3672,1.316,1.316,0,0,1,.35.9482,1.5625,1.5625,0,0,1-.1645.71,1.1992,1.1992,0,0,1-.4527.5019,1.2364,1.2364,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3129,2.3129,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2481,1.2481,0,0,1,1.0215-.4658,1.087,1.087,0,0,1,1.1513,1.0107Zm-2.0346,1.75a1.1565,1.1565,0,0,0,.1093.4922.8115.8115,0,0,0,.3057.3584.766.766,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.738.738,0,0,0-.5644-.2422.77.77,0,0,0-.5733.2422A.8732.8732,0,0,0,80.32,117.5023Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_01_krs_C57'
              ? 'white-text'
              : elementOnHover === 'C57'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 57)}
          onMouseOver={() => setElementOnHover('C57')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-6"
            d="M76.3326,131.1576l.5229-.0439a.9383.9383,0,0,0,.27.5742.73.73,0,0,0,.5108.1924.7957.7957,0,0,0,.6089-.27,1.0224,1.0224,0,0,0,.2495-.72.9242.9242,0,0,0-.24-.6728.8359.8359,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.85.85,0,0,0-.3047.2842l-.4678-.0606.3931-2.0849h2.0181v.4765H77.2129l-.2187,1.0909a1.3188,1.3188,0,0,1,.7666-.2549,1.2123,1.2123,0,0,1,.897.3681,1.2852,1.2852,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2854,1.2854,0,0,1-1.0659.4931,1.3112,1.3112,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,76.3326,131.1576Z"
          />
          <path
            className="cls-6"
            d="M79.8023,128.6947v-.48h2.6269v.3877a5.5365,5.5365,0,0,0-.768,1.0967,6.6507,6.6507,0,0,0-.5884,1.4063,5.2241,5.2241,0,0,0-.1909,1.1152H80.37a4.9542,4.9542,0,0,1,.1884-1.1572,6.0606,6.0606,0,0,1,.5162-1.3076,5.5856,5.5856,0,0,1,.7153-1.0616Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_01_krs_C58'
              ? 'white-text'
              : elementOnHover === 'C58'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(1, 58)}
          onMouseOver={() => setElementOnHover('C58')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-6"
            d="M76.3326,144.8168l.5229-.0439a.9388.9388,0,0,0,.27.5742.73.73,0,0,0,.5108.1924.7957.7957,0,0,0,.6089-.27,1.0224,1.0224,0,0,0,.2495-.72.9239.9239,0,0,0-.24-.6728.8359.8359,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.8493.8493,0,0,0-.3047.2842l-.4678-.0606.3931-2.085h2.0181v.4766H77.2129l-.2187,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.2124,1.2124,0,0,1,.897.3682,1.2851,1.2851,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2854,1.2854,0,0,1-1.0659.4931,1.3112,1.3112,0,0,1-.9038-.31A1.2082,1.2082,0,0,1,76.3326,144.8168Z"
          />
          <path
            className="cls-6"
            d="M80.5357,143.6791a.9383.9383,0,0,1-.46-.3232.8493.8493,0,0,1-.1494-.5039.9864.9864,0,0,1,.3184-.7452,1.34,1.34,0,0,1,1.7026.0069,1.001,1.001,0,0,1,.3237.7519.826.826,0,0,1-.1479.4912.931.931,0,0,1-.45.3223,1.0947,1.0947,0,0,1,.5688.3936,1.0761,1.0761,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1836,1.1836,0,0,1-.3682-.8876,1.0712,1.0712,0,0,1,.2007-.6631A1.0107,1.0107,0,0,1,80.5357,143.6791Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8037.8037,0,0,0,.59-.23.7871.7871,0,0,0,.2325-.584.7981.7981,0,0,0-.2393-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135Zm.1607-1.874a.634.634,0,0,0,.1855.4707.6559.6559,0,0,0,.4815.1826.643.643,0,0,0,.4721-.1817.6.6,0,0,0,.1841-.4443.6223.6223,0,0,0-.1894-.4609.6973.6973,0,0,0-.9453-.0039A.5886.5886,0,0,0,80.4361,142.8354Z"
          />
        </g>
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_1-2" data-name="1">
          <path
            className="cls-7"
            d="M173.1768,86.2191H172.43V81.4565a3.8381,3.8381,0,0,1-.708.5146,4.9638,4.9638,0,0,1-.7871.3862v-.7221a4.5323,4.5323,0,0,0,1.0967-.7144,2.81,2.81,0,0,0,.6641-.814h.4814Z"
          />
        </g>
        <g id="down">
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 1 ? (
          <g id="A1-2" data-name="A1">
            <path
              id="A1cone"
              className="cls-8"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A1-3"
              data-name="A1"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 2 ? (
          <g id="A2-2" data-name="A2">
            <path
              id="A2cone"
              className="cls-10"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A2-3"
              data-name="A2"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 3 ? (
          <g id="A3-2" data-name="A3">
            <path
              id="A3cone"
              className="cls-11"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A3-3"
              data-name="A3"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 4 ? (
          <g id="A4-2" data-name="A4">
            <path
              id="A4_cone"
              data-name="A4 cone"
              className="cls-12"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A4-3"
              data-name="A4"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 5 ? (
          <g id="A5-2" data-name="A5">
            <path
              id="A5cone"
              className="cls-13"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A5-3"
              data-name="A5"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 56 ? (
          <g id="C56-2" data-name="C56">
            <path
              id="C56cone"
              className="cls-14"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C56-3"
              data-name="C56"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 57 ? (
          <g id="C57-2" data-name="C57">
            <path
              id="C57cone"
              className="cls-15"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C57-3"
              data-name="C57"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 58 ? (
          <g id="C58-2" data-name="C58">
            <path
              id="C58cone"
              className="cls-16"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C58-3"
              data-name="C58"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
    </SVG>
  )
}

const pivots = {
  1: [54.848, 7.0884],
  2: [36.4846, 57.3091],
  3: [63.4377, 57.3091],
  4: [77.9525, 57.3091],
  5: [82.3715, 7.0884],
  56: [56.9873, 117.0263],
  57: [56.9873, 130.4153],
  58: [56.9873, 143.5353],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3 {
    fill: #3d3d3b;
  }
  .cls-4,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-4 {
    stroke-width: 0.8504px;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }

  #A1,
  #A2,
  #A3,
  #A4,
  #A5,
  #C56,
  #C57,
  #C58 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .disable-lift-btn,
  #down:hover {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--yit-grey);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
