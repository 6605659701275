export const FLOOR_5_DATA = [
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 21,
    sceneName: 'scene_Otavallanhovi_05_krs_A21',
    sceneName_future: 'scene_Otavallanhovi_05_krs_A21_future',
    name: 'A21',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      4: 16,
      6: 26,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 22,
    sceneName: 'scene_Otavallanhovi_05_krs_A22',
    sceneName_future: 'scene_Otavallanhovi_05_krs_A22_future',
    name: 'A22',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      4: 17,
      6: 27,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 23,
    sceneName: 'scene_Otavallanhovi_05_krs_A23',
    sceneName_future: 'scene_Otavallanhovi_05_krs_A23_future',
    name: 'A23',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      4: 18,
      6: 28,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 24,
    sceneName: 'scene_Otavallanhovi_05_krs_A24',
    sceneName_future: 'scene_Otavallanhovi_05_krs_A24_future',
    name: 'A24',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      4: 19,
      6: 29,
    },
  },
  {
    floor: 5,
    building: 'A',
    apartmentNumber: 25,
    sceneName: 'scene_Otavallanhovi_05_krs_A25',
    sceneName_future: 'scene_Otavallanhovi_05_krs_A25_future',
    name: 'A25',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      4: 20,
      6: 30,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 45,
    sceneName: 'scene_Otavallanhovi_05_krs_B45',
    sceneName_future: 'scene_Otavallanhovi_05_krs_B45_future',
    name: 'B45',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      4: 42,
      6: 48,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 46,
    sceneName: 'scene_Otavallanhovi_05_krs_B46',
    sceneName_future: 'scene_Otavallanhovi_05_krs_B46_future',
    name: 'B46',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      4: 43,
      6: 49,
    },
  },
  {
    floor: 5,
    building: 'B',
    apartmentNumber: 47,
    sceneName: 'scene_Otavallanhovi_05_krs_B47',
    sceneName_future: 'scene_Otavallanhovi_05_krs_B47_future',
    name: 'B47',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      4: 44,
      6: 50,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 77,
    sceneName: 'scene_Otavallanhovi_05_krs_C77',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C77_future',
    name: 'C77',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      4: 71,
      6: 83,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 78,
    sceneName: 'scene_Otavallanhovi_05_krs_C78',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C78_future',
    name: 'C78',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      4: 72,
      6: `${84}small`,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 79,
    sceneName: 'scene_Otavallanhovi_05_krs_C79',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C79_future',
    name: 'C79',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      4: 73,
      6: 84,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 80,
    sceneName: 'scene_Otavallanhovi_05_krs_C80',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C80_future',
    name: 'C80',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      4: 74,
      6: 85,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 81,
    sceneName: 'scene_Otavallanhovi_05_krs_C81',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C81_future',
    name: 'C81',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      4: 75,
      6: 86,
    },
  },
  {
    floor: 5,
    building: 'C',
    apartmentNumber: 82,
    sceneName: 'scene_Otavallanhovi_05_krs_C82',
    sceneName_future: 'scene_Otavallanhovi_05_krs_C82_future',
    name: 'C82',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      4: 76,
      6: 87,
    },
  },
]
