export const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 11,
    sceneName: 'scene_Otavallanhovi_03_krs_A11',
    sceneName_future: 'scene_Otavallanhovi_03_krs_A11_future',
    name: 'A11',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      2: 6,
      4: 16,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 12,
    sceneName: 'scene_Otavallanhovi_03_krs_A12',
    sceneName_future: 'scene_Otavallanhovi_03_krs_A12_future',
    name: 'A12',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 7,
      4: 17,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 13,
    sceneName: 'scene_Otavallanhovi_03_krs_A13',
    sceneName_future: 'scene_Otavallanhovi_03_krs_A13_future',
    name: 'A13',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 8,
      4: 18,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 14,
    sceneName: 'scene_Otavallanhovi_03_krs_A14',
    sceneName_future: 'scene_Otavallanhovi_03_krs_A14_future',
    name: 'A14',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 9,
      4: 19,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 15,
    sceneName: 'scene_Otavallanhovi_03_krs_A15',
    sceneName_future: 'scene_Otavallanhovi_03_krs_A15_future',
    name: 'A15',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      2: 10,
      4: 20,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 39,
    sceneName: 'scene_Otavallanhovi_03_krs_B39',
    sceneName_future: 'scene_Otavallanhovi_03_krs_B39_future',
    name: 'B39',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      2: 36,
      4: 42,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 40,
    sceneName: 'scene_Otavallanhovi_03_krs_B40',
    sceneName_future: 'scene_Otavallanhovi_03_krs_B40_future',
    name: 'B40',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      2: 37,
      4: 43,
    },
  },
  {
    floor: 3,
    building: 'B',
    apartmentNumber: 41,
    sceneName: 'scene_Otavallanhovi_03_krs_B41',
    sceneName_future: 'scene_Otavallanhovi_03_krs_B41_future',
    name: 'B41',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 38,
      4: 44,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 65,
    sceneName: 'scene_Otavallanhovi_03_krs_C65',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C65_future',
    name: 'C65',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 59,
      4: 71,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 66,
    sceneName: 'scene_Otavallanhovi_03_krs_C66',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C66_future',
    name: 'C66',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      2: 60,
      4: 72,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 67,
    sceneName: 'scene_Otavallanhovi_03_krs_C67',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C67_future',
    name: 'C67',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      2: 61,
      4: 73,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 68,
    sceneName: 'scene_Otavallanhovi_03_krs_C68',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C68_future',
    name: 'C68',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 62,
      4: 74,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 69,
    sceneName: 'scene_Otavallanhovi_03_krs_C69',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C69_future',
    name: 'C69',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 63,
      4: 75,
    },
  },
  {
    floor: 3,
    building: 'C',
    apartmentNumber: 70,
    sceneName: 'scene_Otavallanhovi_03_krs_C70',
    sceneName_future: 'scene_Otavallanhovi_03_krs_C70_future',
    name: 'C70',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 64,
      4: 76,
    },
  },
]
