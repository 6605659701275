export const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 16,
    sceneName: 'scene_Otavallanhovi_04_krs_A16',
    sceneName_future: 'scene_Otavallanhovi_04_krs_A16_future',
    name: 'A16',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      3: 11,
      5: 21,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 17,
    sceneName: 'scene_Otavallanhovi_04_krs_A17',
    sceneName_future: 'scene_Otavallanhovi_04_krs_A17_future',
    name: 'A17',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      3: 12,
      5: 22,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 18,
    sceneName: 'scene_Otavallanhovi_04_krs_A18',
    sceneName_future: 'scene_Otavallanhovi_04_krs_A18_future',
    name: 'A18',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      3: 13,
      5: 23,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 19,
    sceneName: 'scene_Otavallanhovi_04_krs_A19',
    sceneName_future: 'scene_Otavallanhovi_04_krs_A19_future',
    name: 'A19',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      3: 14,
      5: 24,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 20,
    sceneName: 'scene_Otavallanhovi_04_krs_A20',
    sceneName_future: 'scene_Otavallanhovi_04_krs_A20_future',
    name: 'A20',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      3: 15,
      5: 25,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 42,
    sceneName: 'scene_Otavallanhovi_04_krs_B42',
    sceneName_future: 'scene_Otavallanhovi_04_krs_B42_future',
    name: 'B42',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      3: 39,
      5: 45,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 43,
    sceneName: 'scene_Otavallanhovi_04_krs_B43',
    sceneName_future: 'scene_Otavallanhovi_04_krs_B43_future',
    name: 'B43',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      3: 40,
      5: 46,
    },
  },
  {
    floor: 4,
    building: 'B',
    apartmentNumber: 44,
    sceneName: 'scene_Otavallanhovi_04_krs_B44',
    sceneName_future: 'scene_Otavallanhovi_04_krs_B44_future',
    name: 'B44',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      3: 41,
      5: 47,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 71,
    sceneName: 'scene_Otavallanhovi_04_krs_C71',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C71_future',
    name: 'C71',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      3: 65,
      5: 77,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 72,
    sceneName: 'scene_Otavallanhovi_04_krs_C72',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C72_future',
    name: 'C72',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      3: 66,
      5: 78,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 73,
    sceneName: 'scene_Otavallanhovi_04_krs_C73',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C73_future',
    name: 'C73',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      3: 67,
      5: 79,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 74,
    sceneName: 'scene_Otavallanhovi_04_krs_C74',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C74_future',
    name: 'C74',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      3: 68,
      5: 80,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 75,
    sceneName: 'scene_Otavallanhovi_04_krs_C75',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C75_future',
    name: 'C75',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      3: 69,
      5: 81,
    },
  },
  {
    floor: 4,
    building: 'C',
    apartmentNumber: 76,
    sceneName: 'scene_Otavallanhovi_04_krs_C76',
    sceneName_future: 'scene_Otavallanhovi_04_krs_C76_future',
    name: 'C76',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      3: 70,
      5: 82,
    },
  },
]
