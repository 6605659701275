import * as React from 'react'
import styled from 'styled-components'

export default function Floor3({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_3"
      data-name="3"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="219.9698"
          y1="273.003"
          x2="219.9698"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(3, 11)}
          onMouseOver={() => setElementOnHover('A11')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A11"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_A11'
                ? 'active-apt'
                : elementOnHover === 'A11'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 9.837 42.915 23.784 67.917 23.784 67.917 9.837 59.107 9.837 59.107 7.088 50.516 7.088 50.516 9.837 42.915 9.837"
          />
        </g>
        <g
          onClick={() => changeView(3, 12)}
          onMouseOver={() => setElementOnHover('A12')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A12"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_A12'
                ? 'active-apt'
                : elementOnHover === 'A12'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 9.837 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 23.784 42.915 23.784 42.915 9.837 23.779 9.837"
          />
        </g>
        <g
          onClick={() => changeView(3, 13)}
          onMouseOver={() => setElementOnHover('A13')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A13"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_A13'
                ? 'active-apt'
                : elementOnHover === 'A13'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(3, 14)}
          onMouseOver={() => setElementOnHover('A14')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A14"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_A14'
                ? 'active-apt'
                : elementOnHover === 'A14'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(3, 15)}
          onMouseOver={() => setElementOnHover('A15')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A15"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_A15'
                ? 'active-apt'
                : elementOnHover === 'A15'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 9.837 67.917 23.784 95.873 23.784 95.873 9.837 86.583 9.837 86.583 7.088 78.086 7.088 78.086 9.837 67.917 9.837"
          />
        </g>
        <g
          onClick={() => changeView(3, 39)}
          onMouseOver={() => setElementOnHover('B39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B39"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_B39'
                ? 'active-apt'
                : elementOnHover === 'B39'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 9.837 95.873 39.77 114.376 39.749 114.376 44.171 138.752 44.171 138.752 10.038 95.873 9.837"
          />
        </g>
        <g
          onClick={() => changeView(3, 40)}
          onMouseOver={() => setElementOnHover('B40')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B40"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_B40'
                ? 'active-apt'
                : elementOnHover === 'B40'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 77.319 138.665 77.319 138.665 44.618 114.29 44.618 114.029 77.319"
          />
        </g>
        <g
          onClick={() => changeView(3, 41)}
          onMouseOver={() => setElementOnHover('B41')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B41"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_B41'
                ? 'active-apt'
                : elementOnHover === 'B41'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 67.625 87.471 67.625 87.471 77.527 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(3, 65)}
          onMouseOver={() => setElementOnHover('C65')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C65"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C65'
                ? 'active-apt'
                : elementOnHover === 'C65'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 115.219 109.277 115.219 91.798 137.989 92.111 137.989 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(3, 66)}
          onMouseOver={() => setElementOnHover('C66')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C66"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C66'
                ? 'active-apt'
                : elementOnHover === 'C66'
                ? 'on-hover'
                : ''
            }`}
            points="138.567 91.798 138.567 117.543 114.975 117.543 115.219 91.798 138.567 91.798"
          />
        </g>
        <g
          onClick={() => changeView(3, 67)}
          onMouseOver={() => setElementOnHover('C67')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C67"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C67'
                ? 'active-apt'
                : elementOnHover === 'C67'
                ? 'on-hover'
                : ''
            }`}
            points="99.053 151.213 99.053 127.956 114.843 127.956 114.843 117.543 138.567 117.543 138.567 151.296 99.053 151.213"
          />
        </g>
        <g
          onClick={() => changeView(3, 68)}
          onMouseOver={() => setElementOnHover('C68')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C68"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C68'
                ? 'active-apt'
                : elementOnHover === 'C68'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
        <g
          onClick={() => changeView(3, 69)}
          onMouseOver={() => setElementOnHover('C69')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C69"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C69'
                ? 'active-apt'
                : elementOnHover === 'C69'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(3, 70)}
          onMouseOver={() => setElementOnHover('C70')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C70"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_03_krs_C70'
                ? 'active-apt'
                : elementOnHover === 'C70'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polyline
          className="cls-3"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-3"
          points="99.053 151.631 99.053 127.956 115.315 127.956"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-3"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-3"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-3"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-3"
          x1="95.8734"
          y1="9.837"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-3"
          points="95.873 23.715 67.917 23.715 67.917 9.837"
        />
        <polyline
          className="cls-3"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-3"
          points="45.296 23.715 42.915 23.715 42.915 9.837"
        />
        <line
          className="cls-3"
          x1="45.3101"
          y1="34.4952"
          x2="73.5293"
          y2="34.4952"
        />
        <polyline
          className="cls-3"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-3"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-3"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-3"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.837 50.516 7.088 59.107 7.088 59.107 9.768"
        />
        <polyline
          className="cls-5"
          points="78.086 9.837 78.086 7.088 86.583 7.088 86.583 9.837"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 67.625 95.028 67.625"
        />
        <line
          className="cls-3"
          x1="114.9748"
          y1="117.3803"
          x2="138.3093"
          y2="117.3803"
        />
        <path
          className="cls-4"
          d="M137.4064,10.4876l.0119,17.0265h-7.5529l.0023,1.42.0238,14.2487.0023,1.4111,1.411.0039,6.1006.017V49.26l-.0124,17.5936h-9.0382l.0024,1.42.0139,8.4067.0023,1.415h9.0329V91.4857h-9.0352v10.282l1.42-.0028,7.6143-.0148v35.157l-7.62.0189-1.4138.0035V150.214H63.3133V109.9506H96.36V51.5682H24.5864V10.4876h112.82M138.8227,9.07H23.1691V52.9855H94.9423v55.5478H61.896v43.098h67.8924v-13.288l9.0343-.0224V100.33l-9.0343.0176V92.903h9.0352V76.6775h-9.0352l-.0139-8.4068h9.0349l.0133-19.01v-6.06l-7.5139-.0209-.0237-14.2488h7.5515L138.8227,9.07Z"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.321"
        />
        <line
          className="cls-5"
          x1="138.659"
          y1="100.3794"
          x2="138.659"
          y2="92.1328"
        />
        <line
          className="cls-5"
          x1="138.6654"
          y1="77.3189"
          x2="138.6654"
          y2="68.2707"
        />
        <line
          className="cls-5"
          x1="138.6767"
          y1="43.2003"
          x2="138.6767"
          y2="28.9315"
        />
        <line
          className="cls-3"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
        <polyline
          className="cls-3"
          points="128.82 77.59 95.583 77.59 87.471 77.59"
        />
        <polyline
          className="cls-3"
          points="128.396 91.798 114.975 91.798 114.975 109.364"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_A11'
              ? 'white-text'
              : elementOnHover === 'A11'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 11)}
          onMouseOver={() => setElementOnHover('A11')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M58.9239,18.5937h-.4986V15.4189a2.59,2.59,0,0,1-.4716.3427,3.3957,3.3957,0,0,1-.525.2578V15.538a3.0231,3.0231,0,0,0,.731-.4766,1.8734,1.8734,0,0,0,.4429-.5429h.3213Z"
          />
          <path
            className="cls-6"
            d="M61.94,18.5937H61.441V15.4189a2.5945,2.5945,0,0,1-.4717.3427,3.3951,3.3951,0,0,1-.5249.2578V15.538a3.0244,3.0244,0,0,0,.7309-.4766,1.8734,1.8734,0,0,0,.4429-.5429H61.94Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_A12'
              ? 'white-text'
              : elementOnHover === 'A12'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 12)}
          onMouseOver={() => setElementOnHover('A12')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M37.0635,35.4179H36.565V32.2431a2.591,2.591,0,0,1-.4717.3427,3.4013,3.4013,0,0,1-.5249.2579v-.4815a3.0278,3.0278,0,0,0,.731-.4765,1.8731,1.8731,0,0,0,.4428-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M41.2417,34.9394v.4785H38.5591a.8827.8827,0,0,1,.0586-.3457,1.8191,1.8191,0,0,1,.3277-.54,5.084,5.084,0,0,1,.6518-.6142,5.5219,5.5219,0,0,0,.8945-.86,1.0271,1.0271,0,0,0,.2325-.5986.6671.6671,0,0,0-.212-.5.7629.7629,0,0,0-.5522-.2031.7763.7763,0,0,0-.5757.2158.8153.8153,0,0,0-.2187.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8027,1.2158,1.2158,0,0,1-.1.4786,1.8561,1.8561,0,0,1-.3311.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5756.5108a1.6208,1.6208,0,0,0-.2105.27Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_A13'
              ? 'white-text'
              : elementOnHover === 'A13'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 13)}
          onMouseOver={() => setElementOnHover('A13')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M62.7837,44.9242h-.4985V41.7494a2.5875,2.5875,0,0,1-.4717.3428,3.3907,3.3907,0,0,1-.5249.2578v-.4814a3.031,3.031,0,0,0,.731-.4766,1.8718,1.8718,0,0,0,.4428-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M64.3462,43.8529l.4981-.0664a1.1006,1.1006,0,0,0,.292.61.72.72,0,0,0,.5024.1865.8062.8062,0,0,0,.5938-.2432.82.82,0,0,0,.2421-.6035.7658.7658,0,0,0-.2241-.5664.775.775,0,0,0-.57-.2226,1.4213,1.4213,0,0,0-.3515.0556l.0556-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5674.5674,0,0,0,.2549-.5117.6071.6071,0,0,0-.1855-.4541.6581.6581,0,0,0-.479-.18.6782.6782,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.4981-.0879a1.2849,1.2849,0,0,1,.4151-.7774,1.2,1.2,0,0,1,.8056-.2754,1.3333,1.3333,0,0,1,.6119.1426,1.0362,1.0362,0,0,1,.4277.39.9966.9966,0,0,1,.1479.5224.8572.8572,0,0,1-.1411.48.9667.9667,0,0,1-.4179.3428.9458.9458,0,0,1,.559.3447,1.05,1.05,0,0,1,.1993.6553,1.1977,1.1977,0,0,1-.3877.9,1.3607,1.3607,0,0,1-.98.37,1.2767,1.2767,0,0,1-.8872-.3184A1.2339,1.2339,0,0,1,64.3462,43.8529Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_A14'
              ? 'white-text'
              : elementOnHover === 'A14'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 14)}
          onMouseOver={() => setElementOnHover('A14')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M87.9644,40.1132h-.4985V36.9384a2.5993,2.5993,0,0,1-.4717.3428,3.4,3.4,0,0,1-.5249.2578v-.4815A3.0271,3.0271,0,0,0,87.2,36.581a1.8737,1.8737,0,0,0,.4429-.543h.3213Z"
          />
          <path
            className="cls-6"
            d="M91.1211,40.1132v-.9717H89.36v-.457l1.852-2.63H91.62v2.63h.5478v.457H91.62v.9717Zm0-1.4287V36.8554l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_A15'
              ? 'white-text'
              : elementOnHover === 'A15'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 15)}
          onMouseOver={() => setElementOnHover('A15')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M77.2769,18.4667l1.5586-4.0586h.5786l1.6606,4.0586h-.6118L79.99,17.2372H78.293l-.4458,1.2295Zm1.1709-1.666h1.376L79.4,15.6767q-.1934-.5127-.2876-.8418a5.2,5.2,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M83.4624,18.4667h-.4985V15.2919a2.5875,2.5875,0,0,1-.4717.3428,3.4,3.4,0,0,1-.5249.2578V15.411a3.0278,3.0278,0,0,0,.731-.4765,1.874,1.874,0,0,0,.4429-.543h.3212Z"
          />
          <path
            className="cls-6"
            d="M85.022,17.4032l.523-.0439a.9377.9377,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.7954.7954,0,0,0,.6089-.2705,1.0223,1.0223,0,0,0,.2495-.72.9247.9247,0,0,0-.24-.6729.8363.8363,0,0,0-.627-.2471.8749.8749,0,0,0-.4345.1094.85.85,0,0,0-.3047.2842l-.4678-.0606.3931-2.0849h2.018V14.94H85.9024l-.2188,1.0909a1.3192,1.3192,0,0,1,.7666-.2549,1.2121,1.2121,0,0,1,.897.3681,1.2848,1.2848,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2853,1.2853,0,0,1-1.0659.4931,1.3114,1.3114,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,85.022,17.4032Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_B39'
              ? 'white-text'
              : elementOnHover === 'B39'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 39)}
          onMouseOver={() => setElementOnHover('B39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M115.1866,26.6479l.498-.0664a1.0941,1.0941,0,0,0,.292.61.7187.7187,0,0,0,.502.1865.8051.8051,0,0,0,.5937-.2431.8188.8188,0,0,0,.2422-.6036.7654.7654,0,0,0-.2236-.5664.7762.7762,0,0,0-.57-.2226,1.4145,1.4145,0,0,0-.3515.0556l.0556-.4375a.6954.6954,0,0,0,.08.0059,1.0189,1.0189,0,0,0,.5723-.167.5674.5674,0,0,0,.2549-.5117.6049.6049,0,0,0-.1856-.4541.6568.6568,0,0,0-.4785-.18.6779.6779,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.498-.0879a1.2843,1.2843,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2753,1.3319,1.3319,0,0,1,.6113.1425,1.03,1.03,0,0,1,.4277.39.9966.9966,0,0,1,.1485.5224.8545.8545,0,0,1-.1416.48.9641.9641,0,0,1-.418.3428.9463.9463,0,0,1,.56.3447,1.0507,1.0507,0,0,1,.1992.6553,1.2,1.2,0,0,1-.3877.9,1.3615,1.3615,0,0,1-.9805.37,1.2766,1.2766,0,0,1-.8867-.3183A1.2323,1.2323,0,0,1,115.1866,26.6479Z"
          />
          <path
            className="cls-6"
            d="M118.6944,26.7807l.48-.044a.826.826,0,0,0,.2324.4893.6368.6368,0,0,0,.4394.1523.7678.7678,0,0,0,.4034-.1045.8571.8571,0,0,0,.2832-.2812,1.64,1.64,0,0,0,.1855-.4746,2.5039,2.5039,0,0,0,.0752-.6094q0-.0338-.0029-.1a1.1431,1.1431,0,0,1-.4082.3867,1.1114,1.1114,0,0,1-.5606.1475,1.1344,1.1344,0,0,1-.8525-.3653,1.3359,1.3359,0,0,1-.3486-.9629,1.3706,1.3706,0,0,1,.3632-.9941,1.2175,1.2175,0,0,1,.9121-.3769,1.2988,1.2988,0,0,1,.7247.2138,1.3281,1.3281,0,0,1,.498.6074,2.9957,2.9957,0,0,1,.17,1.1416,3.6818,3.6818,0,0,1-.1689,1.2393,1.4464,1.4464,0,0,1-.502.7012,1.2985,1.2985,0,0,1-.7822.2412,1.0736,1.0736,0,0,1-1.1406-1.0078Zm2.04-1.791a.9788.9788,0,0,0-.2275-.6807.7171.7171,0,0,0-.55-.252.7581.7581,0,0,0-.5791.2715,1.0055,1.0055,0,0,0-.2461.7031.8663.8663,0,0,0,.2343.629.7663.7663,0,0,0,.5772.2421.7357.7357,0,0,0,.5684-.2421A.9532.9532,0,0,0,120.7344,24.99Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_B40'
              ? 'white-text'
              : elementOnHover === 'B40'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 40)}
          onMouseOver={() => setElementOnHover('B40')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M126.5723,60.3119V59.34h-1.7607v-.457l1.8515-2.63h.4073v2.63h.5478v.457H127.07v.9717Zm0-1.4287V57.0541l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M128.4112,58.3109a3.72,3.72,0,0,1,.1484-1.1591,1.4408,1.4408,0,0,1,.44-.6768,1.1268,1.1268,0,0,1,.7344-.2383,1.197,1.197,0,0,1,.5733.1319,1.1023,1.1023,0,0,1,.4072.3789,2.0874,2.0874,0,0,1,.2519.6035,4.0362,4.0362,0,0,1,.0909.96,3.7273,3.7273,0,0,1-.1465,1.1524,1.4452,1.4452,0,0,1-.4385.6787,1.1306,1.1306,0,0,1-.7383.2393,1.1148,1.1148,0,0,1-.9219-.4209A2.6749,2.6749,0,0,1,128.4112,58.3109Zm.5127,0a2.5427,2.5427,0,0,0,.2334,1.33.6686.6686,0,0,0,1.1543-.0019,3.8926,3.8926,0,0,0,0-2.66.6935.6935,0,0,0-.583-.3291.6391.6391,0,0,0-.5479.29A2.5761,2.5761,0,0,0,128.9239,58.3109Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_03_krs_B41'
              ? 'white-text'
              : elementOnHover === 'B41'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 41)}
          onMouseOver={() => setElementOnHover('B41')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M105.7344,68.2943v-.9716h-1.7607v-.4571l1.852-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V65.0365l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M109.4507,68.2943h-.4985V65.12a2.5863,2.5863,0,0,1-.4717.3428,3.3907,3.3907,0,0,1-.5249.2578v-.4814a3.031,3.031,0,0,0,.731-.4766,1.8718,1.8718,0,0,0,.4428-.543h.3213Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C65'
              ? 'white-text'
              : elementOnHover === 'C65'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 65)}
          onMouseOver={() => setElementOnHover('C65')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-6"
            d="M107.3985,99.1166l-.4956.0391a.9431.9431,0,0,0-.188-.4268.6613.6613,0,0,0-.4986-.2129.6835.6835,0,0,0-.4179.1328,1.1377,1.1377,0,0,0-.3711.501,2.6258,2.6258,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.191,1.191,0,0,1,.5454-.1329,1.1277,1.1277,0,0,1,.8482.3672,1.3164,1.3164,0,0,1,.35.9483,1.5606,1.5606,0,0,1-.1646.71,1.1987,1.1987,0,0,1-.4526.502,1.2367,1.2367,0,0,1-.6534.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3133,2.3133,0,0,1-.393-1.5108,2.7017,2.7017,0,0,1,.4345-1.71,1.248,1.248,0,0,1,1.0215-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1555,1.1555,0,0,0,.1094.4922.8118.8118,0,0,0,.3056.3584.767.767,0,0,0,.4126.123.7043.7043,0,0,0,.5425-.2539,1.0024,1.0024,0,0,0,.2271-.6923.9384.9384,0,0,0-.2241-.6631.7379.7379,0,0,0-.5645-.2422.7691.7691,0,0,0-.5732.2422A.8727.8727,0,0,0,105.3638,100.8666Z"
          />
          <path
            className="cls-6"
            d="M108.25,101.1176l.5229-.044a.9388.9388,0,0,0,.27.5743.73.73,0,0,0,.5108.1923.795.795,0,0,0,.6088-.27,1.0217,1.0217,0,0,0,.25-.72.9239.9239,0,0,0-.24-.6728.8361.8361,0,0,0-.6269-.2471.8752.8752,0,0,0-.4346.1094.85.85,0,0,0-.3047.2841l-.4678-.0605.3931-2.085h2.0181v.4766H109.13l-.2187,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.212,1.212,0,0,1,.8969.3682,1.2846,1.2846,0,0,1,.3653.9463,1.4786,1.4786,0,0,1-.3208.9521,1.2855,1.2855,0,0,1-1.0659.4932,1.3108,1.3108,0,0,1-.9038-.3106A1.2078,1.2078,0,0,1,108.25,101.1176Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C66'
              ? 'white-text'
              : elementOnHover === 'C66'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 66)}
          onMouseOver={() => setElementOnHover('C66')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M123.4483,107.7777l.5371.1358a1.8079,1.8079,0,0,1-.6084,1.0088,1.6729,1.6729,0,0,1-1.0723.3476,1.9233,1.9233,0,0,1-1.0674-.2676,1.6646,1.6646,0,0,1-.625-.7734,2.7525,2.7525,0,0,1-.2148-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.66,1.66,0,0,1,.6894-.7178,2.0145,2.0145,0,0,1,.9834-.2442,1.6608,1.6608,0,0,1,1.0245.31,1.6111,1.6111,0,0,1,.5791.8721l-.5293.124a1.2536,1.2536,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3151,1.3151,0,0,0-.7822.2246,1.1845,1.1845,0,0,0-.4414.6016,2.4209,2.4209,0,0,0-.127.7793,2.478,2.478,0,0,0,.15.9043,1.1262,1.1262,0,0,0,.47.5771,1.3164,1.3164,0,0,0,.69.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,123.4483,107.7777Z"
          />
          <path
            className="cls-6"
            d="M127.3135,106.1361l-.4961.0391a.9358.9358,0,0,0-.1875-.4268.6613.6613,0,0,0-.499-.2128.684.684,0,0,0-.418.1328,1.1421,1.1421,0,0,0-.3711.5009,2.64,2.64,0,0,0-.1406.9385,1.1581,1.1581,0,0,1,.44-.4072,1.1878,1.1878,0,0,1,.545-.1328,1.1282,1.1282,0,0,1,.8486.3672,1.3156,1.3156,0,0,1,.35.9482,1.5673,1.5673,0,0,1-.1641.71,1.1994,1.1994,0,0,1-.4531.5019,1.2341,1.2341,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3161,2.3161,0,0,1-.3926-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2467,1.2467,0,0,1,1.0214-.4658,1.0878,1.0878,0,0,1,1.1514,1.0107Zm-2.0351,1.75a1.164,1.164,0,0,0,.1093.4922.8146.8146,0,0,0,.3057.3584.7676.7676,0,0,0,.4131.1231.7047.7047,0,0,0,.542-.2539,1.0034,1.0034,0,0,0,.2275-.6924.9386.9386,0,0,0-.2246-.6631.738.738,0,0,0-.5644-.2422.7705.7705,0,0,0-.5733.2422A.8751.8751,0,0,0,125.2784,107.8861Z"
          />
          <path
            className="cls-6"
            d="M130.75,106.1361l-.4961.0391a.9358.9358,0,0,0-.1875-.4268.6611.6611,0,0,0-.499-.2128.6837.6837,0,0,0-.4179.1328,1.1406,1.1406,0,0,0-.3711.5009,2.6369,2.6369,0,0,0-.1407.9385,1.1583,1.1583,0,0,1,.4405-.4072,1.1874,1.1874,0,0,1,.5449-.1328,1.1282,1.1282,0,0,1,.8486.3672,1.3156,1.3156,0,0,1,.35.9482,1.5686,1.5686,0,0,1-.164.71,1.2,1.2,0,0,1-.4532.5019,1.2339,1.2339,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3161,2.3161,0,0,1-.3926-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2469,1.2469,0,0,1,1.0215-.4658,1.0876,1.0876,0,0,1,1.1513,1.0107Zm-2.0351,1.75a1.1626,1.1626,0,0,0,.1094.4922.8144.8144,0,0,0,.3056.3584.7679.7679,0,0,0,.4131.1231.7046.7046,0,0,0,.542-.2539,1.0029,1.0029,0,0,0,.2275-.6924.9386.9386,0,0,0-.2246-.6631.738.738,0,0,0-.5644-.2422.7707.7707,0,0,0-.5733.2422A.8751.8751,0,0,0,128.7149,107.8861Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C67'
              ? 'white-text'
              : elementOnHover === 'C67'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 67)}
          onMouseOver={() => setElementOnHover('C67')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
          />
          <path
            className="cls-6"
            d="M119.8116,134.269l-.4961.039a.9369.9369,0,0,0-.1875-.4267.6616.6616,0,0,0-.4991-.2129.6837.6837,0,0,0-.4179.1328,1.14,1.14,0,0,0-.3711.501,2.6362,2.6362,0,0,0-.1406.9384,1.1581,1.1581,0,0,1,.44-.4072,1.1874,1.1874,0,0,1,.5449-.1328,1.1286,1.1286,0,0,1,.8486.3672,1.3157,1.3157,0,0,1,.35.9482,1.5673,1.5673,0,0,1-.1641.71,1.2007,1.2007,0,0,1-.4531.5019,1.2342,1.2342,0,0,1-.6534.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3161,2.3161,0,0,1-.3926-1.5107,2.7018,2.7018,0,0,1,.4346-1.71,1.247,1.247,0,0,1,1.0215-.4658,1.0878,1.0878,0,0,1,1.1514,1.0108Zm-2.0352,1.75a1.1625,1.1625,0,0,0,.1094.4921.8144.8144,0,0,0,.3056.3584.7679.7679,0,0,0,.4131.1231.7044.7044,0,0,0,.542-.2539,1.0027,1.0027,0,0,0,.2276-.6924.9386.9386,0,0,0-.2246-.6631.7382.7382,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.875.875,0,0,0,117.7764,136.019Z"
          />
          <path
            className="cls-6"
            d="M120.6954,133.807v-.48h2.6269v.3877a5.5405,5.5405,0,0,0-.7676,1.0967,6.6471,6.6471,0,0,0-.5888,1.4063,5.1935,5.1935,0,0,0-.1905,1.1152h-.5127a4.9869,4.9869,0,0,1,.1885-1.1572,6.062,6.062,0,0,1,.5166-1.3076,5.585,5.585,0,0,1,.7149-1.0616Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C68'
              ? 'white-text'
              : elementOnHover === 'C68'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 68)}
          onMouseOver={() => setElementOnHover('C68')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-6"
            d="M78.918,142.8158l-.4956.0391a.941.941,0,0,0-.188-.4268.6612.6612,0,0,0-.4985-.2129.683.683,0,0,0-.418.1329,1.1376,1.1376,0,0,0-.3711.5009,2.6266,2.6266,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.1912,1.1912,0,0,1,.5454-.1328,1.1272,1.1272,0,0,1,.8482.3672,1.316,1.316,0,0,1,.35.9482,1.5613,1.5613,0,0,1-.1646.71,1.1983,1.1983,0,0,1-.4526.5019,1.2366,1.2366,0,0,1-.6533.1748,1.2747,1.2747,0,0,1-1.0156-.458,2.3125,2.3125,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0214-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1566,1.1566,0,0,0,.1094.4922.812.812,0,0,0,.3057.3584.7666.7666,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.0032,1.0032,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8731.8731,0,0,0,76.8833,144.5658Z"
          />
          <path
            className="cls-6"
            d="M80.5357,143.6791a.9383.9383,0,0,1-.46-.3232.8493.8493,0,0,1-.1494-.5039.9864.9864,0,0,1,.3184-.7452,1.34,1.34,0,0,1,1.7026.0069,1.001,1.001,0,0,1,.3237.7519.826.826,0,0,1-.1479.4912.931.931,0,0,1-.45.3223,1.0947,1.0947,0,0,1,.5688.3936,1.0761,1.0761,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1836,1.1836,0,0,1-.3682-.8876,1.0712,1.0712,0,0,1,.2007-.6631A1.0107,1.0107,0,0,1,80.5357,143.6791Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8037.8037,0,0,0,.59-.23.7871.7871,0,0,0,.2325-.584.7981.7981,0,0,0-.2393-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135Zm.1607-1.874a.634.634,0,0,0,.1855.4707.6559.6559,0,0,0,.4815.1826.643.643,0,0,0,.4721-.1817.6.6,0,0,0,.1841-.4443.6223.6223,0,0,0-.1894-.4609.6973.6973,0,0,0-.9453-.0039A.5886.5886,0,0,0,80.4361,142.8354Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C69'
              ? 'white-text'
              : elementOnHover === 'C69'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 69)}
          onMouseOver={() => setElementOnHover('C69')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-6"
            d="M78.918,129.1566l-.4956.0391a.94.94,0,0,0-.188-.4267.6609.6609,0,0,0-.4985-.2129.6829.6829,0,0,0-.418.1328,1.1376,1.1376,0,0,0-.3711.5009,2.6266,2.6266,0,0,0-.1411.9385,1.1581,1.1581,0,0,1,.44-.4072,1.1912,1.1912,0,0,1,.5454-.1328,1.1276,1.1276,0,0,1,.8482.3672,1.316,1.316,0,0,1,.35.9482,1.5613,1.5613,0,0,1-.1646.71,1.1983,1.1983,0,0,1-.4526.5019,1.2366,1.2366,0,0,1-.6533.1748,1.2747,1.2747,0,0,1-1.0156-.458,2.3124,2.3124,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0214-.4658,1.087,1.087,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1566,1.1566,0,0,0,.1094.4922.8115.8115,0,0,0,.3057.3584.7657.7657,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8733.8733,0,0,0,76.8833,130.9066Z"
          />
          <path
            className="cls-6"
            d="M79.8438,131.2826l.479-.0439a.8292.8292,0,0,0,.2324.4892.6379.6379,0,0,0,.44.1524.7666.7666,0,0,0,.4028-.1045.8636.8636,0,0,0,.2837-.2813,1.6544,1.6544,0,0,0,.1855-.4746,2.5027,2.5027,0,0,0,.0747-.6093c0-.0225-.0009-.0557-.0024-.1a1.1441,1.1441,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5605.1474,1.1344,1.1344,0,0,1-.8526-.3652,1.3363,1.3363,0,0,1-.3486-.9629,1.3687,1.3687,0,0,1,.3638-.9941,1.2165,1.2165,0,0,1,.9121-.377,1.2983,1.2983,0,0,1,.7241.2139,1.3281,1.3281,0,0,1,.498.6074,2.9841,2.9841,0,0,1,.17,1.1416,3.6954,3.6954,0,0,1-.1689,1.2393,1.4469,1.4469,0,0,1-.5024.7011,1.2958,1.2958,0,0,1-.7818.2412,1.0727,1.0727,0,0,1-1.1406-1.0078Zm2.04-1.791a.9763.9763,0,0,0-.228-.6807.7162.7162,0,0,0-.55-.2519.7568.7568,0,0,0-.5786.2715,1.0051,1.0051,0,0,0-.2461.7031.8653.8653,0,0,0,.2339.6289.7675.7675,0,0,0,.5771.2422.7369.7369,0,0,0,.5689-.2422A.953.953,0,0,0,81.8838,129.4916Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_03_krs_C70'
              ? 'white-text'
              : elementOnHover === 'C70'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(3, 70)}
          onMouseOver={() => setElementOnHover('C70')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-6"
            d="M76.3658,115.29v-.48h2.6269v.3877a5.5407,5.5407,0,0,0-.7681,1.0967,6.6451,6.6451,0,0,0-.5883,1.4063,5.2241,5.2241,0,0,0-.1909,1.1152h-.5123a4.96,4.96,0,0,1,.1885-1.1572,6.06,6.06,0,0,1,.5161-1.3076,5.59,5.59,0,0,1,.7154-1.0616Z"
          />
          <path
            className="cls-6"
            d="M79.7691,116.8158a3.7227,3.7227,0,0,1,.1479-1.1592,1.4449,1.4449,0,0,1,.4405-.6767,1.1267,1.1267,0,0,1,.7348-.2383,1.1961,1.1961,0,0,1,.5728.1318,1.0993,1.0993,0,0,1,.4072.3789,2.0635,2.0635,0,0,1,.252.6036,4.0383,4.0383,0,0,1,.0913.96,3.71,3.71,0,0,1-.147,1.1524,1.4449,1.4449,0,0,1-.4385.6787,1.1283,1.1283,0,0,1-.7378.2392,1.1139,1.1139,0,0,1-.9219-.4209A2.6748,2.6748,0,0,1,79.7691,116.8158Zm.5122,0a2.5418,2.5418,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.0019,3.8988,3.8988,0,0,0,0-2.66.6942.6942,0,0,0-.583-.3291.6391.6391,0,0,0-.5479.29A2.5749,2.5749,0,0,0,80.2813,116.8158Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 11 ? (
          <g id="A11-2" data-name="A11">
            <path
              id="A11cone"
              className="cls-8"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A11-3"
              data-name="A11"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 12 ? (
          <g id="A12-2" data-name="A12">
            <path
              id="A12cone"
              className="cls-10"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A12-3"
              data-name="A12"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 13 ? (
          <g id="A13-2" data-name="A13">
            <path
              id="A13cone"
              className="cls-11"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A13-3"
              data-name="A13"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 14 ? (
          <g id="A14-2" data-name="A14">
            <path
              id="A14cone"
              className="cls-12"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A14-3"
              data-name="A14"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 15 ? (
          <g id="A15-2" data-name="A15">
            <path
              id="A15cone"
              className="cls-13"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A15-3"
              data-name="A15"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 39 ? (
          <g id="B39-2" data-name="B39">
            <path
              id="B39cone"
              className="cls-14"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B39-3"
              data-name="B39"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 40 ? (
          <g id="B40-2" data-name="B40">
            <path
              id="B40cone"
              className="cls-15"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B40-3"
              data-name="B40"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 41 ? (
          <g id="B41-2" data-name="B41">
            <path
              id="B41cone"
              className="cls-16"
              d="M86.1952,75.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B41-3"
              data-name="B41"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 65 ? (
          <g id="C65-2" data-name="C65">
            <path
              id="C65cone"
              className="cls-17"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C65-3"
              data-name="C65"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 66 ? (
          <g id="C66-2" data-name="C66">
            <path
              id="C66cone"
              className="cls-18"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C66-3"
              data-name="C66"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 67 ? (
          <g id="C67-2" data-name="C67">
            <path
              id="C67cone"
              className="cls-19"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C67-3"
              data-name="C67"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 68 ? (
          <g id="C68-2" data-name="C68">
            <path
              id="C68cone"
              className="cls-20"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C68-3"
              data-name="C68"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 69 ? (
          <g id="C69-2" data-name="C69">
            <path
              id="C69cone"
              className="cls-21"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C69-3"
              data-name="C69"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 70 ? (
          <g id="C70-2" data-name="C70">
            <path
              id="C70cone"
              className="cls-22"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C70-3"
              data-name="C70"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_3-2" data-name="3">
          <path
            className="cls-7"
            d="M170.3662,84.6122l.7471-.1a1.644,1.644,0,0,0,.4385.9155,1.08,1.08,0,0,0,.7529.28,1.2074,1.2074,0,0,0,.8907-.3652,1.2337,1.2337,0,0,0,.3632-.9053,1.1443,1.1443,0,0,0-.3359-.8491,1.1628,1.1628,0,0,0-.8555-.3345,2.1607,2.1607,0,0,0-.5273.083l.083-.6558a1.1775,1.1775,0,0,0,.12.0083,1.5394,1.5394,0,0,0,.86-.25.8536.8536,0,0,0,.3818-.768.9079.9079,0,0,0-.2783-.6807.9878.9878,0,0,0-.7188-.27,1.02,1.02,0,0,0-.7265.2739,1.3731,1.3731,0,0,0-.3731.8223l-.748-.1328a1.9243,1.9243,0,0,1,.623-1.1651,1.8008,1.8008,0,0,1,1.208-.4131,1.991,1.991,0,0,1,.918.2139,1.55,1.55,0,0,1,.6416.5835,1.4955,1.4955,0,0,1,.2217.7847,1.2868,1.2868,0,0,1-.211.7182,1.45,1.45,0,0,1-.6269.5152,1.4106,1.4106,0,0,1,.8379.5171,1.5672,1.5672,0,0,1,.3.9819,1.7977,1.7977,0,0,1-.5821,1.3516,2.0432,2.0432,0,0,1-1.47.5542,1.9136,1.9136,0,0,1-1.331-.4776A1.8478,1.8478,0,0,1,170.3662,84.6122Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  11: [54.848, 7.0884],
  12: [36.4846, 57.3091],
  13: [63.4377, 57.3091],
  14: [77.9525, 57.3091],
  15: [82.3715, 7.0884],
  39: [135.7828, 36.2417],
  40: [135.7828, 72.5363],
  41: [90.7952, 72.5363],
  65: [90.7952, 86.0066],
  66: [135.7828, 96.0066],
  67: [135.7828, 144.7428],
  68: [56.9873, 143.5353],
  69: [56.9873, 130.4153],
  70: [56.9873, 117.0263],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }
  .cls-17 {
    fill: url(#linear-gradient-9);
  }
  .cls-18 {
    fill: url(#linear-gradient-10);
  }
  .cls-19 {
    fill: url(#linear-gradient-11);
  }
  .cls-20 {
    fill: url(#linear-gradient-12);
  }
  .cls-21 {
    fill: url(#linear-gradient-13);
  }
  .cls-22 {
    fill: url(#linear-gradient-14);
  }

  #A11,
  #A12,
  #A13,
  #A14,
  #A15,
  #B39,
  #B40,
  #B41,
  #C65,
  #C66,
  #C67,
  #C68,
  #C69,
  #C70 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
