export const FLOOR_8_DATA = [
  {
    floor: 8,
    building: 'B',
    apartmentNumber: `${54}terrace`,
    sceneName: 'scene_Otavallanhovi_08_krs_B54_terrace',
    sceneName_future: 'scene_Otavallanhovi_08_krs_B54_terrace_future',
    name: 'B54 2',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {},
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: 54,
    sceneName: 'scene_Otavallanhovi_08_krs_B54',
    sceneName_future: 'scene_Otavallanhovi_08_krs_B54_future',
    name: 'B54 1',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      7: 53,
    },
  },
  {
    floor: 8,
    building: 'B',
    apartmentNumber: `${54}small`,
    sceneName: 'scene_Otavallanhovi_08_krs_B54_small',
    sceneName_future: 'scene_Otavallanhovi_08_krs_B54_small_future',
    name: 'C 91',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      7: 88,
    },
  },

  {
    floor: 8,
    building: 'C',
    apartmentNumber: 92,
    sceneName: 'scene_Otavallanhovi_08_krs_C92',
    sceneName_future: 'scene_Otavallanhovi_08_krs_C92_future',
    name: 'C92 1',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      7: 90,
    },
  },
  {
    floor: 8,
    building: 'C',
    apartmentNumber: `${92}small`,
    sceneName: 'scene_Otavallanhovi_08_krs_C92_small',
    sceneName_future: 'scene_Otavallanhovi_08_krs_C92_future',
    name: 'C92 2',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      7: `${90}small`,
    },
  },
]
