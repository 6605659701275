export const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 31,
    sceneName: 'scene_Otavallanhovi_07_krs_A31',
    sceneName_future: 'scene_Otavallanhovi_07_krs_A31_future',
    name: 'A31',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      6: 27,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 32,
    sceneName: 'scene_Otavallanhovi_07_krs_A32',
    sceneName_future: 'scene_Otavallanhovi_07_krs_A32_future',
    name: 'A32',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      6: 28,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 33,
    sceneName: 'scene_Otavallanhovi_07_krs_A33',
    sceneName_future: 'scene_Otavallanhovi_07_krs_A33_future',
    name: 'A33',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      6: 29,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 51,
    sceneName: 'scene_Otavallanhovi_07_krs_B51',
    sceneName_future: 'scene_Otavallanhovi_07_krs_B51_future',
    name: 'B51',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      6: 48,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 52,
    sceneName: 'scene_Otavallanhovi_07_krs_B52',
    sceneName_future: 'scene_Otavallanhovi_07_krs_B52_future',
    name: 'B52',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      6: 49,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'B',
    apartmentNumber: 53,
    sceneName: 'scene_Otavallanhovi_07_krs_B53',
    sceneName_future: 'scene_Otavallanhovi_07_krs_B53_future',
    name: 'B53',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      6: 50,
      8: 54,
    },
  },
  {
    floor: 7,
    building: 'C',
    apartmentNumber: 88,
    sceneName: 'scene_Otavallanhovi_07_krs_C88',
    sceneName_future: 'scene_Otavallanhovi_07_krs_C88_future',
    name: 'C88',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      6: 83,
      8: `${54}small`,
    },
  },
  {
    floor: 7,
    building: 'C',
    apartmentNumber: `${89}small`,
    sceneName: 'scene_Otavallanhovi_07_krs_C89_small',
    sceneName_future: 'scene_Otavallanhovi_07_krs_C89_small_future',
    name: 'C89 2',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      6: `${84}small`,
      8: `${54}small`,
    },
  },
  {
    floor: 7,
    building: 'C',
    apartmentNumber: 89,
    sceneName: 'scene_Otavallanhovi_07_krs_C89',
    sceneName_future: 'scene_Otavallanhovi_07_krs_C89_future',
    name: 'C89 1',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      6: 84,
      8: `${54}small`,
    },
  },
  {
    floor: 7,
    building: 'C',
    apartmentNumber: `${90}small`,
    sceneName: 'scene_Otavallanhovi_07_krs_C90_small',
    sceneName_future: 'scene_Otavallanhovi_07_krs_C90_small_future',
    name: 'C90 2',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      6: 85,
      8: `${92}small`,
    },
  },
  {
    floor: 7,
    building: 'C',
    apartmentNumber: 90,
    sceneName: 'scene_Otavallanhovi_07_krs_C90',
    sceneName_future: 'scene_Otavallanhovi_07_krs_C90_future',
    name: 'C90 1',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      6: 86,
      8: 92,
    },
  },
]
