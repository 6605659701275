export const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 26,
    sceneName: 'scene_Otavallanhovi_06_krs_A26',
    sceneName_future: 'scene_Otavallanhovi_06_krs_A26_future',
    name: 'A26',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      5: 21,
      7: 88,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 27,
    sceneName: 'scene_Otavallanhovi_06_krs_B27',
    sceneName_future: 'scene_Otavallanhovi_06_krs_A27_future',
    name: 'A27',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      5: 22,
      7: 31,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 28,
    sceneName: 'scene_Otavallanhovi_06_krs_B28',
    sceneName_future: 'scene_Otavallanhovi_06_krs_A28_future',
    name: 'A28',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      5: 23,
      7: 32,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 29,
    sceneName: 'scene_Otavallanhovi_06_krs_B29',
    sceneName_future: 'scene_Otavallanhovi_06_krs_A29_future',
    name: 'A29',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      5: 24,
      7: 33,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 30,
    sceneName: 'scene_Otavallanhovi_06_krs_A30',
    sceneName_future: 'scene_Otavallanhovi_06_krs_A30_future',
    name: 'A30',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      5: 25,
      7: 88,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 48,
    sceneName: 'scene_Otavallanhovi_06_krs_B48',
    sceneName_future: 'scene_Otavallanhovi_06_krs_B48_future',
    name: 'B48',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      5: 45,
      7: 51,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 49,
    sceneName: 'scene_Otavallanhovi_06_krs_B49',
    sceneName_future: 'scene_Otavallanhovi_06_krs_B49_future',
    name: 'B49',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      5: 46,
      7: 52,
    },
  },
  {
    floor: 6,
    building: 'B',
    apartmentNumber: 50,
    sceneName: 'scene_Otavallanhovi_06_krs_B50',
    sceneName_future: 'scene_Otavallanhovi_06_krs_B50_future',
    name: 'B50',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      5: 47,
      7: 53,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: 83,
    sceneName: 'scene_Otavallanhovi_06_krs_C83',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C83_future',
    name: 'C83',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      5: 77,
      7: 88,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: `${84}small`,
    sceneName: 'scene_Otavallanhovi_06_krs_C84_small',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C84_small_future',
    name: 'C84 2',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      5: 78,
      7: `${89}small`,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: 84,
    sceneName: 'scene_Otavallanhovi_06_krs_C84',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C84_future',
    name: 'C84 1',
    lookAt: '80, 14.11, 140',
    face: 'east',

    relatedApartments: {
      5: 79,
      7: 89,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: 85,
    sceneName: 'scene_Otavallanhovi_06_krs_C85',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C85_future',
    name: 'C85',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      5: 80,
      7: `${90}small`,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: 86,
    sceneName: 'scene_Otavallanhovi_06_krs_C86',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C86_future',
    name: 'C86',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      5: 81,
      7: 90,
    },
  },
  {
    floor: 6,
    building: 'C',
    apartmentNumber: 87,
    sceneName: 'scene_Otavallanhovi_06_krs_C87',
    sceneName_future: 'scene_Otavallanhovi_06_krs_C87_future',
    name: 'C87',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      5: 82,
      7: 90,
    },
  },
]
