import * as React from 'react'
import styled from 'styled-components'

export default function Floor2({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_2"
      data-name="2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="217.9698"
          y1="275.003"
          x2="217.9698"
          y2="230.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="248.4401"
          y1="275.003"
          x2="248.4401"
          y2="230.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(2, 6)}
          onMouseOver={() => setElementOnHover('A06')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A6"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_A06'
                ? 'active-apt'
                : elementOnHover === 'A06'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 9.837 42.915 23.784 67.917 23.784 67.917 9.837 59.107 9.837 59.107 7.088 50.516 7.088 50.516 9.837 42.915 9.837"
          />
        </g>
        <g
          onClick={() => changeView(2, 7)}
          onMouseOver={() => setElementOnHover('A07')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A7"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_A07'
                ? 'active-apt'
                : elementOnHover === 'A07'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 9.837 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 23.784 42.915 23.784 42.915 9.837 23.779 9.837"
          />
        </g>
        <g
          onClick={() => changeView(2, 8)}
          onMouseOver={() => setElementOnHover('A08')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A8"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_A08'
                ? 'active-apt'
                : elementOnHover === 'A08'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(2, 9)}
          onMouseOver={() => setElementOnHover('A09')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A9"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_A09'
                ? 'active-apt'
                : elementOnHover === 'A09'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(2, 10)}
          onMouseOver={() => setElementOnHover('A10')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A10"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_A10'
                ? 'active-apt'
                : elementOnHover === 'A10'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 9.837 67.917 23.784 95.873 23.784 95.873 9.837 86.583 9.837 86.583 7.088 78.086 7.088 78.086 9.837 67.917 9.837"
          />
        </g>
        <g
          onClick={() => changeView(2, 36)}
          onMouseOver={() => setElementOnHover('B36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B36"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_B36'
                ? 'active-apt'
                : elementOnHover === 'B36'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 9.837 95.873 39.77 114.376 39.749 114.376 44.171 138.752 44.171 138.752 10.038 95.873 9.837"
          />
        </g>
        <g
          onClick={() => changeView(2, 37)}
          onMouseOver={() => setElementOnHover('B37')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B37"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_B37'
                ? 'active-apt'
                : elementOnHover === 'B37'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 78.462 138.665 78.462 138.665 44.618 114.29 44.618 114.029 78.462"
          />
        </g>
        <g
          onClick={() => changeView(2, 38)}
          onMouseOver={() => setElementOnHover('B38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B38"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_B38'
                ? 'active-apt'
                : elementOnHover === 'B38'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 65.816 92.279 65.816 92.279 75.51 95.671 75.51 95.671 78.015 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(2, 59)}
          onMouseOver={() => setElementOnHover('C59')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C59"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C59'
                ? 'active-apt'
                : elementOnHover === 'C59'
                ? 'on-hover'
                : ''
            }`}
            points="95.79 91.484 95.79 96.615 92.279 96.615 92.279 106.309 95.79 106.309 95.79 109.277 115.219 109.277 115.219 91.521 95.79 91.484"
          />
        </g>
        <g
          onClick={() => changeView(2, 60)}
          onMouseOver={() => setElementOnHover('C60')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C60"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C60'
                ? 'active-apt'
                : elementOnHover === 'C60'
                ? 'on-hover'
                : ''
            }`}
            points="138.567 91.521 138.567 117.543 114.975 117.543 115.219 91.521 138.567 91.521"
          />
        </g>
        <g
          onClick={() => changeView(2, 61)}
          onMouseOver={() => setElementOnHover('C61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C61"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C61'
                ? 'active-apt'
                : elementOnHover === 'C61'
                ? 'on-hover'
                : ''
            }`}
            points="99.053 151.213 99.053 127.956 114.843 127.956 114.843 117.543 138.567 117.543 138.567 151.296 99.053 151.213"
          />
        </g>
        <g
          onClick={() => changeView(2, 62)}
          onMouseOver={() => setElementOnHover('C62')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C62"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C62'
                ? 'active-apt'
                : elementOnHover === 'C62'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
        <g
          onClick={() => changeView(2, 63)}
          onMouseOver={() => setElementOnHover('C63')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C63"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C63'
                ? 'active-apt'
                : elementOnHover === 'C63'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(2, 64)}
          onMouseOver={() => setElementOnHover('C64')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C64"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_02_krs_C64'
                ? 'active-apt'
                : elementOnHover === 'C64'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
      </g>
      <g id="levelfloor">
        <path
          className="cls-3"
          d="M128.3711,92.2294v9.5383l1.42-.0027,7.6142-.0149v35.157l-7.62.0189-1.4138.0035V150.214H63.3133V109.9506H96.36V92.2294h32.0115m1.4173-1.4173H94.9423v17.7212H61.8959v43.098h67.8925v-13.288l9.0343-.0224V100.33l-9.0343.0176V90.8121Z"
        />
        <path
          className="cls-3"
          d="M137.4063,10.4876l.012,17.0265h-7.5529l.0023,1.42.0237,14.2487.0024,1.4111,1.411.0039,6.1005.017V49.26l-.0123,17.5936h-8.8284V77.3181H96.36v-25.75H24.5864V10.4876h112.82M138.8227,9.07H23.1691V52.9855H94.9423v25.75h35.04V68.2707h8.8275l.0133-19.01v-6.06l-7.5139-.0209-.0237-14.2488h7.5515L138.8227,9.07Z"
        />
        <polyline
          className="cls-4"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-4"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-4"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-4"
          points="99.053 151.631 99.053 127.956 115.315 127.956"
        />
        <polyline
          className="cls-4"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-4"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-4"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-4"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-4"
          x1="95.8734"
          y1="9.837"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-4"
          points="95.873 23.715 67.917 23.715 67.917 9.837"
        />
        <polyline
          className="cls-4"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-4"
          points="45.296 23.715 42.915 23.715 42.915 9.837"
        />
        <line
          className="cls-4"
          x1="45.3101"
          y1="34.4952"
          x2="73.5293"
          y2="34.4952"
        />
        <polyline
          className="cls-4"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-4"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-4"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-3"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-4"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-4"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.837 50.516 7.088 59.107 7.088 59.107 9.768"
        />
        <polyline
          className="cls-5"
          points="78.086 9.837 78.086 7.088 86.583 7.088 86.583 9.837"
        />
        <polyline
          className="cls-5"
          points="95.028 75.51 92.279 75.51 92.279 65.816 95.028 65.816"
        />
        <polyline
          className="cls-5"
          points="95.028 106.309 92.279 106.309 92.279 96.615 95.028 96.615"
        />
        <line
          className="cls-4"
          x1="114.9748"
          y1="117.3803"
          x2="138.3093"
          y2="117.3803"
        />
        <line
          className="cls-4"
          x1="114.9748"
          y1="109.3642"
          x2="114.9748"
          y2="91.3612"
        />
        <line
          className="cls-6"
          x1="138.8237"
          y1="91.5205"
          x2="129.7884"
          y2="91.5205"
        />
        <line
          className="cls-6"
          x1="138.8237"
          y1="78.0316"
          x2="129.7884"
          y2="78.0316"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.321"
        />
        <line
          className="cls-5"
          x1="138.659"
          y1="100.3794"
          x2="138.659"
          y2="92.1328"
        />
        <line
          className="cls-5"
          x1="138.6654"
          y1="77.3189"
          x2="138.6654"
          y2="68.2707"
        />
        <line
          className="cls-5"
          x1="138.6767"
          y1="43.2003"
          x2="138.6767"
          y2="28.9315"
        />
        <line
          className="cls-4"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_A06'
              ? 'white-text'
              : elementOnHover === 'A06'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 6)}
          onMouseOver={() => setElementOnHover('A06')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-7"
            d="M59.6324,15.5292l-.4956.0391a.9431.9431,0,0,0-.188-.4268.6613.6613,0,0,0-.4986-.2129.6833.6833,0,0,0-.4179.1328,1.1377,1.1377,0,0,0-.3711.501,2.6236,2.6236,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.191,1.191,0,0,1,.5454-.1329,1.1275,1.1275,0,0,1,.8481.3672,1.3164,1.3164,0,0,1,.35.9483,1.56,1.56,0,0,1-.1645.71,1.1993,1.1993,0,0,1-.4526.502,1.2367,1.2367,0,0,1-.6534.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3133,2.3133,0,0,1-.3931-1.5108,2.7017,2.7017,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0215-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1555,1.1555,0,0,0,.1094.4922.8118.8118,0,0,0,.3056.3584.767.767,0,0,0,.4126.123.7045.7045,0,0,0,.5425-.2539,1.0025,1.0025,0,0,0,.2271-.6924.9379.9379,0,0,0-.2242-.663.7375.7375,0,0,0-.5644-.2422.77.77,0,0,0-.5733.2422A.8731.8731,0,0,0,57.5977,17.2792Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_A07'
              ? 'white-text'
              : elementOnHover === 'A07'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 7)}
          onMouseOver={() => setElementOnHover('A07')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-7"
            d="M35.22,31.8915v-.48h2.6269V31.8a5.5335,5.5335,0,0,0-.768,1.0967,6.6417,6.6417,0,0,0-.5884,1.4062,5.2262,5.2262,0,0,0-.1909,1.1153h-.5123a4.96,4.96,0,0,1,.1885-1.1572,6.0683,6.0683,0,0,1,.5161-1.3077,5.5926,5.5926,0,0,1,.7154-1.0615Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_A08'
              ? 'white-text'
              : elementOnHover === 'A08'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 8)}
          onMouseOver={() => setElementOnHover('A08')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-7"
            d="M61.6734,42.7231a.9378.9378,0,0,1-.4595-.3233.8491.8491,0,0,1-.1494-.5039.9859.9859,0,0,1,.3184-.7451,1.1808,1.1808,0,0,1,.8471-.3018,1.1928,1.1928,0,0,1,.8555.3086,1.0013,1.0013,0,0,1,.3237.752.8262.8262,0,0,1-.1479.4912.9326.9326,0,0,1-.45.3223,1.0945,1.0945,0,0,1,.5688.3935,1.0766,1.0766,0,0,1,.1953.6475,1.165,1.165,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1837,1.1837,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0111,1.0111,0,0,1,61.6734,42.7231Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.71.71,0,0,0,.3.3086.875.875,0,0,0,.4292.1094.8037.8037,0,0,0,.59-.2295.7868.7868,0,0,0,.2325-.584.7984.7984,0,0,0-.2393-.5947.82.82,0,0,0-.6-.2354.793.793,0,0,0-.814.8135Zm.1607-1.874a.634.634,0,0,0,.1855.4707.6555.6555,0,0,0,.4815.1826.6429.6429,0,0,0,.4721-.1816.6.6,0,0,0,.1841-.4444.6223.6223,0,0,0-.1894-.4609.6977.6977,0,0,0-.9454-.0039A.5892.5892,0,0,0,61.5738,41.8793Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_A09'
              ? 'white-text'
              : elementOnHover === 'A09'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 9)}
          onMouseOver={() => setElementOnHover('A09')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-7"
            d="M86.1621,39.1747l.4791-.0439a.8286.8286,0,0,0,.2324.4892.6377.6377,0,0,0,.44.1524.7666.7666,0,0,0,.4028-.1045A.8617.8617,0,0,0,88,39.3866a1.6514,1.6514,0,0,0,.1856-.4746,2.5029,2.5029,0,0,0,.0747-.6094c0-.0224-.001-.0556-.0024-.1a1.1466,1.1466,0,0,1-.4087.3868,1.11,1.11,0,0,1-.5606.1474,1.1347,1.1347,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3487-.9629,1.3691,1.3691,0,0,1,.3638-.9942,1.2171,1.2171,0,0,1,.9121-.3769,1.2979,1.2979,0,0,1,.7241.2139,1.3276,1.3276,0,0,1,.4981.6074,2.9841,2.9841,0,0,1,.17,1.1416A3.697,3.697,0,0,1,88.587,39.24a1.4468,1.4468,0,0,1-.5025.7012,1.2962,1.2962,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1407-1.0078Zm2.04-1.791a.9768.9768,0,0,0-.228-.6807.7165.7165,0,0,0-.55-.2519.7572.7572,0,0,0-.5787.2715,1.0056,1.0056,0,0,0-.2461.7031.8657.8657,0,0,0,.2339.6289.7679.7679,0,0,0,.5772.2422.7365.7365,0,0,0,.5688-.2422A.9526.9526,0,0,0,88.2022,37.3837Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_A10'
              ? 'white-text'
              : elementOnHover === 'A10'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 10)}
          onMouseOver={() => setElementOnHover('A10')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M77.2769,18.4667l1.5586-4.0586h.5786l1.6606,4.0586h-.6118L79.99,17.2372H78.293l-.4458,1.2295Zm1.1709-1.666h1.376L79.4,15.6767q-.1934-.5127-.2876-.8418a5.2,5.2,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-7"
            d="M83.4624,18.4667h-.4985V15.2919a2.5875,2.5875,0,0,1-.4717.3428,3.4,3.4,0,0,1-.5249.2578V15.411a3.0278,3.0278,0,0,0,.731-.4765,1.874,1.874,0,0,0,.4429-.543h.3212Z"
          />
          <path
            className="cls-7"
            d="M85.022,16.4657a3.7194,3.7194,0,0,1,.148-1.1592,1.4447,1.4447,0,0,1,.44-.6767,1.1268,1.1268,0,0,1,.7349-.2383,1.196,1.196,0,0,1,.5727.1318,1.098,1.098,0,0,1,.4072.379,2.0563,2.0563,0,0,1,.252.6035,4.0383,4.0383,0,0,1,.0913.96,3.7074,3.7074,0,0,1-.147,1.1524,1.4442,1.4442,0,0,1-.4385.6787,1.1282,1.1282,0,0,1-.7377.2392,1.1137,1.1137,0,0,1-.9219-.4209A2.6739,2.6739,0,0,1,85.022,16.4657Zm.5122,0a2.5428,2.5428,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.0019,2.5458,2.5458,0,0,0,.2339-1.3282,2.5456,2.5456,0,0,0-.2339-1.332.694.694,0,0,0-.583-.3291.6393.6393,0,0,0-.5479.29A2.5759,2.5759,0,0,0,85.5342,16.4657Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_B36'
              ? 'white-text'
              : elementOnHover === 'B36'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 36)}
          onMouseOver={() => setElementOnHover('B36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-7"
            d="M115.1866,26.6479l.498-.0664a1.0941,1.0941,0,0,0,.292.61.7187.7187,0,0,0,.502.1865.8051.8051,0,0,0,.5937-.2431.8188.8188,0,0,0,.2422-.6036.7654.7654,0,0,0-.2236-.5664.7762.7762,0,0,0-.57-.2226,1.4145,1.4145,0,0,0-.3515.0556l.0556-.4375a.6954.6954,0,0,0,.08.0059,1.0189,1.0189,0,0,0,.5723-.167.5674.5674,0,0,0,.2549-.5117.6049.6049,0,0,0-.1856-.4541.6568.6568,0,0,0-.4785-.18.6779.6779,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.498-.0879a1.2843,1.2843,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2753,1.3319,1.3319,0,0,1,.6113.1425,1.03,1.03,0,0,1,.4277.39.9966.9966,0,0,1,.1485.5224.8545.8545,0,0,1-.1416.48.9641.9641,0,0,1-.418.3428.9463.9463,0,0,1,.56.3447,1.0507,1.0507,0,0,1,.1992.6553,1.2,1.2,0,0,1-.3877.9,1.3615,1.3615,0,0,1-.9805.37,1.2766,1.2766,0,0,1-.8867-.3183A1.2323,1.2323,0,0,1,115.1866,26.6479Z"
          />
          <path
            className="cls-7"
            d="M121.2051,24.6547l-.4951.0391a.9462.9462,0,0,0-.1885-.4268.6608.6608,0,0,0-.498-.2129.6825.6825,0,0,0-.418.1328,1.1347,1.1347,0,0,0-.3711.501,2.613,2.613,0,0,0-.1416.9385,1.1584,1.1584,0,0,1,.44-.4073,1.1961,1.1961,0,0,1,.5459-.1328,1.1265,1.1265,0,0,1,.8477.3672,1.3167,1.3167,0,0,1,.3506.9483,1.5546,1.5546,0,0,1-.1651.71,1.1969,1.1969,0,0,1-.4521.502,1.2391,1.2391,0,0,1-.6533.1748,1.2753,1.2753,0,0,1-1.0157-.458,2.3107,2.3107,0,0,1-.3935-1.5108,2.7009,2.7009,0,0,1,.4346-1.71,1.249,1.249,0,0,1,1.0214-.4658,1.086,1.086,0,0,1,1.1514,1.0107Zm-2.0342,1.75a1.15,1.15,0,0,0,.1094.4922.8094.8094,0,0,0,.3057.3584.7648.7648,0,0,0,.4121.123.7049.7049,0,0,0,.543-.2539,1.0028,1.0028,0,0,0,.2265-.6924.9381.9381,0,0,0-.2236-.663.7377.7377,0,0,0-.5645-.2422.7684.7684,0,0,0-.5732.2422A.8711.8711,0,0,0,119.1709,26.4047Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_B37'
              ? 'white-text'
              : elementOnHover === 'B37'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 37)}
          onMouseOver={() => setElementOnHover('B37')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-7"
            d="M124.9776,59.2406l.498-.0664a1.1073,1.1073,0,0,0,.292.61.7211.7211,0,0,0,.5029.1865.8069.8069,0,0,0,.5938-.2432.8224.8224,0,0,0,.2422-.6035.7662.7662,0,0,0-.2246-.5664.7744.7744,0,0,0-.57-.2226,1.4282,1.4282,0,0,0-.3516.0556l.0557-.4375a.7157.7157,0,0,0,.08.0059,1.0219,1.0219,0,0,0,.5733-.167.5677.5677,0,0,0,.2549-.5117.6086.6086,0,0,0-.1856-.4541.6592.6592,0,0,0-.48-.18.6787.6787,0,0,0-.4843.1826.9207.9207,0,0,0-.2491.5478l-.498-.0878a1.2843,1.2843,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2754,1.3331,1.3331,0,0,1,.6123.1426,1.0424,1.0424,0,0,1,.4277.39.9953.9953,0,0,1,.1475.5224.8616.8616,0,0,1-.1406.4795.97.97,0,0,1-.418.3428.9463.9463,0,0,1,.5586.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1959,1.1959,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2774,1.2774,0,0,1-.8877-.3184A1.236,1.236,0,0,1,124.9776,59.2406Z"
          />
          <path
            className="cls-7"
            d="M128.4444,56.7856v-.48h2.6269v.3877a5.5366,5.5366,0,0,0-.7676,1.0966,6.6545,6.6545,0,0,0-.5888,1.4063,5.1948,5.1948,0,0,0-.19,1.1152h-.5127a4.9807,4.9807,0,0,1,.1884-1.1572,6.0672,6.0672,0,0,1,.5166-1.3076,5.5919,5.5919,0,0,1,.7149-1.0615Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_02_krs_B38'
              ? 'white-text'
              : elementOnHover === 'B38'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 38)}
          onMouseOver={() => setElementOnHover('B38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-7"
            d="M104.14,67.2231l.498-.0665a1.1013,1.1013,0,0,0,.292.61.72.72,0,0,0,.5025.1865.8059.8059,0,0,0,.5937-.2431.8209.8209,0,0,0,.2422-.6036.7658.7658,0,0,0-.2241-.5664.7752.7752,0,0,0-.57-.2226,1.4213,1.4213,0,0,0-.3516.0556l.0557-.4375a.7157.7157,0,0,0,.08.0059,1.02,1.02,0,0,0,.5728-.167.5674.5674,0,0,0,.2549-.5117.6068.6068,0,0,0-.1856-.4541.658.658,0,0,0-.479-.18.6779.6779,0,0,0-.4843.1826.9208.9208,0,0,0-.2491.5479l-.498-.0879a1.2843,1.2843,0,0,1,.415-.7774,1.2,1.2,0,0,1,.8057-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0362,1.0362,0,0,1,.4277.39.9961.9961,0,0,1,.148.5224.858.858,0,0,1-.1411.48.967.967,0,0,1-.418.3428.9465.9465,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1977,1.1977,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2766,1.2766,0,0,1-.8872-.3184A1.234,1.234,0,0,1,104.14,67.2231Z"
          />
          <path
            className="cls-7"
            d="M108.34,66.0932a.9378.9378,0,0,1-.46-.3233.8491.8491,0,0,1-.1494-.5039.9862.9862,0,0,1,.3183-.7451,1.1811,1.1811,0,0,1,.8472-.3018,1.1932,1.1932,0,0,1,.8555.3086,1.0013,1.0013,0,0,1,.3237.752.8262.8262,0,0,1-.1479.4912.932.932,0,0,1-.45.3223,1.0951,1.0951,0,0,1,.5688.3935,1.0766,1.0766,0,0,1,.1953.6475,1.1649,1.1649,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1837,1.1837,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0111,1.0111,0,0,1,108.34,66.0932Zm-.26,1.03a.9016.9016,0,0,0,.1011.4122.71.71,0,0,0,.3.3085.875.875,0,0,0,.4292.1094.8037.8037,0,0,0,.59-.23.7868.7868,0,0,0,.2324-.584.7983.7983,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2353.7929.7929,0,0,0-.814.8134Zm.1607-1.874a.6338.6338,0,0,0,.1855.4707.6554.6554,0,0,0,.4815.1826.6429.6429,0,0,0,.4721-.1816.6.6,0,0,0,.1841-.4443.6223.6223,0,0,0-.1894-.461.6977.6977,0,0,0-.9454-.0039A.5888.5888,0,0,0,108.2408,65.2494Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C59'
              ? 'white-text'
              : elementOnHover === 'C59'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 59)}
          onMouseOver={() => setElementOnHover('C59')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-7"
            d="M104.813,101.1176l.523-.044a.9383.9383,0,0,0,.27.5743.73.73,0,0,0,.5107.1923.795.795,0,0,0,.6089-.27,1.0221,1.0221,0,0,0,.2495-.72.9238.9238,0,0,0-.24-.6728.8363.8363,0,0,0-.627-.2471.8751.8751,0,0,0-.4345.1094.85.85,0,0,0-.3047.2841l-.4678-.0605.3931-2.085h2.018v.4766h-1.6191l-.2188,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.2122,1.2122,0,0,1,.897.3682,1.2846,1.2846,0,0,1,.3653.9463,1.4786,1.4786,0,0,1-.3208.9521,1.2857,1.2857,0,0,1-1.066.4932,1.311,1.311,0,0,1-.9038-.3106A1.2078,1.2078,0,0,1,104.813,101.1176Z"
          />
          <path
            className="cls-7"
            d="M108.3243,101.2426l.479-.044a.8289.8289,0,0,0,.2324.4893.6376.6376,0,0,0,.44.1523.7663.7663,0,0,0,.4029-.1045.862.862,0,0,0,.2836-.2812,1.6514,1.6514,0,0,0,.1856-.4746,2.5035,2.5035,0,0,0,.0747-.6094c0-.0224-.001-.0557-.0024-.1a1.1446,1.1446,0,0,1-.4087.3867,1.1091,1.1091,0,0,1-.5606.1475,1.1344,1.1344,0,0,1-.8525-.3653,1.3359,1.3359,0,0,1-.3486-.9628,1.3689,1.3689,0,0,1,.3637-.9942,1.2167,1.2167,0,0,1,.9121-.3769,1.2991,1.2991,0,0,1,.7242.2138,1.3283,1.3283,0,0,1,.498.6075,2.9838,2.9838,0,0,1,.17,1.1416,3.6944,3.6944,0,0,1-.1689,1.2392,1.4467,1.4467,0,0,1-.5025.7012,1.2959,1.2959,0,0,1-.7817.2412,1.0728,1.0728,0,0,1-1.1406-1.0078Zm2.04-1.791a.9766.9766,0,0,0-.228-.6807.7162.7162,0,0,0-.55-.2519.757.757,0,0,0-.5786.2714,1.0056,1.0056,0,0,0-.2461.7032.8658.8658,0,0,0,.2338.6289.7679.7679,0,0,0,.5772.2422.7365.7365,0,0,0,.5688-.2422A.9527.9527,0,0,0,110.3643,99.4516Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C60'
              ? 'white-text'
              : elementOnHover === 'C60'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 60)}
          onMouseOver={() => setElementOnHover('C60')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M123.4483,107.7777l.5371.1358a1.8079,1.8079,0,0,1-.6084,1.0088,1.6729,1.6729,0,0,1-1.0723.3476,1.9233,1.9233,0,0,1-1.0674-.2676,1.6646,1.6646,0,0,1-.625-.7734,2.7525,2.7525,0,0,1-.2148-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.66,1.66,0,0,1,.6894-.7178,2.0145,2.0145,0,0,1,.9834-.2442,1.6608,1.6608,0,0,1,1.0245.31,1.6111,1.6111,0,0,1,.5791.8721l-.5293.124a1.2536,1.2536,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3151,1.3151,0,0,0-.7822.2246,1.1845,1.1845,0,0,0-.4414.6016,2.4209,2.4209,0,0,0-.127.7793,2.478,2.478,0,0,0,.15.9043,1.1262,1.1262,0,0,0,.47.5771,1.3164,1.3164,0,0,0,.69.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,123.4483,107.7777Z"
          />
          <path
            className="cls-7"
            d="M127.3135,106.1361l-.4961.0391a.9358.9358,0,0,0-.1875-.4268.6613.6613,0,0,0-.499-.2128.684.684,0,0,0-.418.1328,1.1421,1.1421,0,0,0-.3711.5009,2.64,2.64,0,0,0-.1406.9385,1.1581,1.1581,0,0,1,.44-.4072,1.1878,1.1878,0,0,1,.545-.1328,1.1282,1.1282,0,0,1,.8486.3672,1.3156,1.3156,0,0,1,.35.9482,1.5673,1.5673,0,0,1-.1641.71,1.1994,1.1994,0,0,1-.4531.5019,1.2341,1.2341,0,0,1-.6533.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3161,2.3161,0,0,1-.3926-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2467,1.2467,0,0,1,1.0214-.4658,1.0878,1.0878,0,0,1,1.1514,1.0107Zm-2.0351,1.75a1.164,1.164,0,0,0,.1093.4922.8146.8146,0,0,0,.3057.3584.7676.7676,0,0,0,.4131.1231.7047.7047,0,0,0,.542-.2539,1.0034,1.0034,0,0,0,.2275-.6924.9386.9386,0,0,0-.2246-.6631.738.738,0,0,0-.5644-.2422.7705.7705,0,0,0-.5733.2422A.8751.8751,0,0,0,125.2784,107.8861Z"
          />
          <path
            className="cls-7"
            d="M128.1641,107.2a3.7205,3.7205,0,0,1,.1484-1.1592,1.4414,1.4414,0,0,1,.44-.6767,1.1263,1.1263,0,0,1,.7343-.2383,1.197,1.197,0,0,1,.5733.1318,1.1031,1.1031,0,0,1,.4072.3789,2.0881,2.0881,0,0,1,.252.6036,4.0422,4.0422,0,0,1,.0908.96,3.7243,3.7243,0,0,1-.1465,1.1524,1.4449,1.4449,0,0,1-.4385.6787,1.1311,1.1311,0,0,1-.7383.2392,1.1142,1.1142,0,0,1-.9218-.4209A2.6741,2.6741,0,0,1,128.1641,107.2Zm.5127,0a2.543,2.543,0,0,0,.2334,1.33.6686.6686,0,0,0,1.1543-.002,3.8907,3.8907,0,0,0,0-2.66.6935.6935,0,0,0-.583-.3291.6393.6393,0,0,0-.5479.29A2.5768,2.5768,0,0,0,128.6768,107.2Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C61'
              ? 'white-text'
              : elementOnHover === 'C61'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 61)}
          onMouseOver={() => setElementOnHover('C61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
          />
          <path
            className="cls-7"
            d="M119.8116,134.269l-.4961.039a.9369.9369,0,0,0-.1875-.4267.6616.6616,0,0,0-.4991-.2129.6837.6837,0,0,0-.4179.1328,1.14,1.14,0,0,0-.3711.501,2.6362,2.6362,0,0,0-.1406.9384,1.1581,1.1581,0,0,1,.44-.4072,1.1874,1.1874,0,0,1,.5449-.1328,1.1286,1.1286,0,0,1,.8486.3672,1.3157,1.3157,0,0,1,.35.9482,1.5673,1.5673,0,0,1-.1641.71,1.2007,1.2007,0,0,1-.4531.5019,1.2342,1.2342,0,0,1-.6534.1748,1.2748,1.2748,0,0,1-1.0156-.458,2.3161,2.3161,0,0,1-.3926-1.5107,2.7018,2.7018,0,0,1,.4346-1.71,1.247,1.247,0,0,1,1.0215-.4658,1.0878,1.0878,0,0,1,1.1514,1.0108Zm-2.0352,1.75a1.1625,1.1625,0,0,0,.1094.4921.8144.8144,0,0,0,.3056.3584.7679.7679,0,0,0,.4131.1231.7044.7044,0,0,0,.542-.2539,1.0027,1.0027,0,0,0,.2276-.6924.9386.9386,0,0,0-.2246-.6631.7382.7382,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.875.875,0,0,0,117.7764,136.019Z"
          />
          <path
            className="cls-7"
            d="M122.5391,137.3334h-.498v-3.1748a2.5945,2.5945,0,0,1-.4717.3428,3.425,3.425,0,0,1-.5254.2578v-.4815a3.03,3.03,0,0,0,.7314-.4765,1.878,1.878,0,0,0,.4424-.543h.3213Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C62'
              ? 'white-text'
              : elementOnHover === 'C62'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 62)}
          onMouseOver={() => setElementOnHover('C62')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-7"
            d="M78.918,142.8158l-.4956.0391a.941.941,0,0,0-.188-.4268.6612.6612,0,0,0-.4985-.2129.683.683,0,0,0-.418.1329,1.1376,1.1376,0,0,0-.3711.5009,2.6266,2.6266,0,0,0-.1411.9385,1.1573,1.1573,0,0,1,.44-.4072,1.1912,1.1912,0,0,1,.5454-.1328,1.1272,1.1272,0,0,1,.8482.3672,1.316,1.316,0,0,1,.35.9482,1.5613,1.5613,0,0,1-.1646.71,1.1983,1.1983,0,0,1-.4526.5019,1.2366,1.2366,0,0,1-.6533.1748,1.2747,1.2747,0,0,1-1.0156-.458,2.3125,2.3125,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0214-.4658,1.0868,1.0868,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1566,1.1566,0,0,0,.1094.4922.812.812,0,0,0,.3057.3584.7666.7666,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.0032,1.0032,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8731.8731,0,0,0,76.8833,144.5658Z"
          />
          <path
            className="cls-7"
            d="M82.3877,145.4018v.4785H79.7051a.8827.8827,0,0,1,.0586-.3457,1.8208,1.8208,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5219,5.5219,0,0,0,.8945-.86,1.0271,1.0271,0,0,0,.2325-.5986.6671.6671,0,0,0-.212-.5.7631.7631,0,0,0-.5522-.2031.7761.7761,0,0,0-.5757.2158.8153.8153,0,0,0-.2187.5976l-.5122-.0527a1.2592,1.2592,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8027,1.2158,1.2158,0,0,1-.1.4786,1.8585,1.8585,0,0,1-.3311.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.6253,1.6253,0,0,0-.21.27Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C63'
              ? 'white-text'
              : elementOnHover === 'C63'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 63)}
          onMouseOver={() => setElementOnHover('C63')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-7"
            d="M78.918,129.1566l-.4956.0391a.94.94,0,0,0-.188-.4267.6609.6609,0,0,0-.4985-.2129.6829.6829,0,0,0-.418.1328,1.1376,1.1376,0,0,0-.3711.5009,2.6266,2.6266,0,0,0-.1411.9385,1.1581,1.1581,0,0,1,.44-.4072,1.1912,1.1912,0,0,1,.5454-.1328,1.1276,1.1276,0,0,1,.8482.3672,1.316,1.316,0,0,1,.35.9482,1.5613,1.5613,0,0,1-.1646.71,1.1983,1.1983,0,0,1-.4526.5019,1.2366,1.2366,0,0,1-.6533.1748,1.2747,1.2747,0,0,1-1.0156-.458,2.3124,2.3124,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0214-.4658,1.087,1.087,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1566,1.1566,0,0,0,.1094.4922.8115.8115,0,0,0,.3057.3584.7657.7657,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8733.8733,0,0,0,76.8833,130.9066Z"
          />
          <path
            className="cls-7"
            d="M79.772,131.15l.4981-.0664a1.1,1.1,0,0,0,.292.61.72.72,0,0,0,.5024.1865.8058.8058,0,0,0,.5937-.2432.82.82,0,0,0,.2422-.6035.7656.7656,0,0,0-.2241-.5664.7751.7751,0,0,0-.57-.2227,1.4235,1.4235,0,0,0-.3516.0557l.0557-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5675.5675,0,0,0,.2549-.5118.6072.6072,0,0,0-.1855-.4541.658.658,0,0,0-.479-.18.6782.6782,0,0,0-.4844.1826.921.921,0,0,0-.249.5478l-.4981-.0879a1.2844,1.2844,0,0,1,.4151-.7773,1.2,1.2,0,0,1,.8056-.2754,1.3326,1.3326,0,0,1,.6118.1426,1.0369,1.0369,0,0,1,.4278.39.9972.9972,0,0,1,.1479.5225.858.858,0,0,1-.1411.48.9672.9672,0,0,1-.418.3428.9457.9457,0,0,1,.5591.3447,1.0507,1.0507,0,0,1,.1992.6553,1.1973,1.1973,0,0,1-.3877.9,1.36,1.36,0,0,1-.98.37,1.2767,1.2767,0,0,1-.8872-.3184A1.2339,1.2339,0,0,1,79.772,131.15Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_02_krs_C64'
              ? 'white-text'
              : elementOnHover === 'C64'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(2, 64)}
          onMouseOver={() => setElementOnHover('C64')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-7"
            d="M78.918,115.7523l-.4956.0391a.94.94,0,0,0-.188-.4267.6612.6612,0,0,0-.4985-.2129.6829.6829,0,0,0-.418.1328,1.1373,1.1373,0,0,0-.3711.501,2.6256,2.6256,0,0,0-.1411.9384,1.1581,1.1581,0,0,1,.44-.4072,1.1912,1.1912,0,0,1,.5454-.1328,1.1276,1.1276,0,0,1,.8482.3672,1.316,1.316,0,0,1,.35.9482,1.5613,1.5613,0,0,1-.1646.71,1.1983,1.1983,0,0,1-.4526.5019,1.2366,1.2366,0,0,1-.6533.1748,1.2747,1.2747,0,0,1-1.0156-.458,2.3124,2.3124,0,0,1-.3931-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.248,1.248,0,0,1,1.0214-.4658,1.087,1.087,0,0,1,1.1514,1.0107Zm-2.0347,1.75a1.1566,1.1566,0,0,0,.1094.4922.8115.8115,0,0,0,.3057.3584.7657.7657,0,0,0,.4126.1231.705.705,0,0,0,.5425-.2539,1.003,1.003,0,0,0,.227-.6924.9384.9384,0,0,0-.2241-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8733.8733,0,0,0,76.8833,117.5023Z"
          />
          <path
            className="cls-7"
            d="M81.3662,118.8168v-.9717H79.6055v-.457l1.8521-2.63h.4072v2.63h.5478v.457h-.5478v.9717Zm0-1.4287V115.559l-1.2705,1.8291Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 6 ? (
          <g id="A6-2" data-name="A6">
            <path
              id="A6cone"
              className="cls-9"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A6-3"
              data-name="A6"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 7 ? (
          <g id="A7-2" data-name="A7">
            <path
              id="A7cone"
              className="cls-11"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A7-3"
              data-name="A7"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 8 ? (
          <g id="A8-2" data-name="A8">
            <path
              id="A8cone"
              className="cls-12"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A8-3"
              data-name="A8"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 9 ? (
          <g id="A9-2" data-name="A9">
            <path
              id="A9cone"
              className="cls-13"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A9-3"
              data-name="A9"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 10 ? (
          <g id="A10-2" data-name="A10">
            <path
              id="A10cone"
              className="cls-14"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A10-3"
              data-name="A10"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 36 ? (
          <g id="B36-2" data-name="B36">
            <path
              id="B36cone"
              className="cls-15"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B36-3"
              data-name="B36"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 37 ? (
          <g id="B37-2" data-name="B37">
            <path
              id="B37cone"
              className="cls-16"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B37-3"
              data-name="B37"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 38 ? (
          <g id="B38-2" data-name="B38">
            <path
              id="B38cone"
              className="cls-17"
              d="M88.1952,73.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B38-3"
              data-name="B38"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 59 ? (
          <g id="C59-2" data-name="C59">
            <path
              id="C59cone"
              className="cls-18"
              d="M88.1952,103.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C59-3"
              data-name="C59"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 60 ? (
          <g id="C60-2" data-name="C60">
            <path
              id="C60cone"
              className="cls-19"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C60-3"
              data-name="C60"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 61 ? (
          <g id="C61-2" data-name="C61">
            <path
              id="C61cone"
              className="cls-20"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C61-3"
              data-name="C61"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 62 ? (
          <g id="C62-2" data-name="C62">
            <path
              id="C62cone"
              className="cls-21"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C62-3"
              data-name="C62"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 63 ? (
          <g id="C63-2" data-name="C63">
            <path
              id="C63cone"
              className="cls-22"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C63-3"
              data-name="C63"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 64 ? (
          <g id="C64-2" data-name="C64">
            <path
              id="C64cone"
              className="cls-23"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C64-3"
              data-name="C64"
              className="cls-10"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-7"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_2-2" data-name="2">
          <path
            className="cls-8"
            d="M174.29,85.5009v.7182h-4.0235a1.3208,1.3208,0,0,1,.087-.519,2.7509,2.7509,0,0,1,.4921-.81,7.6989,7.6989,0,0,1,.9776-.9218,8.2326,8.2326,0,0,0,1.3408-1.29,1.5383,1.5383,0,0,0,.35-.8989,1,1,0,0,0-.3183-.7495,1.1447,1.1447,0,0,0-.8282-.3052,1.1659,1.1659,0,0,0-.8633.3242,1.2257,1.2257,0,0,0-.3281.8965l-.7685-.0786a1.8926,1.8926,0,0,1,.5937-1.31,2.0278,2.0278,0,0,1,1.3828-.4507,1.9289,1.9289,0,0,1,1.3867.4859,1.5937,1.5937,0,0,1,.5108,1.2041,1.8247,1.8247,0,0,1-.1494.7182,2.781,2.781,0,0,1-.4961.7437,12.1329,12.1329,0,0,1-1.1524,1.0713q-.6723.5648-.8632.7661a2.4144,2.4144,0,0,0-.3155.4048Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-7"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-7"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  6: [54.848, 7.0884],
  7: [36.4846, 57.3091],
  8: [63.4377, 57.3091],
  9: [77.9525, 57.3091],
  10: [82.3715, 7.0884],
  36: [135.7828, 36.2417],
  37: [135.7828, 72.5363],
  38: [92.7952, 70.5363],
  59: [92.7952, 101.0066],
  60: [135.7828, 96.0066],
  61: [135.7828, 144.7428],
  62: [56.9873, 143.5353],
  63: [56.9873, 130.4153],
  64: [56.9873, 117.0263],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3 {
    fill: #3d3d3b;
  }
  .cls-4,
  .cls-5,
  .cls-6 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-4 {
    stroke-width: 0.8504px;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    stroke-width: 1.4173px;
  }
  .cls-7 {
    fill: #3f3f3c;
  }
  .cls-8 {
    fill: #d3d2cf;
  }
  .cls-9 {
    fill: url(#linear-gradient);
  }
  .cls-10 {
    fill: #c65805;
  }
  .cls-11 {
    fill: url(#linear-gradient-2);
  }
  .cls-12 {
    fill: url(#linear-gradient-3);
  }
  .cls-13 {
    fill: url(#linear-gradient-4);
  }
  .cls-14 {
    fill: url(#linear-gradient-5);
  }
  .cls-15 {
    fill: url(#linear-gradient-6);
  }
  .cls-16 {
    fill: url(#linear-gradient-7);
  }
  .cls-17 {
    fill: url(#linear-gradient-8);
  }
  .cls-18 {
    fill: url(#linear-gradient-9);
  }
  .cls-19 {
    fill: url(#linear-gradient-10);
  }
  .cls-20 {
    fill: url(#linear-gradient-11);
  }
  .cls-21 {
    fill: url(#linear-gradient-12);
  }
  .cls-22 {
    fill: url(#linear-gradient-13);
  }
  .cls-23 {
    fill: url(#linear-gradient-14);
  }

  #A6,
  #A7,
  #A8,
  #A9,
  #A10,
  #B36,
  #B37,
  #B38,
  #C59,
  #C60,
  #C61,
  #C62,
  #C63,
  #C64 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-7 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
