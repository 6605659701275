import * as React from 'react'
import styled from 'styled-components'

export default function Floor8({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_8"
      data-name="8"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="189.2856"
          y1="267.4882"
          x2="189.2856"
          y2="222.5782"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="213.8543"
          y1="273.003"
          x2="213.8543"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="270.9504"
          y1="239.1951"
          x2="270.9504"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(8, 54)}
          onMouseOver={() => setElementOnHover('B54')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B54"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_08_krs_B54' ||
              sceneName === 'scene_Otavallanhovi_08_krs_B54_terrace'
                ? 'active-apt'
                : elementOnHover === 'B54'
                ? 'on-hover'
                : ''
            }`}
            points="76.214 30.364 76.214 52.13 95.776 52.13 95.573 39.667 108.634 39.715 108.634 50.74 103.348 50.74 103.348 55.713 95.637 55.605 95.776 52.13 87.471 52.13 87.471 77.527 122.718 78.015 122.569 30.202 76.214 30.364"
          />
        </g>
        <g
          onClick={() => changeView(8, `${54}small`)}
          onMouseOver={() => setElementOnHover('B54small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C91"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_08_krs_B54_small'
                ? 'active-apt'
                : elementOnHover === 'B54small'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 108.655 109.277 108.82 134.819 122.717 134.79 122.503 92.111 122.503 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(8, `${54}terrace`)}
          onMouseOver={() => setElementOnHover('B54terrace')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="B54_terrace"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_08_krs_B54_terrace'
                ? 'active-apt'
                : elementOnHover === 'B54terrace'
                ? 'on-hover'
                : ''
            }`}
            x="76.4276"
            y="30.3644"
            width="18.3134"
            height="21.7652"
          />
        </g>
        <g
          onClick={() => changeView(8, `${92}small`)}
          onMouseOver={() => setElementOnHover('C92small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C92small"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_08_krs_C92_small'
                ? 'active-apt'
                : elementOnHover === 'C92small'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.1984"
            width="8.5454"
            height="14.0142"
          />
        </g>
        <g
          onClick={() => changeView(8, 92)}
          onMouseOver={() => setElementOnHover('C92')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C92"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_08_krs_C92' ||
              sceneName === 'scene_Otavallanhovi_08_krs_C92_small'
                ? 'active-apt'
                : elementOnHover === 'C92'
                ? 'on-hover'
                : ''
            }`}
            points="92.944 108.533 54.177 108.533 54.177 137.198 62.714 137.3 62.41 151.213 114.341 151.213 114.341 135.157 108.82 134.819 108.838 127.956 92.944 127.956 92.944 108.533"
          />
        </g>
      </g>
      <g id="levelfloor">
        <line
          className="cls-3"
          x1="92.8857"
          y1="128.3815"
          x2="92.8857"
          y2="108.5333"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 108.82 134.819 113.96 134.819"
        />
        <polyline
          className="cls-3"
          points="95.994 55.713 103.348 55.713 103.348 50.74"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 52.13 95.028 52.13"
        />
        <polyline
          className="cls-5"
          points="87.471 52.13 76.214 52.13 76.214 30.364 95.028 30.364"
        />
        <path
          className="cls-4"
          d="M122.2426,31.599l-.0616,102.7286-7.3034.0188-1.4137.0036v15.864H63.3133V109.9506H96.36V31.599h25.883m1.4181-1.4174H94.9423v78.3517H61.896v43.098h52.9853V135.7637l8.7161-.0224.0633-105.56Z"
        />
        <line
          className="cls-3"
          x1="122.7179"
          y1="77.5903"
          x2="87.4713"
          y2="77.5903"
        />
        <line
          className="cls-3"
          x1="108.8377"
          y1="127.9563"
          x2="93.005"
          y2="127.9563"
        />
        <polyline
          className="cls-3"
          points="108.634 44.618 108.634 39.715 95.965 39.715"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_08_krs_B54' ||
            sceneName === 'scene_Otavallanhovi_08_krs_B54_terrace'
              ? 'white-text'
              : elementOnHover === 'B54'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, 54)}
          onMouseOver={() => setElementOnHover('B54')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5816,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0025,1.0025,0,0,1,.1591.5351.9235.9235,0,0,1-.1411.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M107.4668,67.2309l.523-.044a.9377.9377,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.795.795,0,0,0,.6089-.27,1.0221,1.0221,0,0,0,.2495-.72.9242.9242,0,0,0-.24-.6729.8366.8366,0,0,0-.627-.247.8761.8761,0,0,0-.4345.1093.851.851,0,0,0-.3047.2842l-.4678-.06.3931-2.085h2.018v.4766h-1.6191l-.2187,1.0908a1.32,1.32,0,0,1,.7666-.2549,1.212,1.212,0,0,1,.8969.3682,1.2846,1.2846,0,0,1,.3653.9463,1.4788,1.4788,0,0,1-.3208.9521,1.2857,1.2857,0,0,1-1.066.4932,1.311,1.311,0,0,1-.9038-.3106A1.2078,1.2078,0,0,1,107.4668,67.2309Z"
          />
          <path
            className="cls-6"
            d="M112.5005,68.2943v-.9716H110.74v-.4571l1.852-2.63h.4073v2.63h.5478v.4571h-.5478v.9716Zm0-1.4287V65.0365l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-8 ${
            sceneName === 'scene_Otavallanhovi_08_krs_B54_small'
              ? 'white-text'
              : elementOnHover === 'B54small'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(8, `${54}small`)}
          onMouseOver={() => setElementOnHover('B54small')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M106.8628,100.7582l.5371.1358a1.8066,1.8066,0,0,1-.6079,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.0158,2.0158,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.4213,2.4213,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,106.8628,100.7582Z"
          />
          <path
            className="cls-6"
            d="M108.2173,101.2426l.479-.044a.83.83,0,0,0,.2324.4893.6381.6381,0,0,0,.44.1523.7656.7656,0,0,0,.4028-.1045.8614.8614,0,0,0,.2837-.2812,1.6543,1.6543,0,0,0,.1856-.4746,2.5035,2.5035,0,0,0,.0747-.6094c0-.0224-.001-.0557-.0025-.1a1.1438,1.1438,0,0,1-.4087.3867,1.1084,1.1084,0,0,1-.5605.1475,1.1348,1.1348,0,0,1-.8526-.3653,1.3359,1.3359,0,0,1-.3486-.9628,1.369,1.369,0,0,1,.3638-.9942,1.2167,1.2167,0,0,1,.9121-.3769,1.2986,1.2986,0,0,1,.7241.2138,1.3279,1.3279,0,0,1,.4981.6075,2.9857,2.9857,0,0,1,.17,1.1416,3.6941,3.6941,0,0,1-.169,1.2392,1.4472,1.4472,0,0,1-.5024.7012,1.2963,1.2963,0,0,1-.7818.2412,1.0728,1.0728,0,0,1-1.1406-1.0078Zm2.04-1.791a.9762.9762,0,0,0-.2281-.6807.7159.7159,0,0,0-.55-.2519.7572.7572,0,0,0-.5786.2714,1.006,1.006,0,0,0-.2461.7032.8659.8659,0,0,0,.2339.6289.7679.7679,0,0,0,.5772.2422.7367.7367,0,0,0,.5688-.2422A.9532.9532,0,0,0,110.2574,99.4516Z"
          />
          <path
            className="cls-6"
            d="M113.4561,102.1811h-.4985V99.0063a2.5945,2.5945,0,0,1-.4717.3427,3.3951,3.3951,0,0,1-.5249.2578v-.4814a3.0244,3.0244,0,0,0,.7309-.4766,1.8734,1.8734,0,0,0,.4429-.5429h.3213Z"
          />
        </g>
        {apartmentNumber === `${92}small` ? (
          <g
            id="C92small-2"
            data-name="C92small"
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_08_krs_C92_small'
                ? 'white-text'
                : elementOnHover === 'C92small'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, `${92}small`)}
            onMouseOver={() => setElementOnHover('C92small')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M84.0332,131.8451l.5372.1358a1.8074,1.8074,0,0,1-.608,1.0088,1.675,1.675,0,0,1-1.0727.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3934,2.3934,0,0,1,.2421-1.1055,1.6635,1.6635,0,0,1,.69-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6617,1.6617,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2535,1.2535,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3318,1.3318,0,0,0,84.0332,131.8451Z"
            />
            <path
              className="cls-6"
              d="M85.3877,132.33l.479-.0439a.8292.8292,0,0,0,.2325.4892.6377.6377,0,0,0,.44.1524.7666.7666,0,0,0,.4028-.1045.8627.8627,0,0,0,.2837-.2813,1.6514,1.6514,0,0,0,.1856-.4746,2.5029,2.5029,0,0,0,.0747-.6094c0-.0224-.001-.0556-.0025-.1a1.1438,1.1438,0,0,1-.4087.3867,1.1084,1.1084,0,0,1-.5605.1475,1.1347,1.1347,0,0,1-.8525-.3652,1.3359,1.3359,0,0,1-.3487-.9629,1.3691,1.3691,0,0,1,.3638-.9942,1.2171,1.2171,0,0,1,.9121-.3769,1.2986,1.2986,0,0,1,.7241.2138,1.3279,1.3279,0,0,1,.4981.6075,2.9861,2.9861,0,0,1,.17,1.1416,3.6941,3.6941,0,0,1-.169,1.2392,1.4465,1.4465,0,0,1-.5024.7012,1.2962,1.2962,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1407-1.0078Zm2.04-1.791a.9762.9762,0,0,0-.2281-.6807.7159.7159,0,0,0-.55-.2519.7572.7572,0,0,0-.5786.2714,1.006,1.006,0,0,0-.2461.7032.8659.8659,0,0,0,.2339.6289.7679.7679,0,0,0,.5772.2422.7367.7367,0,0,0,.5688-.2422A.9527.9527,0,0,0,87.4278,130.5385Z"
            />
            <path
              className="cls-6"
              d="M91.3682,132.79v.4785H88.6856a.8827.8827,0,0,1,.0586-.3457,1.8187,1.8187,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5262,5.5262,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7627.7627,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8153.8153,0,0,0-.2188.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3512,1.3512,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0628,1.0628,0,0,1,.3408.8027,1.2149,1.2149,0,0,1-.1.4785,1.8543,1.8543,0,0,1-.331.4961,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.62,1.62,0,0,0-.21.27Z"
            />
          </g>
        ) : (
          <g
            className={`cls-8 ${
              sceneName === 'scene_Otavallanhovi_08_krs_C92'
                ? 'white-text'
                : elementOnHover === 'C92'
                ? 'white-text'
                : ''
            }`}
            onClick={() => changeView(8, 92)}
            onMouseOver={() => setElementOnHover('C92')}
            onMouseLeave={() => setElementOnHover('')}
          >
            <path
              className="cls-6"
              d="M84.0332,131.8451l.5372.1358a1.8074,1.8074,0,0,1-.608,1.0088,1.675,1.675,0,0,1-1.0727.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3934,2.3934,0,0,1,.2421-1.1055,1.6635,1.6635,0,0,1,.69-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6617,1.6617,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2535,1.2535,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3318,1.3318,0,0,0,84.0332,131.8451Z"
            />
            <path
              className="cls-6"
              d="M85.3877,132.33l.479-.0439a.8292.8292,0,0,0,.2325.4892.6377.6377,0,0,0,.44.1524.7666.7666,0,0,0,.4028-.1045.8627.8627,0,0,0,.2837-.2813,1.6514,1.6514,0,0,0,.1856-.4746,2.5029,2.5029,0,0,0,.0747-.6094c0-.0224-.001-.0556-.0025-.1a1.1438,1.1438,0,0,1-.4087.3867,1.1084,1.1084,0,0,1-.5605.1475,1.1347,1.1347,0,0,1-.8525-.3652,1.3359,1.3359,0,0,1-.3487-.9629,1.3691,1.3691,0,0,1,.3638-.9942,1.2171,1.2171,0,0,1,.9121-.3769,1.2986,1.2986,0,0,1,.7241.2138,1.3279,1.3279,0,0,1,.4981.6075,2.9861,2.9861,0,0,1,.17,1.1416,3.6941,3.6941,0,0,1-.169,1.2392,1.4465,1.4465,0,0,1-.5024.7012,1.2962,1.2962,0,0,1-.7817.2412,1.0727,1.0727,0,0,1-1.1407-1.0078Zm2.04-1.791a.9762.9762,0,0,0-.2281-.6807.7159.7159,0,0,0-.55-.2519.7572.7572,0,0,0-.5786.2714,1.006,1.006,0,0,0-.2461.7032.8659.8659,0,0,0,.2339.6289.7679.7679,0,0,0,.5772.2422.7367.7367,0,0,0,.5688-.2422A.9527.9527,0,0,0,87.4278,130.5385Z"
            />
            <path
              className="cls-6"
              d="M91.3682,132.79v.4785H88.6856a.8827.8827,0,0,1,.0586-.3457,1.8187,1.8187,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5262,5.5262,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7627.7627,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8153.8153,0,0,0-.2188.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3512,1.3512,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0628,1.0628,0,0,1,.3408.8027,1.2149,1.2149,0,0,1-.1.4785,1.8543,1.8543,0,0,1-.331.4961,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.62,1.62,0,0,0-.21.27Z"
            />
          </g>
        )}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_8-2" data-name="8">
          <path
            className="cls-7"
            d="M171.5118,82.9179a1.4074,1.4074,0,0,1-.6895-.4859,1.2686,1.2686,0,0,1-.2236-.7553,1.4785,1.4785,0,0,1,.4775-1.1172,2.01,2.01,0,0,1,2.5537.01,1.5013,1.5013,0,0,1,.4854,1.1275,1.2468,1.2468,0,0,1-.2217.7373,1.4009,1.4009,0,0,1-.6748.4834,1.5511,1.5511,0,0,1,1.1455,1.5615,1.7481,1.7481,0,0,1-.5518,1.312,2.2494,2.2494,0,0,1-2.9072-.0019,1.7749,1.7749,0,0,1-.5517-1.3311,1.6111,1.6111,0,0,1,.3007-.9941A1.5105,1.5105,0,0,1,171.5118,82.9179Zm-.39,1.5449a1.3458,1.3458,0,0,0,.1514.6187,1.0669,1.0669,0,0,0,.45.4628,1.3165,1.3165,0,0,0,.6435.1641,1.2054,1.2054,0,0,0,.8848-.3447,1.1788,1.1788,0,0,0,.3486-.876,1.1987,1.1987,0,0,0-.3594-.8926,1.23,1.23,0,0,0-.8984-.353,1.1885,1.1885,0,0,0-1.2207,1.2207Zm.24-2.811a.9468.9468,0,0,0,.2784.7055.9849.9849,0,0,0,.7226.2744.9676.9676,0,0,0,.708-.2719.9026.9026,0,0,0,.2764-.6665.9325.9325,0,0,0-.2852-.6914.9673.9673,0,0,0-.708-.28.9786.9786,0,0,0-.71.2739A.8812.8812,0,0,0,171.3623,81.6518Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up">
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === `${54}terrace` ? (
          <g id="B54">
            <path
              id="B54cone"
              className="cls-8"
              d="M80.68,44.622a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92L35.79,19.352v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="B54-2"
              data-name="B54"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 54 ? (
          <g id="B54-3" data-name="B54">
            <path
              id="B54cone-2"
              data-name="B54cone"
              className="cls-10"
              d="M86.1952,69.1907a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="B54-4"
              data-name="B54"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === `${92}small` ? (
          <g id="C92small-3" data-name="C92small">
            <path
              id="C92smallcone"
              className="cls-11"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="C92small-4"
              data-name="C92small"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 92 ? (
          <g id="C92-2" data-name="C92">
            <path
              id="C92cone"
              className="cls-12"
              d="M52.3872,126.2869a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="C92-3"
              data-name="C92"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === `${54}small` ? (
          <g id="C91">
            <path
              id="C91cone"
              className="cls-13"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]}) `}
            />
            <circle
              id="C91-2"
              data-name="C91"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
    </SVG>
  )
}

const pivots = {
  '54terrace': [85.2804, 41.852],
  54: [90.7952, 66.4207],
  '92small': [56.9873, 143.5353],
  92: [56.9873, 123.5169],
  '54small': [90.7952, 86.0066],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }

  #B54,
  #C92,
  #C92small {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #up:hover {
    cursor: not-allowed;

    .cls-1 {
      fill: var(--yit-grey);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  #down:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
