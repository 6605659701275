import * as React from 'react'
import styled from 'styled-components'

export default function Floor5({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="_5"
      data-name="5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 210.4068 162"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="154.522"
          y1="237.0559"
          x2="154.522"
          y2="192.1459"
          gradientTransform="matrix(0, 1, 1, 0, -186.7878, -148.0386)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="204.7427"
          y1="218.6925"
          x2="204.7427"
          y2="173.7824"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="204.7427"
          y1="245.6455"
          x2="204.7427"
          y2="200.7355"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="204.7427"
          y1="260.1603"
          x2="204.7427"
          y2="215.2503"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="154.522"
          y1="264.5794"
          x2="154.522"
          y2="219.6694"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="183.6753"
          y1="317.9906"
          x2="183.6753"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="219.9698"
          y1="317.9906"
          x2="219.9698"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="219.9698"
          y1="273.003"
          x2="219.9698"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="233.4401"
          y1="273.003"
          x2="233.4401"
          y2="228.093"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="243.4401"
          y1="317.9906"
          x2="243.4401"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="292.1763"
          y1="317.9906"
          x2="292.1763"
          y2="273.0806"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="290.9689"
          y1="239.1951"
          x2="290.9689"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="277.8489"
          y1="239.1951"
          x2="277.8489"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="264.4599"
          y1="239.1951"
          x2="264.4599"
          y2="194.2851"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="210.4068" height="162" />
        </g>
      </g>
      <g id="apartments">
        <g
          onClick={() => changeView(5, 21)}
          onMouseOver={() => setElementOnHover('A21')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A21"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_A21'
                ? 'active-apt'
                : elementOnHover === 'A21'
                ? 'on-hover'
                : ''
            }`}
            points="42.915 9.837 42.915 23.784 67.917 23.784 67.917 9.837 59.107 9.837 59.107 7.088 50.516 7.088 50.516 9.837 42.915 9.837"
          />
        </g>
        <g
          onClick={() => changeView(5, 22)}
          onMouseOver={() => setElementOnHover('A22')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A22"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_A22'
                ? 'active-apt'
                : elementOnHover === 'A22'
                ? 'on-hover'
                : ''
            }`}
            points="23.779 9.837 23.779 60.537 47.351 60.537 47.096 52.521 45.126 52.276 45.126 23.784 42.915 23.784 42.915 9.837 23.779 9.837"
          />
        </g>
        <g
          onClick={() => changeView(5, 23)}
          onMouseOver={() => setElementOnHover('A23')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A23"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_A23'
                ? 'active-apt'
                : elementOnHover === 'A23'
                ? 'on-hover'
                : ''
            }`}
            points="47.351 60.537 47.351 52.305 45.126 52.305 45.126 34.495 73.529 34.495 73.529 52.453 71.488 52.453 71.488 60.423 47.351 60.537"
          />
        </g>
        <g
          onClick={() => changeView(5, 24)}
          onMouseOver={() => setElementOnHover('A24')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A24"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_A24'
                ? 'active-apt'
                : elementOnHover === 'A24'
                ? 'on-hover'
                : ''
            }`}
            points="95.748 52.453 95.748 23.784 80.333 23.784 80.333 29.838 73.493 29.838 73.493 52.453 71.488 52.453 71.488 60.423 85.136 60.423 85.136 52.305 95.748 52.453"
          />
        </g>
        <g
          onClick={() => changeView(5, 25)}
          onMouseOver={() => setElementOnHover('A25')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A25"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_A25'
                ? 'active-apt'
                : elementOnHover === 'A25'
                ? 'on-hover'
                : ''
            }`}
            points="67.917 9.837 67.917 23.784 95.873 23.784 95.873 9.837 86.583 9.837 86.583 7.088 78.086 7.088 78.086 9.837 67.917 9.837"
          />
        </g>
        <g
          onClick={() => changeView(5, 45)}
          onMouseOver={() => setElementOnHover('B45')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B45"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_B45'
                ? 'active-apt'
                : elementOnHover === 'B45'
                ? 'on-hover'
                : ''
            }`}
            points="95.873 9.837 95.873 39.77 114.376 39.749 114.376 44.171 138.752 44.171 138.752 10.038 95.873 9.837"
          />
        </g>
        <g
          onClick={() => changeView(5, 46)}
          onMouseOver={() => setElementOnHover('B46')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B46"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_B46'
                ? 'active-apt'
                : elementOnHover === 'B46'
                ? 'on-hover'
                : ''
            }`}
            points="114.029 77.319 138.665 77.319 138.665 44.618 114.29 44.618 114.029 77.319"
          />
        </g>
        <g
          onClick={() => changeView(5, 47)}
          onMouseOver={() => setElementOnHover('B47')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="B47"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_B47'
                ? 'active-apt'
                : elementOnHover === 'B47'
                ? 'on-hover'
                : ''
            }`}
            points="95.671 55.713 95.671 67.625 87.471 67.625 87.471 77.527 114.525 78.015 114.376 55.551 95.671 55.713"
          />
        </g>
        <g
          onClick={() => changeView(5, 77)}
          onMouseOver={() => setElementOnHover('C77')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C77"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C77'
                ? 'active-apt'
                : elementOnHover === 'C77'
                ? 'on-hover'
                : ''
            }`}
            points="87.471 77.684 87.471 95.072 95.79 95.072 95.79 109.277 115.219 109.277 115.219 91.798 137.989 92.111 137.989 77.545 87.471 77.684"
          />
        </g>
        <g
          onClick={() => changeView(5, 78)}
          onMouseOver={() => setElementOnHover('C78')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C78"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C78'
                ? 'active-apt'
                : elementOnHover === 'C78'
                ? 'on-hover'
                : ''
            }`}
            points="138.567 91.798 138.567 117.543 114.975 117.543 115.219 91.798 138.567 91.798"
          />
        </g>
        <g
          onClick={() => changeView(5, 79)}
          onMouseOver={() => setElementOnHover('C79')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C79"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C79'
                ? 'active-apt'
                : elementOnHover === 'C79'
                ? 'on-hover'
                : ''
            }`}
            points="99.053 151.213 99.053 127.956 114.843 127.956 114.843 117.543 138.567 117.543 138.567 151.296 99.053 151.213"
          />
        </g>
        <g
          onClick={() => changeView(5, 80)}
          onMouseOver={() => setElementOnHover('C80')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <rect
            id="C80"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C80'
                ? 'active-apt'
                : elementOnHover === 'C80'
                ? 'on-hover'
                : ''
            }`}
            x="54.1772"
            y="137.3107"
            width="44.8759"
            height="13.9019"
          />
        </g>
        <g
          onClick={() => changeView(5, 81)}
          onMouseOver={() => setElementOnHover('C81')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C81"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C81'
                ? 'active-apt'
                : elementOnHover === 'C81'
                ? 'on-hover'
                : ''
            }`}
            points="92.886 137.311 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.177 137.198 92.886 137.311"
          />
        </g>
        <g
          onClick={() => changeView(5, 82)}
          onMouseOver={() => setElementOnHover('C82')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="C82"
            className={`cls-2 ${
              sceneName === 'scene_Otavallanhovi_05_krs_C82'
                ? 'active-apt'
                : elementOnHover === 'C82'
                ? 'on-hover'
                : ''
            }`}
            points="54.144 108.956 92.886 108.956 92.886 129.776 86.581 129.776 86.581 123.963 54.177 123.963 54.144 108.956"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polyline
          className="cls-3"
          points="54.177 123.734 61.896 123.734 86.581 123.734 86.581 129.776 92.886 129.776 92.886 137.311 61.896 137.311 54.177 137.311"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="129.7762"
          x2="92.8857"
          y2="108.5333"
        />
        <line
          className="cls-3"
          x1="92.8857"
          y1="137.3107"
          x2="99.0531"
          y2="137.3107"
        />
        <polyline
          className="cls-3"
          points="99.053 151.631 99.053 127.956 115.315 127.956"
        />
        <polyline
          className="cls-3"
          points="95.62 109.364 108.82 109.364 114.975 109.364 114.975 117.38 114.975 127.956"
        />
        <line
          className="cls-3"
          x1="114.9335"
          y1="55.7135"
          x2="95.9935"
          y2="55.7135"
        />
        <polyline
          className="cls-3"
          points="95.98 39.77 114.502 39.77 114.502 44.051 114.502 55.94 114.502 77.49"
        />
        <line
          className="cls-3"
          x1="129.9754"
          y1="44.1089"
          x2="114.5017"
          y2="44.1089"
        />
        <line
          className="cls-3"
          x1="95.8734"
          y1="9.837"
          x2="95.8734"
          y2="52.9855"
        />
        <polyline
          className="cls-3"
          points="95.873 23.715 67.917 23.715 67.917 9.837"
        />
        <polyline
          className="cls-3"
          points="45.316 52.205 45.296 23.715 67.917 23.715"
        />
        <polyline
          className="cls-3"
          points="45.296 23.715 42.915 23.715 42.915 9.837"
        />
        <line
          className="cls-3"
          x1="45.3101"
          y1="34.4952"
          x2="73.5293"
          y2="34.4952"
        />
        <polyline
          className="cls-3"
          points="73.493 52.453 73.493 29.838 80.333 29.838 80.333 23.715"
        />
        <line
          className="cls-3"
          x1="73.4935"
          y1="23.7149"
          x2="73.4935"
          y2="29.8377"
        />
        <rect
          className="cls-3"
          x="101.4914"
          y="44.6183"
          width="7.1426"
          height="6.1222"
        />
        <path
          className="cls-4"
          d="M103.5653,115.5062v5.7827H98.3612v-5.7827h5.2041m.85-.85H97.5108v7.4835h6.9049v-7.4835Z"
        />
        <polyline
          className="cls-5"
          points="61.896 151.49 54.144 151.49 54.144 108.647 61.896 108.647"
        />
        <polyline
          className="cls-5"
          points="23.35 52.985 23.35 60.469 47.351 60.469"
        />
        <polyline
          className="cls-5"
          points="85.136 52.968 85.136 60.469 47.351 60.469"
        />
        <line
          className="cls-3"
          x1="71.4884"
          y1="52.9855"
          x2="71.4884"
          y2="60.4227"
        />
        <line
          className="cls-3"
          x1="47.3372"
          y1="52.9855"
          x2="47.3372"
          y2="60.4227"
        />
        <polyline
          className="cls-5"
          points="50.516 9.837 50.516 7.088 59.107 7.088 59.107 9.768"
        />
        <polyline
          className="cls-5"
          points="78.086 9.837 78.086 7.088 86.583 7.088 86.583 9.837"
        />
        <polyline
          className="cls-5"
          points="95.028 95.072 87.471 95.072 87.471 67.625 95.028 67.625"
        />
        <line
          className="cls-3"
          x1="114.9748"
          y1="117.3803"
          x2="138.3093"
          y2="117.3803"
        />
        <path
          className="cls-4"
          d="M137.4064,10.4876l.0119,17.0265h-7.5529l.0023,1.42.0238,14.2487.0023,1.4111,1.411.0039,6.1006.017V49.26l-.0124,17.5936h-9.0382l.0024,1.42.0139,8.4067.0023,1.415h9.0329V91.4857h-9.0352v10.282l1.42-.0028,7.6143-.0148v35.157l-7.62.0189-1.4138.0035V150.214H63.3133V109.9506H96.36V51.5682H24.5864V10.4876h112.82M138.8227,9.07H23.1691V52.9855H94.9423v55.5478H61.896v43.098h67.8924v-13.288l9.0343-.0224V100.33l-9.0343.0176V92.903h9.0352V76.6775h-9.0352l-.0139-8.4068h9.0349l.0133-19.01v-6.06l-7.5139-.0209-.0237-14.2488h7.5515L138.8227,9.07Z"
        />
        <polyline
          className="cls-5"
          points="129.768 151.462 138.677 151.462 138.677 138.321"
        />
        <line
          className="cls-5"
          x1="138.659"
          y1="100.3794"
          x2="138.659"
          y2="92.1328"
        />
        <line
          className="cls-5"
          x1="138.6654"
          y1="77.3189"
          x2="138.6654"
          y2="68.2707"
        />
        <line
          className="cls-5"
          x1="138.6767"
          y1="43.2003"
          x2="138.6767"
          y2="28.9315"
        />
        <line
          className="cls-3"
          x1="108.8204"
          y1="122.1393"
          x2="108.8204"
          y2="109.3642"
        />
        <polyline
          className="cls-3"
          points="128.82 77.59 95.583 77.59 87.471 77.59"
        />
        <polyline
          className="cls-3"
          points="128.396 91.798 114.975 91.798 114.975 109.364"
        />
      </g>
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_A21'
              ? 'white-text'
              : elementOnHover === 'A21'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 21)}
          onMouseOver={() => setElementOnHover('A21')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M52.7383,18.5937l1.5586-4.0586h.5786l1.6607,4.0586h-.6118l-.4732-1.2295H53.7544l-.4458,1.2295Zm1.1709-1.6661h1.376l-.4238-1.124q-.1934-.5127-.2876-.8418a5.1941,5.1941,0,0,1-.2188.7754Z"
          />
          <path
            className="cls-6"
            d="M59.6656,18.1151v.4786H56.9829a.8842.8842,0,0,1,.0586-.3457,1.8179,1.8179,0,0,1,.3277-.54,5.0976,5.0976,0,0,1,.6518-.6142,5.5279,5.5279,0,0,0,.8946-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7628.7628,0,0,0-.5523-.2032.776.776,0,0,0-.5757.2159.8151.8151,0,0,0-.2187.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.8731,1.351,1.351,0,0,1,.9219-.3007,1.2869,1.2869,0,0,1,.9243.3242,1.0632,1.0632,0,0,1,.3408.8027,1.2149,1.2149,0,0,1-.1.4785,1.8549,1.8549,0,0,1-.3311.4961,7.9858,7.9858,0,0,1-.768.7139c-.2989.251-.4912.4219-.5757.5107a1.629,1.629,0,0,0-.2105.2705Z"
          />
          <path
            className="cls-6"
            d="M62.36,18.5937h-.4985V15.4189a2.5945,2.5945,0,0,1-.4717.3427,3.3951,3.3951,0,0,1-.5249.2578V15.538a3.0244,3.0244,0,0,0,.7309-.4766,1.8734,1.8734,0,0,0,.4429-.5429H62.36Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_A22'
              ? 'white-text'
              : elementOnHover === 'A22'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 22)}
          onMouseOver={() => setElementOnHover('A22')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M30.878,35.4179l1.5586-4.0586h.5786l1.6606,4.0586H34.064l-.4731-1.23H31.8941l-.4458,1.23Zm1.1709-1.666h1.3759l-.4238-1.1241q-.1933-.5127-.2876-.8418a5.1936,5.1936,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M37.8052,34.9394v.4785H35.1226a.8827.8827,0,0,1,.0586-.3457,1.8187,1.8187,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5262,5.5262,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7631.7631,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8153.8153,0,0,0-.2188.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3512,1.3512,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242A1.0628,1.0628,0,0,1,37.8,32.47a1.2158,1.2158,0,0,1-.1.4786,1.8555,1.8555,0,0,1-.331.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5757.5108a1.6253,1.6253,0,0,0-.21.27Z"
          />
          <path
            className="cls-6"
            d="M41.2417,34.9394v.4785H38.5591a.8827.8827,0,0,1,.0586-.3457,1.8191,1.8191,0,0,1,.3277-.54,5.084,5.084,0,0,1,.6518-.6142,5.5219,5.5219,0,0,0,.8945-.86,1.0271,1.0271,0,0,0,.2325-.5986.6671.6671,0,0,0-.212-.5.7629.7629,0,0,0-.5522-.2031.7763.7763,0,0,0-.5757.2158.8153.8153,0,0,0-.2187.5976l-.5122-.0527a1.26,1.26,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8027,1.2158,1.2158,0,0,1-.1.4786,1.8561,1.8561,0,0,1-.3311.496,7.99,7.99,0,0,1-.7681.7139c-.2988.251-.4912.4219-.5756.5108a1.6208,1.6208,0,0,0-.2105.27Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_A23'
              ? 'white-text'
              : elementOnHover === 'A23'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 23)}
          onMouseOver={() => setElementOnHover('A23')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M56.5982,44.9242l1.5586-4.0586h.5786l1.6606,4.0586h-.6118l-.4731-1.23H57.6143l-.4458,1.23Zm1.1709-1.666h1.376l-.4239-1.124q-.1934-.5127-.2876-.8418a5.197,5.197,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M63.5254,44.4457v.4785H60.8428a.883.883,0,0,1,.0586-.3457,1.8191,1.8191,0,0,1,.3276-.54,5.0853,5.0853,0,0,1,.6519-.6143,5.5224,5.5224,0,0,0,.8945-.86,1.0276,1.0276,0,0,0,.2325-.5987.6674.6674,0,0,0-.212-.5.7631.7631,0,0,0-.5522-.2031.7761.7761,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0527a1.26,1.26,0,0,1,.3959-.8731,1.3514,1.3514,0,0,1,.9219-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0631,1.0631,0,0,1,.3409.8028,1.2136,1.2136,0,0,1-.1.4785,1.8558,1.8558,0,0,1-.331.4961,8.0157,8.0157,0,0,1-.7681.7139c-.2988.2509-.4912.4218-.5757.5107a1.6294,1.6294,0,0,0-.21.2705Z"
          />
          <path
            className="cls-6"
            d="M64.3462,43.8529l.4981-.0664a1.1006,1.1006,0,0,0,.292.61.72.72,0,0,0,.5024.1865.8062.8062,0,0,0,.5938-.2432.82.82,0,0,0,.2421-.6035.7658.7658,0,0,0-.2241-.5664.775.775,0,0,0-.57-.2226,1.4213,1.4213,0,0,0-.3515.0556l.0556-.4375a.7172.7172,0,0,0,.08.0059,1.02,1.02,0,0,0,.5727-.167.5674.5674,0,0,0,.2549-.5117.6071.6071,0,0,0-.1855-.4541.6581.6581,0,0,0-.479-.18.6782.6782,0,0,0-.4844.1826.9213.9213,0,0,0-.249.5479l-.4981-.0879a1.2849,1.2849,0,0,1,.4151-.7774,1.2,1.2,0,0,1,.8056-.2754,1.3333,1.3333,0,0,1,.6119.1426,1.0362,1.0362,0,0,1,.4277.39.9966.9966,0,0,1,.1479.5224.8572.8572,0,0,1-.1411.48.9667.9667,0,0,1-.4179.3428.9458.9458,0,0,1,.559.3447,1.05,1.05,0,0,1,.1993.6553,1.1977,1.1977,0,0,1-.3877.9,1.3607,1.3607,0,0,1-.98.37,1.2767,1.2767,0,0,1-.8872-.3184A1.2339,1.2339,0,0,1,64.3462,43.8529Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_A24'
              ? 'white-text'
              : elementOnHover === 'A24'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 24)}
          onMouseOver={() => setElementOnHover('A24')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M81.7788,40.1132l1.5586-4.0586h.5787l1.6606,4.0586h-.6118l-.4732-1.23H82.795l-.4458,1.23Zm1.1709-1.666h1.376l-.4238-1.124q-.1933-.5127-.2876-.8418a5.1963,5.1963,0,0,1-.2188.7753Z"
          />
          <path
            className="cls-6"
            d="M88.7061,39.6347v.4785H86.0235a.8827.8827,0,0,1,.0586-.3457,1.8166,1.8166,0,0,1,.3276-.54,5.0926,5.0926,0,0,1,.6519-.6142,5.5219,5.5219,0,0,0,.8945-.86,1.0269,1.0269,0,0,0,.2324-.5986.6674.6674,0,0,0-.2119-.5.7631.7631,0,0,0-.5522-.2031.776.776,0,0,0-.5757.2158.8156.8156,0,0,0-.2188.5977l-.5122-.0528a1.26,1.26,0,0,1,.396-.873,1.351,1.351,0,0,1,.9219-.3008,1.2868,1.2868,0,0,1,.9243.3242,1.0628,1.0628,0,0,1,.3408.8027,1.2154,1.2154,0,0,1-.1.4786,1.8574,1.8574,0,0,1-.331.4961,8.0169,8.0169,0,0,1-.7681.7138c-.2988.251-.4912.4219-.5757.5108a1.6188,1.6188,0,0,0-.21.2705Z"
          />
          <path
            className="cls-6"
            d="M91.1211,40.1132v-.9717H89.36v-.457l1.852-2.63H91.62v2.63h.5478v.457H91.62v.9717Zm0-1.4287V36.8554l-1.27,1.8291Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_A25'
              ? 'white-text'
              : elementOnHover === 'A25'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 25)}
          onMouseOver={() => setElementOnHover('A25')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M77.2769,18.4667l1.5586-4.0586h.5786l1.6606,4.0586h-.6118L79.99,17.2372H78.293l-.4458,1.2295Zm1.1709-1.666h1.376L79.4,15.6767q-.1934-.5127-.2876-.8418a5.2,5.2,0,0,1-.2187.7754Z"
          />
          <path
            className="cls-6"
            d="M84.2041,17.9882v.4785H81.5215a.883.883,0,0,1,.0586-.3457,1.8175,1.8175,0,0,1,.3277-.54,5.0767,5.0767,0,0,1,.6518-.6143,5.5224,5.5224,0,0,0,.8945-.86,1.0276,1.0276,0,0,0,.2325-.5987.6674.6674,0,0,0-.212-.5.7629.7629,0,0,0-.5522-.2031.7763.7763,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0528a1.26,1.26,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8028,1.2153,1.2153,0,0,1-.1.4785,1.858,1.858,0,0,1-.3311.4961A8.0046,8.0046,0,0,1,83,17.2069c-.2988.251-.4912.4219-.5756.5108a1.6249,1.6249,0,0,0-.2105.27Z"
          />
          <path
            className="cls-6"
            d="M85.022,17.4032l.523-.0439a.9377.9377,0,0,0,.27.5742.73.73,0,0,0,.5107.1924.7954.7954,0,0,0,.6089-.2705,1.0223,1.0223,0,0,0,.2495-.72.9247.9247,0,0,0-.24-.6729.8363.8363,0,0,0-.627-.2471.8749.8749,0,0,0-.4345.1094.85.85,0,0,0-.3047.2842l-.4678-.0606.3931-2.0849h2.018V14.94H85.9024l-.2188,1.0909a1.3192,1.3192,0,0,1,.7666-.2549,1.2121,1.2121,0,0,1,.897.3681,1.2848,1.2848,0,0,1,.3652.9463,1.4785,1.4785,0,0,1-.3208.9522,1.2853,1.2853,0,0,1-1.0659.4931,1.3114,1.3114,0,0,1-.9038-.3105A1.2082,1.2082,0,0,1,85.022,17.4032Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_B45'
              ? 'white-text'
              : elementOnHover === 'B45'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 45)}
          onMouseOver={() => setElementOnHover('B45')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M111.2979,27.7191V23.6606h1.5229a1.8656,1.8656,0,0,1,.7456.124.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9234.9234,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874h1.0107a2.35,2.35,0,0,0,.3653-.02.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7016.7016,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M116.78,27.7191v-.9716H115.02V26.29l1.8525-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V24.4613L115.51,26.29Z"
          />
          <path
            className="cls-6"
            d="M118.62,26.6557l.5224-.044a.9359.9359,0,0,0,.27.5742.7291.7291,0,0,0,.5108.1924.795.795,0,0,0,.6084-.27,1.02,1.02,0,0,0,.25-.72.9225.9225,0,0,0-.24-.6729.8347.8347,0,0,0-.6269-.247.879.879,0,0,0-.4346.1093.8552.8552,0,0,0-.3047.2842l-.4678-.06.3936-2.085h2.0176v.4766H119.5l-.2187,1.0908a1.3206,1.3206,0,0,1,.7666-.2549,1.2143,1.2143,0,0,1,.8975.3682,1.2869,1.2869,0,0,1,.3652.9463,1.4786,1.4786,0,0,1-.3213.9521,1.2839,1.2839,0,0,1-1.0654.4932,1.3121,1.3121,0,0,1-.9043-.3106A1.2073,1.2073,0,0,1,118.62,26.6557Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_B46'
              ? 'white-text'
              : elementOnHover === 'B46'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 46)}
          onMouseOver={() => setElementOnHover('B46')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M121.09,60.3119V56.2533h1.5224a1.87,1.87,0,0,1,.7461.124.9594.9594,0,0,1,.4405.379,1.0068,1.0068,0,0,1,.1591.5351.9237.9237,0,0,1-.1416.49,1.0038,1.0038,0,0,1-.4257.3711,1.0588,1.0588,0,0,1,.5654.3682.9868.9868,0,0,1,.1982.6143,1.1862,1.1862,0,0,1-.12.53,1.0394,1.0394,0,0,1-.2978.378,1.254,1.254,0,0,1-.4444.2011,2.7127,2.7127,0,0,1-.6543.0674Zm.5371-2.3525h.8769a1.9141,1.9141,0,0,0,.5127-.0479.5622.5622,0,0,0,.3086-.2021.5785.5785,0,0,0,.1035-.3535.657.657,0,0,0-.0966-.3565.4916.4916,0,0,0-.2764-.21,2.3075,2.3075,0,0,0-.6182-.0567h-.8105Zm0,1.874h1.0107a2.3543,2.3543,0,0,0,.3653-.02.8815.8815,0,0,0,.3095-.1114.591.591,0,0,0,.2051-.2255.702.702,0,0,0,.08-.3418.679.679,0,0,0-.1162-.3946.6162.6162,0,0,0-.3223-.2353,2.0154,2.0154,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M126.5723,60.3119V59.34h-1.7607v-.457l1.8515-2.63h.4073v2.63h.5478v.457H127.07v.9717Zm0-1.4287V57.0541l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M130.9971,57.2475l-.4961.039a.9348.9348,0,0,0-.1875-.4267.6615.6615,0,0,0-.499-.2129.684.684,0,0,0-.418.1328,1.1416,1.1416,0,0,0-.3711.501,2.6385,2.6385,0,0,0-.1406.9384,1.1581,1.1581,0,0,1,.44-.4072,1.1878,1.1878,0,0,1,.545-.1328,1.1286,1.1286,0,0,1,.8486.3672,1.3156,1.3156,0,0,1,.35.9482,1.5673,1.5673,0,0,1-.1641.71,1.2,1.2,0,0,1-.4531.502,1.2341,1.2341,0,0,1-.6533.1748,1.2749,1.2749,0,0,1-1.0156-.4581,2.3153,2.3153,0,0,1-.3926-1.5107,2.7012,2.7012,0,0,1,.4346-1.71,1.2467,1.2467,0,0,1,1.0214-.4658,1.0878,1.0878,0,0,1,1.1514,1.0108Zm-2.0351,1.75a1.1644,1.1644,0,0,0,.1093.4922.8157.8157,0,0,0,.3057.3584.7681.7681,0,0,0,.4131.123.7047.7047,0,0,0,.542-.2539,1.0032,1.0032,0,0,0,.2275-.6924.9388.9388,0,0,0-.2246-.6631.7381.7381,0,0,0-.5645-.2422.77.77,0,0,0-.5732.2422A.8756.8756,0,0,0,128.962,58.9975Z"
          />
        </g>
        <g
          className={`cls-6 ${
            sceneName === 'scene_Otavallanhovi_05_krs_B47'
              ? 'white-text'
              : elementOnHover === 'B47'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 47)}
          onMouseOver={() => setElementOnHover('B47')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M100.252,68.2943V64.2357h1.5229a1.8654,1.8654,0,0,1,.7456.1241.9586.9586,0,0,1,.4405.3789,1.0026,1.0026,0,0,1,.1592.5351.9236.9236,0,0,1-.1412.49,1.0066,1.0066,0,0,1-.4262.3711,1.06,1.06,0,0,1,.5659.3681.9868.9868,0,0,1,.1977.6143,1.1867,1.1867,0,0,1-.12.53,1.0346,1.0346,0,0,1-.2978.3779,1.2483,1.2483,0,0,1-.4444.2012,2.7154,2.7154,0,0,1-.6543.0673Zm.5371-2.3525h.8774a1.9125,1.9125,0,0,0,.5122-.0478.5611.5611,0,0,0,.3086-.2022.5785.5785,0,0,0,.104-.3535.657.657,0,0,0-.0966-.3565.4931.4931,0,0,0-.2769-.21,2.3056,2.3056,0,0,0-.6177-.0567h-.811Zm0,1.874H101.8a2.35,2.35,0,0,0,.3653-.0195.8826.8826,0,0,0,.31-.1113.5871.5871,0,0,0,.2046-.2256.7019.7019,0,0,0,.0806-.3418.6786.6786,0,0,0-.1162-.3945.6191.6191,0,0,0-.3228-.2354,2.0109,2.0109,0,0,0-.5937-.0674h-.9385Z"
          />
          <path
            className="cls-6"
            d="M105.7344,68.2943v-.9716h-1.7607v-.4571l1.852-2.63h.4072v2.63h.5479v.4571h-.5479v.9716Zm0-1.4287V65.0365l-1.27,1.8291Z"
          />
          <path
            className="cls-6"
            d="M107.607,64.768v-.48h2.6269v.3877a5.5335,5.5335,0,0,0-.768,1.0967,6.65,6.65,0,0,0-.5884,1.4062,5.2252,5.2252,0,0,0-.1909,1.1152h-.5122a4.955,4.955,0,0,1,.1884-1.1572,6.0675,6.0675,0,0,1,.5161-1.3076,5.5926,5.5926,0,0,1,.7154-1.0615Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C77'
              ? 'white-text'
              : elementOnHover === 'C77'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 77)}
          onMouseOver={() => setElementOnHover('C77')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M103.5332,100.7582l.5372.1358a1.8068,1.8068,0,0,1-.608,1.0087,1.6745,1.6745,0,0,1-1.0727.3477,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.76,2.76,0,0,1-.2148-1.0879,2.3931,2.3931,0,0,1,.2421-1.1055,1.6643,1.6643,0,0,1,.69-.7178,2.016,2.016,0,0,1,.9839-.2441,1.6611,1.6611,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.872l-.5288.1241a1.2538,1.2538,0,0,0-.41-.6446,1.0924,1.0924,0,0,0-.6753-.2021,1.3132,1.3132,0,0,0-.7822.2246,1.1823,1.1823,0,0,0-.4414.6016,2.424,2.424,0,0,0-.1274.7793,2.478,2.478,0,0,0,.1509.9043,1.1273,1.1273,0,0,0,.4692.5771,1.3188,1.3188,0,0,0,.6894.19,1.152,1.152,0,0,0,.7637-.26A1.3321,1.3321,0,0,0,103.5332,100.7582Z"
          />
          <path
            className="cls-6"
            d="M104.8462,98.6547v-.48h2.627v.3877a5.5335,5.5335,0,0,0-.7681,1.0967,6.6521,6.6521,0,0,0-.5884,1.4062,5.2324,5.2324,0,0,0-.1909,1.1153h-.5122a4.961,4.961,0,0,1,.1885-1.1573,6.0623,6.0623,0,0,1,.5161-1.3076,5.5925,5.5925,0,0,1,.7153-1.0615Z"
          />
          <path
            className="cls-6"
            d="M108.2828,98.6547v-.48H110.91v.3877a5.5377,5.5377,0,0,0-.7681,1.0967,6.6465,6.6465,0,0,0-.5883,1.4062,5.2323,5.2323,0,0,0-.191,1.1153H108.85a4.961,4.961,0,0,1,.1885-1.1573,6.0623,6.0623,0,0,1,.5161-1.3076,5.588,5.588,0,0,1,.7154-1.0615Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C78'
              ? 'white-text'
              : elementOnHover === 'C78'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 78)}
          onMouseOver={() => setElementOnHover('C78')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M123.4483,107.7777l.5371.1358a1.8079,1.8079,0,0,1-.6084,1.0088,1.6729,1.6729,0,0,1-1.0723.3476,1.9233,1.9233,0,0,1-1.0674-.2676,1.6646,1.6646,0,0,1-.625-.7734,2.7525,2.7525,0,0,1-.2148-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.66,1.66,0,0,1,.6894-.7178,2.0145,2.0145,0,0,1,.9834-.2442,1.6608,1.6608,0,0,1,1.0245.31,1.6111,1.6111,0,0,1,.5791.8721l-.5293.124a1.2536,1.2536,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3151,1.3151,0,0,0-.7822.2246,1.1845,1.1845,0,0,0-.4414.6016,2.4209,2.4209,0,0,0-.127.7793,2.478,2.478,0,0,0,.15.9043,1.1262,1.1262,0,0,0,.47.5771,1.3164,1.3164,0,0,0,.69.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,123.4483,107.7777Z"
          />
          <path
            className="cls-6"
            d="M124.7608,105.6742v-.48h2.6269v.3877a5.5364,5.5364,0,0,0-.7675,1.0967,6.6475,6.6475,0,0,0-.5889,1.4063,5.1937,5.1937,0,0,0-.19,1.1152h-.5127a4.9807,4.9807,0,0,1,.1884-1.1572,6.07,6.07,0,0,1,.5166-1.3077,5.5919,5.5919,0,0,1,.7149-1.0615Z"
          />
          <path
            className="cls-6"
            d="M128.9307,106.9994a.9391.9391,0,0,1-.459-.3232.8491.8491,0,0,1-.1494-.5039.9842.9842,0,0,1,.3184-.7451,1.1788,1.1788,0,0,1,.8466-.3018,1.1911,1.1911,0,0,1,.8555.3086,1.0014,1.0014,0,0,1,.3242.7519.8269.8269,0,0,1-.1484.4913.9292.9292,0,0,1-.45.3222,1.0942,1.0942,0,0,1,.5693.3936,1.08,1.08,0,0,1,.1953.6474,1.1666,1.1666,0,0,1-.3681.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1818,1.1818,0,0,1-.3682-.8877,1.0741,1.0741,0,0,1,.2-.6631A1.0107,1.0107,0,0,1,128.9307,106.9994Zm-.26,1.03a.9.9,0,0,0,.1006.4121.7118.7118,0,0,0,.3008.3086.8728.8728,0,0,0,.4287.1094.8041.8041,0,0,0,.59-.23.7854.7854,0,0,0,.2324-.584.7983.7983,0,0,0-.2393-.5947.8185.8185,0,0,0-.6-.2354.7929.7929,0,0,0-.8135.8135Zm.16-1.874a.6359.6359,0,0,0,.1855.4707.6567.6567,0,0,0,.4815.1826.6448.6448,0,0,0,.4726-.1817.6009.6009,0,0,0,.1836-.4443.62.62,0,0,0-.1894-.4609.6975.6975,0,0,0-.9453-.0039A.59.59,0,0,0,128.8311,106.1557Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C79'
              ? 'white-text'
              : elementOnHover === 'C79'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 79)}
          onMouseOver={() => setElementOnHover('C79')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M115.9463,135.9106l.5371.1357a1.8073,1.8073,0,0,1-.6084,1.0088,1.6728,1.6728,0,0,1-1.0722.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.76,2.76,0,0,1-.2149-1.0879,2.3927,2.3927,0,0,1,.2422-1.1054,1.6637,1.6637,0,0,1,.69-.7178,2.0141,2.0141,0,0,1,.9834-.2441,1.6607,1.6607,0,0,1,1.0244.3095,1.6115,1.6115,0,0,1,.5791.8721l-.5293.124a1.2529,1.2529,0,0,0-.4092-.6445,1.0933,1.0933,0,0,0-.6758-.2022,1.3126,1.3126,0,0,0-.7817.2246,1.1831,1.1831,0,0,0-.4414.6016,2.4217,2.4217,0,0,0-.1274.7793,2.48,2.48,0,0,0,.1508.9043,1.128,1.128,0,0,0,.4693.5771,1.3189,1.3189,0,0,0,.6894.1905,1.153,1.153,0,0,0,.7637-.26A1.3373,1.3373,0,0,0,115.9463,135.9106Z"
          />
          <path
            className="cls-6"
            d="M117.2588,133.807v-.48h2.627v.3877a5.5364,5.5364,0,0,0-.7676,1.0967,6.6527,6.6527,0,0,0-.5889,1.4063,5.1937,5.1937,0,0,0-.19,1.1152h-.5127a4.9869,4.9869,0,0,1,.1885-1.1572,6.062,6.062,0,0,1,.5166-1.3076,5.59,5.59,0,0,1,.7148-1.0616Z"
          />
          <path
            className="cls-6"
            d="M120.7373,136.3949l.4786-.0439a.8312.8312,0,0,0,.2324.4892.639.639,0,0,0,.44.1524.7654.7654,0,0,0,.4024-.1045.8686.8686,0,0,0,.2841-.2813,1.6635,1.6635,0,0,0,.1856-.4746,2.5023,2.5023,0,0,0,.0742-.6093c0-.0225-.001-.0557-.002-.1a1.1461,1.1461,0,0,1-.4091.3868,1.1086,1.1086,0,0,1-.5606.1474,1.1343,1.1343,0,0,1-.8525-.3652,1.3363,1.3363,0,0,1-.3487-.9629,1.3669,1.3669,0,0,1,.3643-.9941,1.2161,1.2161,0,0,1,.9121-.377,1.2974,1.2974,0,0,1,.7236.2139,1.3276,1.3276,0,0,1,.4981.6074,2.9733,2.9733,0,0,1,.1709,1.1416,3.705,3.705,0,0,1-.169,1.2393,1.447,1.447,0,0,1-.5029.7011,1.2929,1.2929,0,0,1-.7812.2412,1.0719,1.0719,0,0,1-1.1407-1.0078Zm2.04-1.791a.9747.9747,0,0,0-.2285-.6807.7158.7158,0,0,0-.55-.2519.7562.7562,0,0,0-.5782.2715,1.0056,1.0056,0,0,0-.2461.7031.8654.8654,0,0,0,.2334.6289.7694.7694,0,0,0,.5772.2422.7376.7376,0,0,0,.5693-.2422A.9526.9526,0,0,0,122.7774,134.6039Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C80'
              ? 'white-text'
              : elementOnHover === 'C80'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 80)}
          onMouseOver={() => setElementOnHover('C80')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,144.4574l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2676,1.6625,1.6625,0,0,1-.6255-.7734,2.7613,2.7613,0,0,1-.2148-1.0879,2.3921,2.3921,0,0,1,.2422-1.1055,1.6633,1.6633,0,0,1,.6894-.7177,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1825,1.1825,0,0,0-.4415.6016,2.424,2.424,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.19,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,144.4574Z"
          />
          <path
            className="cls-6"
            d="M77.0992,143.6791a.9383.9383,0,0,1-.4595-.3232.8493.8493,0,0,1-.1494-.5039.9863.9863,0,0,1,.3183-.7452,1.34,1.34,0,0,1,1.7027.0069,1.001,1.001,0,0,1,.3237.7519.826.826,0,0,1-.1479.4912.932.932,0,0,1-.45.3223,1.0947,1.0947,0,0,1,.5688.3936,1.0761,1.0761,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4993,1.4993,0,0,1-1.9375-.001,1.1832,1.1832,0,0,1-.3682-.8876,1.0712,1.0712,0,0,1,.2007-.6631A1.0107,1.0107,0,0,1,77.0992,143.6791Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7871.7871,0,0,0,.2324-.584.798.798,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,142.8354a.634.634,0,0,0,.1855.4707.6557.6557,0,0,0,.4814.1826.6431.6431,0,0,0,.4722-.1817.6.6,0,0,0,.1841-.4443.622.622,0,0,0-.1895-.4609.6973.6973,0,0,0-.9453-.0039A.5885.5885,0,0,0,77,142.8354Z"
          />
          <path
            className="cls-6"
            d="M79.7691,143.8793a3.7223,3.7223,0,0,1,.1479-1.1592,1.4439,1.4439,0,0,1,.4405-.6767,1.1267,1.1267,0,0,1,.7348-.2383,1.195,1.195,0,0,1,.5728.1318,1.0993,1.0993,0,0,1,.4072.3789,2.0628,2.0628,0,0,1,.252.6035,4.0393,4.0393,0,0,1,.0913.96,3.7093,3.7093,0,0,1-.147,1.1523,1.4448,1.4448,0,0,1-.4385.6788,1.1288,1.1288,0,0,1-.7378.2392,1.1139,1.1139,0,0,1-.9219-.4209A2.6748,2.6748,0,0,1,79.7691,143.8793Zm.5122,0a2.5418,2.5418,0,0,0,.2339,1.33.6686.6686,0,0,0,1.1543-.002,3.8985,3.8985,0,0,0,0-2.66.6939.6939,0,0,0-.583-.3291.6388.6388,0,0,0-.5479.29A2.5749,2.5749,0,0,0,80.2813,143.8793Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C81'
              ? 'white-text'
              : elementOnHover === 'C81'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 81)}
          onMouseOver={() => setElementOnHover('C81')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,130.7982l.5371.1358a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.663,1.663,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.6093,1.6093,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1829,1.1829,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3324,1.3324,0,0,0,75.0528,130.7982Z"
          />
          <path
            className="cls-6"
            d="M77.0992,130.02a.9383.9383,0,0,1-.4595-.3232.8491.8491,0,0,1-.1494-.5039.9861.9861,0,0,1,.3183-.7451,1.34,1.34,0,0,1,1.7027.0068,1.0012,1.0012,0,0,1,.3237.752.8264.8264,0,0,1-.1479.4912.9325.9325,0,0,1-.45.3222,1.0952,1.0952,0,0,1,.5688.3936,1.0763,1.0763,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1833,1.1833,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0118,1.0118,0,0,1,77.0992,130.02Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7869.7869,0,0,0,.2324-.584.7982.7982,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,129.1762a.634.634,0,0,0,.1855.4707.6553.6553,0,0,0,.4814.1826.643.643,0,0,0,.4722-.1816.6.6,0,0,0,.1841-.4444.622.622,0,0,0-.1895-.4609.6975.6975,0,0,0-.9453-.0039A.5887.5887,0,0,0,77,129.1762Z"
          />
          <path
            className="cls-6"
            d="M81.646,132.2211h-.4985v-3.1748a2.5875,2.5875,0,0,1-.4717.3428,3.4,3.4,0,0,1-.5249.2578v-.4815a3.0278,3.0278,0,0,0,.731-.4765,1.8731,1.8731,0,0,0,.4428-.543h.3213Z"
          />
        </g>
        <g
          className={`cls-7 ${
            sceneName === 'scene_Otavallanhovi_05_krs_C82'
              ? 'white-text'
              : elementOnHover === 'C82'
              ? 'white-text'
              : ''
          }`}
          onClick={() => changeView(5, 82)}
          onMouseOver={() => setElementOnHover('C82')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M75.0528,117.394l.5371.1357a1.8073,1.8073,0,0,1-.6079,1.0088,1.6753,1.6753,0,0,1-1.0728.3476,1.9221,1.9221,0,0,1-1.0669-.2675,1.6632,1.6632,0,0,1-.6255-.7735,2.7613,2.7613,0,0,1-.2148-1.0879,2.3914,2.3914,0,0,1,.2422-1.1054,1.6635,1.6635,0,0,1,.6894-.7178,2.015,2.015,0,0,1,.9839-.2442,1.6613,1.6613,0,0,1,1.0244.31,1.61,1.61,0,0,1,.5786.8721l-.5288.124a1.2534,1.2534,0,0,0-.41-.6445,1.0918,1.0918,0,0,0-.6753-.2022,1.313,1.313,0,0,0-.7822.2246,1.1833,1.1833,0,0,0-.4415.6016,2.4245,2.4245,0,0,0-.1274.7793,2.4776,2.4776,0,0,0,.1509.9043,1.1278,1.1278,0,0,0,.4692.5771,1.319,1.319,0,0,0,.69.1905,1.1518,1.1518,0,0,0,.7636-.26A1.3325,1.3325,0,0,0,75.0528,117.394Z"
          />
          <path
            className="cls-6"
            d="M77.0992,116.6156a.9383.9383,0,0,1-.4595-.3232.8491.8491,0,0,1-.1494-.5039.9861.9861,0,0,1,.3183-.7451,1.34,1.34,0,0,1,1.7027.0068,1.0012,1.0012,0,0,1,.3237.752.8264.8264,0,0,1-.1479.4912.9325.9325,0,0,1-.45.3222,1.0952,1.0952,0,0,1,.5688.3936,1.0763,1.0763,0,0,1,.1953.6474,1.1647,1.1647,0,0,1-.3681.875,1.4995,1.4995,0,0,1-1.9375-.0009,1.1833,1.1833,0,0,1-.3682-.8877,1.0712,1.0712,0,0,1,.2007-.6631A1.0118,1.0118,0,0,1,77.0992,116.6156Zm-.26,1.03a.9009.9009,0,0,0,.1011.4121.7106.7106,0,0,0,.3.3086.8761.8761,0,0,0,.4292.1094.8039.8039,0,0,0,.59-.23.7869.7869,0,0,0,.2324-.584.7982.7982,0,0,0-.2392-.5947.82.82,0,0,0-.6-.2354.7929.7929,0,0,0-.814.8135ZM77,115.7719a.634.634,0,0,0,.1855.4707.6553.6553,0,0,0,.4814.1826.643.643,0,0,0,.4722-.1816.6.6,0,0,0,.1841-.4444.622.622,0,0,0-.1895-.4609.6975.6975,0,0,0-.9453-.0039A.5888.5888,0,0,0,77,115.7719Z"
          />
          <path
            className="cls-6"
            d="M82.3877,118.3383v.4785H79.7051a.883.883,0,0,1,.0586-.3457,1.8191,1.8191,0,0,1,.3276-.54,5.0853,5.0853,0,0,1,.6519-.6143,5.5224,5.5224,0,0,0,.8945-.86,1.0276,1.0276,0,0,0,.2325-.5987.6674.6674,0,0,0-.212-.5.7631.7631,0,0,0-.5522-.2031.7761.7761,0,0,0-.5757.2158.8155.8155,0,0,0-.2187.5977l-.5122-.0528a1.2592,1.2592,0,0,1,.396-.873,1.3509,1.3509,0,0,1,.9218-.3008,1.2866,1.2866,0,0,1,.9243.3242,1.0629,1.0629,0,0,1,.3409.8028,1.2153,1.2153,0,0,1-.1.4785,1.86,1.86,0,0,1-.3311.4961,8.0046,8.0046,0,0,1-.7681.7138c-.2988.251-.4912.4219-.5757.5108a1.6294,1.6294,0,0,0-.21.27Z"
          />
        </g>
      </g>
      <g id="view">
        {apartmentNumber === 21 ? (
          <g id="A21-2" data-name="A21">
            <path
              id="A21cone"
              className="cls-8"
              d="M50.248,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A21-3"
              data-name="A21"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 22 ? (
          <g id="A22-2" data-name="A22">
            <path
              id="A22cone"
              className="cls-10"
              d="M31.8846,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A22-3"
              data-name="A22"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 23 ? (
          <g id="A23-2" data-name="A23">
            <path
              id="A23cone"
              className="cls-11"
              d="M58.8377,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A23-3"
              data-name="A23"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 24 ? (
          <g id="A24-2" data-name="A24">
            <path
              id="A24cone"
              className="cls-12"
              d="M73.3525,60.0791a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A24-3"
              data-name="A24"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 25 ? (
          <g id="A25-2" data-name="A25">
            <path
              id="A25cone"
              className="cls-13"
              d="M77.7715,9.8584a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="A25-3"
              data-name="A25"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 45 ? (
          <g id="B45-2" data-name="B45">
            <path
              id="B45cone"
              className="cls-14"
              d="M131.1827,39.0117a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B45-3"
              data-name="B45"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 46 ? (
          <g id="B46-2" data-name="B46">
            <path
              id="B46cone"
              className="cls-15"
              d="M131.1827,75.3063a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B46-3"
              data-name="B46"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 47 ? (
          <g id="B47-2" data-name="B47">
            <path
              id="B47cone"
              className="cls-16"
              d="M86.1952,75.3063a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="B47-3"
              data-name="B47"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 77 ? (
          <g id="C77-2" data-name="C77">
            <path
              id="C77cone"
              className="cls-17"
              d="M86.1952,88.7766a7.4913,7.4913,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C77-3"
              data-name="C77"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 78 ? (
          <g id="C78-2" data-name="C78">
            <path
              id="C78cone"
              className="cls-18"
              d="M131.1827,98.7766a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C78-3"
              data-name="C78"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 79 ? (
          <g id="C79-2" data-name="C79">
            <path
              id="C79cone"
              className="cls-19"
              d="M131.1827,147.5128a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C79-3"
              data-name="C79"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 80 ? (
          <g id="C80-2" data-name="C80">
            <path
              id="C80cone"
              className="cls-20"
              d="M52.3872,146.3053a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C80-3"
              data-name="C80"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 81 ? (
          <g id="C81-2" data-name="C81">
            <path
              id="C81cone"
              className="cls-21"
              d="M52.3872,133.1853a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C81-3"
              data-name="C81"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 82 ? (
          <g id="C82-2" data-name="C82">
            <path
              id="C82cone"
              className="cls-22"
              d="M52.3872,119.7963a7.4932,7.4932,0,0,1-.58-2.89,7.2612,7.2612,0,0,1,.6-2.92l-44.91-19.46v43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="C82-3"
              data-name="C82"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="161.9694"
          y="55.7983"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_5-2" data-name="5">
          <path
            className="cls-7"
            d="M170.3614,84.6244l.7851-.0664a1.4091,1.4091,0,0,0,.4053.8618,1.0968,1.0968,0,0,0,.7656.2886,1.1956,1.1956,0,0,0,.9141-.4067,1.5346,1.5346,0,0,0,.373-1.08,1.3841,1.3841,0,0,0-.3593-1.0093,1.2531,1.2531,0,0,0-.9405-.37,1.3018,1.3018,0,0,0-.6513.164,1.261,1.261,0,0,0-.4571.4258l-.7021-.0913.59-3.1269h3.0274v.7143h-2.4287l-.3282,1.6362a1.9781,1.9781,0,0,1,1.1494-.3823,1.8212,1.8212,0,0,1,1.3457.5523,1.9309,1.9309,0,0,1,.5479,1.42,2.2157,2.2157,0,0,1-.4814,1.4282,1.93,1.93,0,0,1-1.5987.7393,1.9671,1.9671,0,0,1-1.3554-.4654A1.8137,1.8137,0,0,1,170.3614,84.6244Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.136,91.9989a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,97.9728l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715.4267-.5689,4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.136,59.1773a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,0,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M176.972,67.9959l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  21: [54.848, 7.0884],
  22: [36.4846, 57.3091],
  23: [63.4377, 57.3091],
  24: [77.9525, 57.3091],
  25: [82.3715, 7.0884],
  45: [135.7828, 36.2417],
  46: [135.7828, 72.5363],
  47: [90.7952, 72.5363],
  77: [90.7952, 86.0066],
  78: [135.7828, 96.0066],
  79: [135.7828, 144.7428],
  80: [56.9873, 143.5353],
  81: [56.9873, 130.4153],
  82: [56.9873, 117.0263],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }
  .cls-2 {
    fill: #e5e5e5;
  }
  .cls-3,
  .cls-5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-width: 0.8504px;
  }
  .cls-4 {
    fill: #3d3d3b;
  }
  .cls-5 {
    stroke-width: 0.2835px;
  }
  .cls-6 {
    fill: #3f3f3c;
  }
  .cls-7 {
    fill: #d3d2cf;
  }
  .cls-8 {
    fill: url(#linear-gradient);
  }
  .cls-9 {
    fill: #c65805;
  }
  .cls-10 {
    fill: url(#linear-gradient-2);
  }
  .cls-11 {
    fill: url(#linear-gradient-3);
  }
  .cls-12 {
    fill: url(#linear-gradient-4);
  }
  .cls-13 {
    fill: url(#linear-gradient-5);
  }
  .cls-14 {
    fill: url(#linear-gradient-6);
  }
  .cls-15 {
    fill: url(#linear-gradient-7);
  }
  .cls-16 {
    fill: url(#linear-gradient-8);
  }
  .cls-17 {
    fill: url(#linear-gradient-9);
  }
  .cls-18 {
    fill: url(#linear-gradient-10);
  }
  .cls-19 {
    fill: url(#linear-gradient-11);
  }
  .cls-20 {
    fill: url(#linear-gradient-12);
  }
  .cls-21 {
    fill: url(#linear-gradient-13);
  }
  .cls-22 {
    fill: url(#linear-gradient-14);
  }

  #A21,
  #A22,
  #A23,
  #A24,
  #A25,
  #B45,
  #B46,
  #B47,
  #C77,
  #C78,
  #C79,
  #C80,
  #C81,
  #C82 {
    cursor: pointer;
    transition: fill 0.2s ease;

    &:hover {
      fill: var(--yit-blue);
    }
  }

  #down:hover,
  #up:hover {
    cursor: pointer;

    .cls-1 {
      fill: var(--yit-blue);
    }

    .cls-6 {
      fill: var(--yit-white);
    }
  }

  .active-apt {
    fill: var(--yit-blue);
  }

  .on-hover {
    fill: var(--yit-blue);
  }

  .white-text path {
    fill: var(--yit-white);
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
