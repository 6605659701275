export const FLOOR_1_DATA = [
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 1,
    sceneName: 'scene_Otavallanhovi_01_krs_A01',
    sceneName_future: 'scene_Otavallanhovi_01_krs_A01_future',
    name: 'A01',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      2: 6,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 2,
    sceneName: 'scene_Otavallanhovi_01_krs_A02',
    sceneName_future: 'scene_Otavallanhovi_01_krs_A02_future',
    name: 'A02',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 7,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 3,
    sceneName: 'scene_Otavallanhovi_01_krs_A03',
    sceneName_future: 'scene_Otavallanhovi_01_krs_A03_future',
    name: 'A03',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 8,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 4,
    sceneName: 'scene_Otavallanhovi_01_krs_A04',
    sceneName_future: 'scene_Otavallanhovi_01_krs_A04_future',
    name: 'A04',
    lookAt: '-160, 14.11, 140',
    face: 'south',

    relatedApartments: {
      2: 9,
    },
  },
  {
    floor: 1,
    building: 'A',
    apartmentNumber: 5,
    sceneName: 'scene_Otavallanhovi_01_krs_A05',
    sceneName_future: 'scene_Otavallanhovi_01_krs_A05_future',
    name: 'A05',
    lookAt: '0, 14.11, 140',
    face: 'north',

    relatedApartments: {
      2: 10,
    },
  },
  {
    floor: 1,
    building: 'C',
    apartmentNumber: 56,
    sceneName: 'scene_Otavallanhovi_01_krs_C56',
    sceneName_future: 'scene_Otavallanhovi_01_krs_C56_future',
    name: 'C56',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 64,
    },
  },
  {
    floor: 1,
    building: 'C',
    apartmentNumber: 57,
    sceneName: 'scene_Otavallanhovi_01_krs_C57',
    sceneName_future: 'scene_Otavallanhovi_01_krs_C57_future',
    name: 'C57',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 63,
    },
  },
  {
    floor: 1,
    building: 'C',
    apartmentNumber: 58,
    sceneName: 'scene_Otavallanhovi_01_krs_C58',
    sceneName_future: 'scene_Otavallanhovi_01_krs_C58_future',
    name: 'C58',
    lookAt: '-100, 14.11, 140',
    face: 'west',

    relatedApartments: {
      2: 62,
    },
  },
]
